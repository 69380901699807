import { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { Link as RouterLink } from 'react-router-dom'
import {
  AppBar,
  Box,
  Container,
  Toolbar
} from '@mui/material'
import { DefaultLogo } from '@/components/default-logo'
import { SkeletonLoading } from '@/components/skeleton-loading'
import { useAuth } from '@/hooks/use-auth'

export const Login = () => {
  const { isLoading, loginWithRedirect } = useAuth()

  useEffect(() => {
    if (!isLoading) {
      loginWithRedirect()
    }
  }, [isLoading, loginWithRedirect])

  return (
    <>
      <Helmet>
        <title>Login | Niomon Admin Console</title>
      </Helmet>
      <AppBar
        elevation={0}
        sx={{ backgroundColor: 'background.paper' }}
      >
        <Container maxWidth="md">
          <Toolbar
            disableGutters
            sx={{ height: 64 }}
          >
            <RouterLink to="/">
              <DefaultLogo variant="color" />
            </RouterLink>
          </Toolbar>
        </Container>
      </AppBar>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          pt: '64px'
        }}
      >
        <Box sx={{ py: 9 }}>
          <Container maxWidth="md">
            <SkeletonLoading />
          </Container>
        </Box>
      </Box>
    </>
  )
}
