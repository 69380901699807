import { adminApi as generatedAdminApi } from '@/store/codegen/generated/adminApi'

export const tenantApi = generatedAdminApi.enhanceEndpoints({
  addTagTypes: ['tenant'],
  endpoints: {
    listTenants: {
      providesTags: (result, error) => {
        return (
          result
            ? [
                ...result.tenants.map(({ id }) => ({ type: 'tenant', id })),
                // PARTIAL-LIST denotes that the list is partial, which is paginated
                { type: 'tenant', id: 'PARTIAL-LIST' }
              ]
            : [{ type: 'tenant', id: 'PARTIAL-LIST' }]
        )
      }
    },
    getTenant: {
      providesTags: (result, error) => result ? [{ type: 'tenant', id: result.id }] : []
    },
    getSelfTenant: {
      providesTags: (result, error) => result ? [{ type: 'tenant', id: result.id }] : []
    },
    createTenant: {
      invalidatesTags: ['tenant']
    },
    updateTenant: {
      invalidatesTags: (result, error, { id }) => [{ type: 'tenant', id }]
    },
    deleteTenant: {
      invalidatesTags: ['tenant']
    }
  }
})

export const {
  useCreateTenantMutation,
  useListTenantsQuery,
  useGetTenantQuery,
  useGetSelfTenantQuery,
  useUpdateTenantMutation,
  useDeleteTenantMutation
} = tenantApi
