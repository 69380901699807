import { createApi } from '@reduxjs/toolkit/query/react'
import { internalApiBaseQuery } from '@/store/query'

export const currentUserApi = createApi({
  reducerPath: 'current_user',
  baseQuery: internalApiBaseQuery,
  tagTypes: ['current_user'],
  // allow refetch all subscribed queries after regaining a network connection
  refetchOnReconnect: true,
  // cache expired after 10 seconds
  refetchOnMountOrArgChange: 10,
  endpoints: (builder) => ({
    getCurrentUser: builder.query({
      query: () => ({ url: '/current-user' }),
      providesTags: ['current_user'],
      extraOptions: {
        tenantId: '-'
      },
      transformResponse: (response) => {
        if (response.user?.userMetadata) {
          response.user.userMetadata = JSON.stringify(response.user.userMetadata)
        }
        return response
      }
    }),
    updateCurrentUser: builder.mutation({
      query: (queryArg) => ({
        url: '/current-user',
        method: 'PATCH',
        body: queryArg.body
      }),
      extraOptions: {
        tenantId: '-'
      },
      invalidatesTags: ['current_user']
    })
  })
})

export const {
  endpoints: { getCurrentUser },
  useGetCurrentUserQuery,
  useUpdateCurrentUserMutation
} = currentUserApi
