import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'

export const CircularLoading = () => {
  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'center',
      py: 4
    }}>
      <CircularProgress />
    </Box>
  )
}
