export const phoneRegex = /^\+[0-9]+$/
export const colorCodeRegex = /^#?(?:[0-9a-fA-F]{3,4}){1,2}$/
// locale regex used in validator.js package
// case-insensitive
// reference: https://github.com/validatorjs/validator.js/blob/master/src/lib/isLocale.js
// eslint-disable-next-line max-len
export const localeRegex = /^(en_US_POSIX|ca_ES_VALENCIA|[A-Za-z]{2,4}([_-]([A-Za-z]{4}|[\d]{3}))?([_-]([A-Za-z]{2}|[\d]{3}))?)$/
export const tenantIdRegex = /^[0-9A-Za-z][0-9A-Za-z-]*[0-9A-Za-z]$/
export const tenantNameRegex = /^[0-9A-Za-z][0-9A-Za-z ]*[0-9A-Za-z]$/
export const emailTemplateIdRegex = /^d-[0-9a-f]+$/
