import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import useMediaQuery from '@mui/material/useMediaQuery'
import {
  AppBar,
  Box,
  Button,
  Divider,
  Toolbar
} from '@mui/material'
import { ChevronDown as ChevronDownIcon } from '@/icons/chevron-down'
import { AccountPopover } from './account-popover'
import { TenantPopover } from './tenant-popover'
import { DefaultLogo } from '@/components/default-logo'
import { DashboardNavbarMenu } from './dashboard-navbar-menu'

import { useGetCurrentUserQuery } from '@/store/api/current-user'
import { selectTenantId } from '@/store/tenant-id'
import { GLOBAL_RESOURCE_NAMESPACE } from '@/constant'

export const ALL_TENANTS_KEY = 'ALL_TENANTS'

export const DashboardNavbar = () => {
  const tenantId = useSelector(selectTenantId)
  const navigate = useNavigate()
  const { data: currentUser } = useGetCurrentUserQuery()
  const roles = currentUser?.roles
  const tenantIds = roles ? Object.keys(roles) : []

  const mdDown = useMediaQuery((theme) => theme.breakpoints.down('md'))
  const [openMenu, setOpenMenu] = useState(false)

  const handleTenantChange = (tenantId) => {
    if (tenantId === ALL_TENANTS_KEY) {
      navigate(`/${GLOBAL_RESOURCE_NAMESPACE}/tenants`)
    } else {
      navigate(`/${tenantId}`)
    }
  }

  return (
    <AppBar
      elevation={0}
      sx={{ backgroundColor: '#1e212a' }}
    >
      <Toolbar
        disableGutters
        sx={{
          alignItems: 'center',
          display: 'flex',
          minHeight: 64,
          px: 3,
          py: 1
        }}
      >
        <Box
          component={RouterLink}
          to="/"
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <DefaultLogo />
        </Box>
        <Divider
          flexItem
          orientation="vertical"
          sx={{
            borderColor: 'rgba(255,255,255,0.1)',
            mx: 3
          }}
        />
        {tenantIds.length &&
          <TenantPopover
            currentTenantId={tenantId}
            onTenantChange={handleTenantChange}
            tenantIds={tenantIds}
            sx={{
              display: {
                md: 'flex',
                xs: 'none'
              }
            }}
          />
        }
        <DashboardNavbarMenu
          onClose={() => setOpenMenu(false)}
          open={mdDown && openMenu}
        />
        <Button
          endIcon={(
            <ChevronDownIcon
              fontSize="small"
              sx={{
                ml: 2,
                transition: 'transform 250ms',
                transform: openMenu ? 'rotate(180deg)' : 'none'
              }}
            />
          )}
          onClick={() => setOpenMenu(true)}
          sx={{
            color: 'primary.contrastText',
            display: {
              md: 'none',
              xs: 'flex'
            }
          }}
          variant="text"
        >
          Menu
        </Button>
        <Box sx={{ flexGrow: 1 }} />
        {tenantIds.length &&
          <AccountPopover
            currentUser={currentUser?.user}
            currentTenantId={tenantId}
            onTenantChange={handleTenantChange}
            tenantIds={tenantIds}
          />
        }
      </Toolbar>
    </AppBar>
  )
}
