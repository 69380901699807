import PropTypes from 'prop-types'
import { ReactComponent as NiomonColorLogo } from '@/assets/niomon-text-color-logo.svg'
import { ReactComponent as NiomonWhiteLogo } from '@/assets/niomon-text-monotone-white-logo.svg'

export const DefaultLogo = ({ logoProps, variant, ...other }) => {
  if (variant === 'color') {
    return (
      <NiomonColorLogo {...logoProps} />
    )
  }
  return (
    <NiomonWhiteLogo {...logoProps} />
  )
}

DefaultLogo.defaultProps = {
  logoProps: {
    width: '120px',
    height: '100%'
  }
}

DefaultLogo.propTypes = {
  logoProps: PropTypes.object,
  variant: PropTypes.string
}
