import PropTypes from 'prop-types'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import {
  Box,
  Button,
  Divider,
  Grid,
  Skeleton,
  Tab,
  Tabs,
  Typography
} from '@mui/material'
import { ArrowLeft as ArrowLeftIcon } from '@/icons/arrow-left'
import { useBackTo } from '@/hooks/use-back-to'

const TabSection = ({ tabs }) => {
  const location = useLocation()
  const activeTabIndex = tabs.findIndex(
    (tab) => (
      tab.href === location.pathname ||
      tab.href === location.state?.location?.pathname
    )
  )

  return (
    <Tabs
      allowScrollButtonsMobile
      sx={{ mt: 4 }}
      value={activeTabIndex}
      variant="scrollable"
    >
      {tabs.map((tab) => (
        <Tab
          component={RouterLink}
          key={tab.href}
          label={tab.label}
          to={tab.href}
        />
      ))}
    </Tabs>
  )
}

TabSection.propTypes = {
  tabs: PropTypes.array
}

export const PageHeader = (props) => {
  const {
    divider,
    isLoading,
    backTo,
    backTitle,
    tabs,
    title,
    actions: ActionsComponent,
    descriptions: DescriptionsComponent
  } = props

  const to = useBackTo(backTo)

  let TitleComponent
  if (typeof (title) === 'string') {
    TitleComponent = (
      <Typography
        color="textPrimary"
        variant="h4"
      >
        {title}
      </Typography>
    )
  } else {
    TitleComponent = title
  }

  return (
    <Box sx={{ py: 4 }}>
      {backTo && backTitle &&
        <Box sx={{ mb: 2 }}>
          <Button
            color="primary"
            component={RouterLink}
            startIcon={<ArrowLeftIcon />}
            to={to}
            variant="text"
          >
            {backTitle}
          </Button>
        </Box>
      }
      {isLoading && <Skeleton height={42} />}
      {!isLoading &&
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex'
          }}
        >
          <Box flexGrow={1}>
            {TitleComponent}
          </Box>
          {ActionsComponent}
        </Box>
      }
      {DescriptionsComponent &&
        <Grid
          container
          spacing={2}
          sx={{
            mt: 2
          }}
          wrap="wrap"
        >
          {DescriptionsComponent}
        </Grid>
      }
      {tabs && <TabSection tabs={tabs} />}
      {divider && <Divider />}
    </Box>
  )
}

PageHeader.propTypes = {
  divider: PropTypes.bool,
  isLoading: PropTypes.bool,
  backTo: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  backTitle: PropTypes.string,
  tabs: PropTypes.array,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]),
  actions: PropTypes.node,
  descriptions: PropTypes.node
}
