import PropTypes from 'prop-types'
import { useAuth } from '@/hooks/use-auth'
import { NavigateToTenant } from '@/components/navigate-to-tenant'

export const GuestGuard = ({ children }) => {
  const { isAuthenticated } = useAuth()

  if (isAuthenticated) {
    return <NavigateToTenant />
  }

  return <>{children}</>
}

GuestGuard.propTypes = {
  children: PropTypes.node
}
