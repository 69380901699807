import { Helmet } from 'react-helmet-async'
import { Box, Container } from '@mui/material'

import { QueryGuard } from '@/components/query-guard'
import { ResourceUnavailable } from '@/components/resource-unavailable'

import { useTableParams } from '@/hooks/use-table-params'
import { useParams } from 'react-router-dom'
import { useListUserCredentialsQuery } from '@/store/api/credential'
import { CredentialCardMasonry } from '@/components/credential/credential-card-masonry'

export const SecurityDevices = () => {
  const { userId } = useParams()
  const { pageParams, setPageParam } = useTableParams()
  const { data: credentialState, error, isLoading } = useListUserCredentialsQuery({ userId, ...pageParams })

  const credentialListEmpty = !credentialState?.credentials?.length

  const renderContent = () => {
    if (credentialListEmpty) {
      return (
        // TODO: design a better UI (#192)gi
        <ResourceUnavailable
          sx={{
            flexGrow: 1,
            m: 2
          }}
        />
      )
    }

    return (
      <QueryGuard
        isLoading={isLoading}
        statusCode={error?.status}
      >
        <CredentialCardMasonry
          credentials={credentialState?.credentials}
          page={credentialState?.page}
          pageSize={credentialState?.pageSize}
          totalSize={credentialState?.totalSize}
          onPageChange={setPageParam}
        />
      </QueryGuard>
    )
  }

  return (
    <>
      <Helmet>
        <title>Security Device | Niomon Admin Dashboard</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          {renderContent()}
        </Container>
      </Box>
    </>
  )
}
