import PropTypes from 'prop-types'
import toast from 'react-hot-toast'
import { ConfirmationDialog } from '@/components/confirmation-dialog'
import { useDeleteTenantMutation } from '@/store/api/tenant'

export const DeleteTenantConfirmationDialog = (props) => {
  const {
    open,
    handleClose,
    tenant,
    setPending,
    onSuccess,
    ...other
  } = props

  const [deleteTenant] = useDeleteTenantMutation()

  const dialogTitle = 'Delete Tenant'

  const dialogMessage = 'Are you sure you want to delete this tenant?'

  const onConfirm = () => {
    setPending?.(true)
    deleteTenant({ tenantId: tenant?.id })
      .unwrap()
      .then(() => {
        toast('Tenant has been removed')
        onSuccess?.()
        handleClose()
      })
      .catch(error => {
        if (error.data.message) {
          toast.error(error.data.message)
        }
        setPending?.(false)
      })
  }
  return (
    <ConfirmationDialog
      title={dialogTitle}
      message={dialogMessage}
      onCancel={handleClose}
      onConfirm={onConfirm}
      open={open}
      variant={'error'}
      {...other}
    />
  )
}

DeleteTenantConfirmationDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  tenant: PropTypes.object,
  setPending: PropTypes.func,
  onSuccess: PropTypes.func
}
