import PropTypes from 'prop-types'
import { NotFound } from '@/containers/not-found'
import { UnknownError } from '@/containers/unknown-error'
import { PermissionDenied } from '@/containers/permission-denied'

export const ErrorGuard = (props) => {
  const { children, statusCode } = props

  if (!statusCode) {
    return <>{children}</>
  }

  // TODO: handle other error status code
  switch (statusCode) {
    case 404:
      return (
        <NotFound />
      )
    case 403:
      return (
        <PermissionDenied />
      )
    default:
      return (
        <UnknownError />
      )
  }
}

ErrorGuard.propTypes = {
  children: PropTypes.node,
  statusCode: PropTypes.number
}
