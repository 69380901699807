import PropTypes from 'prop-types'
import { Chip } from '@mui/material'
export const UnverifiedChip = (props) => (
  <Chip
    label="Unverified"
    color="error"
    variant="outlined"
    size='small'
    sx={{
      height: 'auto',
      fontSize: '10px'
    }}
    {...props}
  />
)
UnverifiedChip.propTypes = {
  shown: PropTypes.bool
}
