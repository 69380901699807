import PropTypes from 'prop-types'
import {
  Button,
  IconButton,
  Menu,
  MenuItem
} from '@mui/material'
import { usePopover } from '@/hooks/use-popover'
import { DotsVertical as DotsVerticalIcon } from '@/icons/dots-vertical'
import { useDialog } from '@/hooks/use-dialog'
import { DeleteClientConfirmationDialog } from './delete-client-confirmation-dialog'

export const ClientActionMenu = (props) => {
  const [
    anchorRef,
    open,
    handleOpen,
    handleClose
  ] = usePopover()

  const [
    clientDeleteDialogOpen,
    handleOpenClientDeleteDialog,
    handleCloseClientDeleteDialog
  ] = useDialog()

  const {
    label,
    client,
    setPending,
    onSuccess,
    ...other
  } = props

  const handleDelete = () => {
    handleClose()
    handleOpenClientDeleteDialog()
  }

  const ButtonComponent = label
    ? <Button
        onClick={handleOpen}
        ref={anchorRef}
        {...other}
      >
        {label}
      </Button>
    : <IconButton
        onClick={handleOpen}
        ref={anchorRef}
        {...other}
      >
        <DotsVerticalIcon fontSize="small" />
      </IconButton>

  return (
    <div onClick={e => e.stopPropagation()}>
      {ButtonComponent}
      <Menu
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        open={open}
        onClose={handleClose}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuItem onClick={handleDelete}>
          Delete
        </MenuItem>
      </Menu>
      <DeleteClientConfirmationDialog
        client={client}
        open={clientDeleteDialogOpen}
        setPending={setPending}
        onSuccess={onSuccess}
        onCancel={handleCloseClientDeleteDialog}
      />
    </div>
  )
}

ClientActionMenu.propTypes = {
  label: PropTypes.string,
  client: PropTypes.object,
  setPending: PropTypes.func,
  onSuccess: PropTypes.func
}
