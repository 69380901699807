import { subDays, subHours, subMinutes } from 'date-fns'

const now = new Date()

export default [
  {
    // TODO: Provide auth field to mock corresponding part
    id: 1,
    // eslint-disable-next-line max-len
    logo: 'https://images.generated.photos/moB96N8Ni852n0JxbIh7rHN0o8wukCWdOwoPKzXQhIE/rs:fit:512:512/wm:0.95:sowe:18:18:0.33/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/MDUyMDU5LmpwZw.jpg',
    id_number: '000001',
    display_name: 'Root',
    email: 'root@niomon.dev',
    email_verified: true,
    phone: '91234567',
    phone_verified: true,
    active: true,
    shown_in_app: true,
    created_at: subDays(subHours(subMinutes(now, 25), 9), 244),
    added_at: subDays(subHours(subMinutes(now, 25), 9), 244),
    role: 'Administrator',
    tenants: [
      {
        id: '000001',
        name: 'RootNiomon',
        admin_user_id: [1],
        logo: 'https://encl.io/assets/icons/android-chrome-512x512.png',
        domain_name: 'Niomon.com',
        subscription: 'Enterprise',
        email: 'root@niomon.dev',
        num_of_user: 731,
        created_at: subDays(subHours(subMinutes(now, 25), 9), 234),
        status: 'active'
      },
      {
        id: '000002',
        name: 'LikeCo',
        admin_user_id: [],
        subscription: 'Enterprise',
        logo: 'https://www.worldlogomart.com/wp-content/uploads/2019/09/BG-Logo.jpg',
        email: 'service@like.co',
        num_of_user: 102,
        created_at: subDays(subHours(now, 10), 4),
        status: 'active'
      }
    ]
  }
]
