import PropTypes from 'prop-types'
import { Status } from '@/components/status'
import { statusVariants } from '@/schema/tenant/variants'

export const TenantStatus = (props) => {
  const { status } = props
  const statusVariant =
    statusVariants.find((variant) => variant.value === status) ||
    {
      color: 'error.main',
      label: 'Unknown'
    }

  return (
    <Status
      color={statusVariant.color}
      label={statusVariant.label}
    />
  )
}

TenantStatus.propTypes = {
  status: PropTypes.string
}
