import { Outlet, useParams, useNavigate } from 'react-router-dom'
import { Box, Container, Typography } from '@mui/material'
import { ActionsMenu } from '@/components/actions-menu'
import { PageHeader } from '@/components/page-header'
import { useState } from 'react'
import { useDialog } from '@/hooks/use-dialog'
import { useGetTenantQuery } from '@/store/api/tenant'
import { Calendar as CalendarIcon } from '@/icons/calendar'
import { formatDistanceDateWithTime } from '@/utils/date'
import { TenantStatus } from '@/components/tenant/tenant-status'
import { StatusGrid } from '@/components/status-grid'
import { QueryGuard } from '@/components/query-guard'
import { GLOBAL_RESOURCE_NAMESPACE } from '@/constant'

import { ActivationTenantConfirmationDialog } from '@/components/tenant/activation-tenant-confirmation-dialog'
import { DeleteTenantConfirmationDialog } from '@/components/tenant/delete-tenant-confirmation-dialog'
import { useGetCurrentUserQuery } from '@/store/api/current-user'

export const Tenant = () => {
  const { id } = useParams()
  const [pending, setPending] = useState(false)
  const { data: currentUser } = useGetCurrentUserQuery()
  const { data: tenant, error, isLoading } = useGetTenantQuery({ tenantId: id }, { skip: pending })
  const navigate = useNavigate()

  const [
    tenantDeleteDialogOpen,
    handleOpenTenantDeleteDialog,
    handleCloseTenantDeleteDialog
  ] = useDialog()

  const [
    tenantActivationDialogOpen,
    handleOpenTenantActivationDialog,
    handleCloseTenantActivationDialog
  ] = useDialog()

  const onCloseDeleteDialog = () => {
    handleCloseTenantDeleteDialog()
  }

  const onCloseActivationDialog = () => {
    handleCloseTenantActivationDialog()
  }

  const onSuccess = () => {
    navigate('..')
  }

  const switchAndRedirectToTenant = () => {
    navigate(`/${tenant.id}`)
  }

  const actions = [
    {
      label: `Switch to ${tenant?.id}`,
      onClick: switchAndRedirectToTenant,
      disabled: !currentUser.roles[tenant?.id]
    },
    {
      label: tenant?.status === 'active' ? 'Deactivate Tenant' : 'Activate Tenant',
      onClick: handleOpenTenantActivationDialog
    },
    {
      label: 'Delete Tenant',
      onClick: handleOpenTenantDeleteDialog
    }
  ]

  const tabs = [
    {
      href: `/${GLOBAL_RESOURCE_NAMESPACE}/tenants/${id}`,
      label: 'Summary'
    }
  ]

  const renderContent = () => {
    return (
      <QueryGuard
        isLoading={isLoading}
        statusCode={error?.status}
      >
        <PageHeader
          divider
          backTo="-1"
          backTitle="Tenants"
          title={tenant?.name || tenant?.id}
          tabs={tabs}
          actions={<ActionsMenu actions={actions} />}
          descriptions={
            <>
              <StatusGrid>
                <CalendarIcon
                  fontSize="small"
                  sx={{ color: 'text.secondary' }}
                />
                <Typography
                  color="textSecondary"
                  sx={{ ml: 0.5 }}
                  variant="body2"
                >
                  Since: {formatDistanceDateWithTime(tenant?.createdAt)}
                </Typography>
              </StatusGrid>

              <StatusGrid>
                <CalendarIcon
                  fontSize="small"
                  sx={{ color: 'text.secondary' }}
                />
                <Typography
                  color="textSecondary"
                  sx={{ ml: 0.5 }}
                  variant="body2"
                >
                  Updated: {formatDistanceDateWithTime(tenant?.updatedAt)}
                </Typography>
              </StatusGrid>

              <StatusGrid>
                <CalendarIcon
                  fontSize="small"
                  sx={{ color: 'text.secondary' }}
                />
                <Typography
                  color="textSecondary"
                  sx={{ ml: 0.5 }}
                  variant="body2"
                >
                  Deleted: {formatDistanceDateWithTime(tenant?.deletedAt)}
                </Typography>
              </StatusGrid>

              <StatusGrid>
                <TenantStatus status={tenant?.status} />
              </StatusGrid>
            </>
          }
        />
        <QueryGuard isLoading={pending} >
          <Outlet />
        </QueryGuard>
        <DeleteTenantConfirmationDialog
          tenant={tenant}
          open={tenantDeleteDialogOpen}
          handleClose={onCloseDeleteDialog}
          setPending={setPending}
          onSuccess={onSuccess}
        />
        <ActivationTenantConfirmationDialog
          tenant={tenant}
          open={tenantActivationDialogOpen}
          handleClose={onCloseActivationDialog}
        />
      </QueryGuard>
    )
  }

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        flexGrow: 1
      }}
    >
      <Container
        maxWidth="lg"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%'
        }}
      >
        {renderContent()}
      </Container>
    </Box>
  )
}
