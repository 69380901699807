import PropTypes from 'prop-types'
import { IconButton, Menu, MenuItem } from '@mui/material'
import { useDialog } from '@/hooks/use-dialog'
import { usePopover } from '@/hooks/use-popover'
import { DotsVertical as DotsVerticalIcon } from '@/icons/dots-vertical'
import { DeleteCredentialConfirmationDialog } from './delete-credential-confirmation-dialog'

export const CredentialCardMenu = (props) => {
  const [anchorRef, open, handleOpen, handleClose] = usePopover()
  const [
    credentialDeleteDialogOpen,
    handleOpenCredentialDeleteDialog,
    handleCloseCredentialDeleteDialog
  ] = useDialog()

  const { credential, setPending, ...other } = props

  const handleDelete = () => {
    handleClose()
    handleOpenCredentialDeleteDialog()
  }

  return (
    <div onClick={e => e.stopPropagation()}>
      <IconButton
        onClick={handleOpen}
        ref={anchorRef}
        {...other}
      >
        <DotsVerticalIcon fontSize="small" />
      </IconButton>
      <Menu
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        open={open}
        onClose={handleClose}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuItem onClick={handleDelete}>
          Revoke
        </MenuItem>
      </Menu>
      <DeleteCredentialConfirmationDialog
        credential={credential}
        open={credentialDeleteDialogOpen}
        setPending={setPending}
        onCancel={handleCloseCredentialDeleteDialog}
      />
    </div>
  )
}

CredentialCardMenu.propTypes = {
  credential: PropTypes.object,
  setPending: PropTypes.func
}
