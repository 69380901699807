import { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Switch
} from '@mui/material'
import CodeMirror from '@uiw/react-codemirror'
import { json } from '@codemirror/lang-json'

import { lightNeutral } from '@/colors'
import { InputField } from '@/components/input-field'
import { ImageUploader } from './image-uploader'

const TextField = forwardRef((props, ref) => {
  return (
    <InputField
      ref={ref}
      {...props}
      // label and helperText are handled in parent
      label={null}
      helperText={null}
    />
  )
})

TextField.displayName = 'TextField'

// OptionsField requires optionList to generate select component.
// It allows to have optionLabelComponent to have custom label component.
// It uses the label field in the given list as the key
const OptionsField = forwardRef((props, ref) => {
  const { optionList, optionLabelComponent, ...other } = props
  const LabelComponent = ({ value }) => optionLabelComponent?.({ value }) || value
  return (
    <InputField
      ref={ref}
      select
      {...other}
      // label and helperText are handled in parent
      label={null}
      helperText={null}
    >
      {optionList?.map(option => (
        <MenuItem
          key={option.value}
          value={option.value}
        >
          <LabelComponent value={option.label} />
        </MenuItem>
      ))}
    </InputField>
  )
})

OptionsField.displayName = 'OptionsField'
OptionsField.propTypes = {
  optionList: PropTypes.array,
  optionLabelComponent: PropTypes.func
}

const MultipleOptionsField = forwardRef((props, ref) => {
  const { optionList, optionLabelComponent, ...other } = props
  if (!Array.isArray(other.value)) {
    // eslint-disable-next-line max-len
    console.error('"value" in InputField requires array value, provide that in "defaultValue" property of corresponding FormField')
  }
  const LabelComponent = ({ value }) => optionLabelComponent?.({ value }) || value
  return (
    <InputField
      ref={ref}
      select
      SelectProps={{
        multiple: true
      }}
      {...other}
      /* label and helperText are handled in parent */
      label={null}
      helperText={null}
    >
      {optionList?.map(option => (
        <MenuItem
          key={option.value}
          value={option.value}
        >
          <LabelComponent value={option.label} />
        </MenuItem>
      ))}
    </InputField>
  )
})

MultipleOptionsField.displayName = 'MultipleOptionsField'
MultipleOptionsField.propTypes = {
  optionList: PropTypes.array,
  optionLabelComponent: PropTypes.func
}

const CheckboxField = forwardRef((props, ref) => {
  const { value, checkboxLabel, ...other } = props
  return (
    <Box>
      <FormControlLabel
        label={checkboxLabel}
        control={
          <Checkbox
            sx={{
              pl: 0,
              color: lightNeutral[300]
            }}
            checked={value}
            {...other}
          />
        }
        sx={{
          marginLeft: 0
        }}
        componentsProps={{
          typography: {
            variant: 'subtitle2',
            color: other.disabled ? 'textSecondary' : 'textPrimary'
          }
        }}
      />
    </Box>
  )
})

CheckboxField.displayName = 'CheckboxField'
CheckboxField.propTypes = {
  value: PropTypes.bool,
  checkboxLabel: PropTypes.string
}

const SwitchField = forwardRef((props, ref) => {
  const { value, ...other } = props
  delete other.helperText
  return (
    <Switch
      ref={ref}
      checked={value}
      {...other}
    />
  )
})

SwitchField.displayName = 'SwitchField'
SwitchField.propTypes = {
  value: PropTypes.bool
}

const CodeMirrorField = forwardRef((props, ref) => {
  return (
    <CodeMirror
      // About 6 line height
      height="150px"
      extensions={[json()]}
      {...props}
    />
  )
})

CodeMirrorField.displayName = 'CodeMirrorField'
CodeMirrorField.propTypes = {
}

const LogoField = forwardRef((props, ref) => {
  const { value, ...other } = props
  return (
    <ImageUploader
      imageUrl={value}
      // maxSize: 1MB
      maxSize={1048576}
      minSize={0}
      {...other}
      // label and helperText are handled in parent
      label={null}
      helperText={null}
    />
  )
})

LogoField.displayName = 'LogoField'
LogoField.propTypes = {
  value: PropTypes.string
}

// FormFieldComponent generates the field according to the given type
const FormFieldComponent = forwardRef((props, ref) => {
  const { type, component, fieldState: { error }, methods, ...other } = props
  // defaultValue is removed to prevent error when passing both value and defaultValue for the input component
  delete other.defaultValue
  if (!!component && type !== 'custom') {
    // eslint-disable-next-line max-len
    console.warn('Detected to have component field but type is not set to be "custom", this will not render custom component as expected.')
  }
  let resultField

  switch (type) {
    case 'logo':
      resultField =
      <LogoField
        ref={ref}
        setValue={methods.setValue}
        setError={methods.setError}
        clearErrors={methods.clearErrors}
        {...other}
      />
      break
    case 'checkbox':
      resultField =
      <CheckboxField
        ref={ref}
        {...other}
      />
      break
    case 'switch':
      resultField =
      <SwitchField
        ref={ref}
        {...other}
      />
      break
    case 'codemirror':
      resultField =
      <CodeMirrorField
        ref={ref}
        {...other}
      />
      break
    case 'options':
      resultField =
      <OptionsField
        ref={ref}
        {...other}
      />
      break
    case 'multipleOptions':
      resultField =
        <MultipleOptionsField
          ref={ref}
          {...other}
        />
      break
    case 'custom':
      resultField = component({ ref, ...other })
      break
    default:
      resultField =
      <TextField
        ref={ref}
        type={type}
        {...other}
      />
      break
  }
  const { id, label, helperText, disabled } = other
  // error can be an object with 'message' field or an array of objects with 'message' field
  const errorMessage = error?.message ?? error?.find?.(Boolean)?.message

  return (
    <FormControl
      fullWidth
      variant="filled"
      error={!!error}
    >
      {(label &&
        <InputLabel
          disabled={disabled}
          shrink
          htmlFor={id}
          sx={{
            color: 'text.primary',
            fontSize: 14,
            fontWeight: 500,
            mb: 0.5,
            position: 'relative',
            transform: 'none'
          }}
        >
          {label}
        </InputLabel>
       )}
      {resultField}
      <FormHelperText id={`${id}-helper-text`}>
        {errorMessage ?? helperText}
      </FormHelperText>
    </FormControl>
  )
})

FormFieldComponent.displayName = 'FormFieldComponent'
FormFieldComponent.propTypes = {
  component: PropTypes.func,
  type: PropTypes.string,
  methods: PropTypes.object,
  fieldState: PropTypes.object
}

// FormField generates the field according to a field object
// The field object requires id and label to be formed.
// Param 'type' controls what type of field it should be rendered
export const FormField = (props) => {
  const { methods, gridSx, ...other } = props
  return (
    <Grid
      item
      xs={12}
      sx={{
        pt: 2,
        ...gridSx
      }}
    >
      <Controller
        render={({ field, fieldState }) => {
          return (
            <FormFieldComponent
              fieldState={fieldState}
              methods={methods}
              {...field}
              {...other}
            />
          )
        }}
        control={methods.control}
        name={other.id}
        defaultValue={other.defaultValue ?? ''}
        key={other.id}
      />
    </Grid>
  )
}

FormField.displayName = 'FormField'
FormField.propTypes = {
  id: PropTypes.string,
  defaultValue: PropTypes.any,
  methods: PropTypes.object,
  gridSx: PropTypes.object
}
