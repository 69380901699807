import { Avatar, Badge, IconButton } from '@mui/material'
import PropTypes from 'prop-types'
import { useState } from 'react'
import CancelIcon from '@mui/icons-material/Cancel'
import BrokenImageIcon from '@mui/icons-material/BrokenImage'

export const ImagePreview = (props) => {
  const { imageUrl, onRemoveImage, ...other } = props
  const [showBadge, setShowBadge] = useState(false)
  return (
    <Badge
      invisible={!showBadge}
      badgeContent={
        <IconButton
          aria-label="clear"
          onClick={onRemoveImage}
          sx={{
            padding: 0,
            borderRadius: '9999px',
            overflow: 'hidden',
            top: '12px'
          }}
        >
          <CancelIcon
            style={{
              background: 'white'
            }}
          />
        </IconButton>
      }
      sx={{
        height: '100%'
      }}
    >
      <Avatar
        variant="square"
        src={imageUrl}
        imgProps={{
          onLoad: () => setShowBadge(true),
          onError: () => setShowBadge(true)
        }}
        {...other}
      >
        <BrokenImageIcon
          sx={{
            fontSize: '144px'
          }}
        />
      </Avatar>
    </Badge>
  )
}

ImagePreview.propTypes = {
  imageUrl: PropTypes.string,
  onRemoveImage: PropTypes.func
}
