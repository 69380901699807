import { adminApi as generatedAdminApi } from '@/store/codegen/generated/adminApi'

export const credentialApi = generatedAdminApi.enhanceEndpoints({
  addTagTypes: ['credential'],
  endpoints: {
    listUserCredentials: {
      providesTags: (result, error) => {
        return (
          result
            ? [
                ...result.credentials.map(({ id }) => ({ type: 'credential', id })),
                // PARTIAL-LIST denotes that the list is partial, which is paginated
                { type: 'credential', id: 'PARTIAL-LIST' }
              ]
            : [{ type: 'credential', id: 'PARTIAL-LIST' }]
        )
      }
    },
    getCredential: {
      providesTags: (result, error) => result ? [{ type: 'credential', id: result.id }] : []
    },
    deleteCredential: {
      invalidatesTags: ['credential']
    }
  }
})

export const {
  useListUserCredentialsQuery,
  useGetCredentialQuery,
  useDeleteCredentialMutation,
  useCreateEnrollmentMutation
} = credentialApi
