import {
  createContext,
  useContext,
  useEffect,
  useState
} from 'react'
import PropTypes from 'prop-types'
import store from 'store2'

const initialSettings = {
  language: 'en',
  pinSidebar: true,
  theme: 'light'
}

export const restoreSettings = () => {
  let settings = null

  try {
    if (store.local.isFake()) {
      console.warn('Local storage is not available, use fakeStore instead')
    }
    if (store.local.has('settings')) {
      const storedData = store.local.get('settings')
      settings = JSON.parse(storedData)
    } else {
      settings = {
        language: 'en',
        pinSidebar: true,
        theme: 'light'
      }
    }
  } catch (err) {
    console.error(err)
    // If stored data is not a strigified JSON this will fail,
    // that's why we catch the error
  }

  return settings
}

export const storeSettings = (settings) => {
  if (store.local.isFake()) {
    console.warn('Local storage is not available, use fakeStore instead')
  }
  store.local.set('settings', JSON.stringify(settings))
}

export const SettingsContext = createContext({
  settings: initialSettings,
  saveSettings: () => { }
})

export const SettingsProvider = (props) => {
  const { children } = props
  const [settings, setSettings] = useState(initialSettings)

  useEffect(() => {
    const restoredSettings = restoreSettings()

    if (restoredSettings) {
      setSettings(restoredSettings)
    }
  }, [])

  const saveSettings = (updatedSettings) => {
    setSettings(updatedSettings)
    storeSettings(updatedSettings)
  }

  return (
    <SettingsContext.Provider
      value={{
        settings,
        saveSettings
      }}
    >
      {children}
    </SettingsContext.Provider>
  )
}

SettingsProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export const SettingsConsumer = SettingsContext.Consumer

export const useSettings = () => useContext(SettingsContext)
