import PropTypes from 'prop-types'
import Dropzone from 'react-dropzone'
import { Box, Typography } from '@mui/material'
import { useCallback } from 'react'
import { CircularLoading } from './circular-loading'

export const SingleFileUploader = (props) => {
  const { upload, handleError, isLoading, ...other } = props
  const textComponent = isLoading
    ? <CircularLoading />
    : <Typography
        color="neutral.400"
        fontWeight="bold"
        textAlign="center"
        width="100%"
      >
        Drop file here
        <br />
        or
        <br />
        Browse file
      </Typography>

  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    if (fileRejections.length !== 0) {
      fileRejections[0].errors.forEach(err => handleError?.(err))
      return
    }

    upload(acceptedFiles[0])
  }, [handleError, upload])

  return (
    <Dropzone
      disabled={isLoading}
      onDrop={onDrop}
      multiple={false}
      maxFiles={1}
      {...other}
    >
      {({ getRootProps, getInputProps, isDragActive }) => (
        <Box
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'neutral.100',
            display: 'flex',
            height: '144px',
            width: '100%',
            cursor: isLoading ? 'default' : 'pointer',
            opacity: isDragActive ? 0.6 : 1,
            '&:active': {
              opacity: 0.6
            }
          }}
          {...getRootProps()}
        >
          {textComponent}
          <input {...getInputProps()} />
        </Box>
      )}
    </Dropzone>
  )
}

SingleFileUploader.propTypes = {
  upload: PropTypes.func,
  handleError: PropTypes.func,
  isLoading: PropTypes.bool
}
