export const NIOMON_DITTO_DEVELOPER = 'niomon.ditto.developer'
export const NIOMON_AUTH_VIEWER = 'niomon.auth.viewer'
export const NIOMON_AUTH_EDITOR = 'niomon.auth.editor'
export const NIOMON_AUTH_ADMIN = 'niomon.auth.admin'
export const NIOMON_TENANT_OWNER = 'niomon.tenant.owner'
export const NIOMON_VIEWER = 'niomon.viewer'
export const NIOMON_EDITOR = 'niomon.editor'
export const NIOMON_ADMIN = 'niomon.admin'

export const roleVariants = [
  {
    value: NIOMON_TENANT_OWNER,
    label: 'Niomon Tenant Owner'
  },
  {
    value: NIOMON_ADMIN,
    label: 'Niomon Admin'
  },
  {
    value: NIOMON_EDITOR,
    label: 'Niomon Editor'
  },
  {
    value: NIOMON_VIEWER,
    label: 'Niomon Viewer'
  },
  {
    value: NIOMON_AUTH_ADMIN,
    label: 'Niomon Auth Admin'
  },
  {
    value: NIOMON_AUTH_EDITOR,
    label: 'Niomon Auth Editor'
  },
  {
    value: NIOMON_AUTH_VIEWER,
    label: 'Niomon Auth Viewer'
  },
  {
    value: NIOMON_DITTO_DEVELOPER,
    label: 'Ditto Developer'
  }
]
