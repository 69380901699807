import { roleVariants } from '@/schema/role/variants'

export const getRoleBindingKey = (roleBinding) => {
  if (typeof roleBinding !== 'object') {
    console.error('role binding should be an object')
    return 'invalid_role_binding'
  }
  if (!roleBinding.domain || !roleBinding.userId || !roleBinding.role) {
    console.error('invalid role binding object')
    return 'invalid_role_binding'
  }
  return `${roleBinding.domain}::${roleBinding.userId}::${roleBinding.role}`
}

export const getRoleKey = (roleState) => {
  if (typeof roleState !== 'object') {
    console.error('"role" should be an object')
    return 'invalid_role'
  }
  if (!roleState.user.id || !roleState.role) {
    console.error('invalid role object')
    return 'invalid_role'
  }
  return `${roleState.user.id}::${roleState.role}`
}

export const getRoleLabel = (role) => {
  return roleVariants.find(variant => variant.value === role)?.label
}
