import DashboardIcon from '@mui/icons-material/Dashboard'
import { grey } from '@mui/material/colors'

const icon = (props) => {
  return (
    <DashboardIcon
      htmlColor={grey[300]}
      sx={{
        height: 36,
        mr: 1,
        width: 36
      }}
      {...props}
    />
  )
}

export default icon
