import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'

import { tenantApi } from './api/tenant'
import { userApi } from './api/user'
import { clientApi } from './api/client'
import { currentUserApi } from './api/current-user'
import { paymentApi } from './api/payment'
import { credentialApi } from './api/credential'
import { roleApi } from './api/role'
import { roleBindingApi } from './api/role-binding'
import { cardLayoutApi } from './api/card-layout'
import { uploadApi } from './api/upload'
import { ApiKeyApi } from './api/api-key'
import { idpApi } from './api/idp'
import { idpUserApi } from './api/idp-user'
import { adminApi } from './codegen/generated/adminApi'
import tenantIdReducer from './tenant-id'

import { dittoClientApi } from './api/ditto/client'
import { dittoClientTokenApi } from './api/ditto/client-token'
import { dittoTenantApi } from './api/ditto/tenant'
import { sessionApi } from './api/session'

export const store = configureStore({
  reducer: {
    tenantId: tenantIdReducer,
    [tenantApi.reducerPath]: tenantApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [clientApi.reducerPath]: clientApi.reducer,
    [currentUserApi.reducerPath]: currentUserApi.reducer,
    [paymentApi.reducerPath]: paymentApi.reducer,
    [credentialApi.reducerPath]: credentialApi.reducer,
    [adminApi.reducerPath]: adminApi.reducer,
    [roleApi.reducerPath]: roleApi.reducer,
    [roleBindingApi.reducerPath]: roleBindingApi.reducer,
    [cardLayoutApi.reducerPath]: cardLayoutApi.reducer,
    [uploadApi.reducerPath]: uploadApi.reducer,
    [ApiKeyApi.reducerPath]: ApiKeyApi.reducer,
    [dittoClientApi.reducerPath]: dittoClientApi.reducer,
    [dittoClientTokenApi.reducerPath]: dittoClientTokenApi.reducer,
    [dittoTenantApi.reducerPath]: dittoTenantApi.reducer,
    [idpApi.reducerPath]: idpApi.reducer,
    [idpUserApi.reducerPath]: idpUserApi.reducer,
    [sessionApi.reducerPath]: sessionApi.reducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware()
    .concat(tenantApi.middleware)
    .concat(userApi.middleware)
    .concat(clientApi.middleware)
    .concat(credentialApi.middleware)
    .concat(currentUserApi.middleware)
    .concat(paymentApi.middleware)
    .concat(adminApi.middleware)
    .concat(roleApi.middleware)
    .concat(roleBindingApi.middleware)
    .concat(cardLayoutApi.middleware)
    .concat(uploadApi.middleware)
    .concat(ApiKeyApi.middleware)
    .concat(dittoClientApi.middleware)
    .concat(dittoClientTokenApi.middleware)
    .concat(dittoTenantApi.middleware)
    .concat(idpApi.middleware)
    .concat(idpUserApi.middleware)
    .concat(sessionApi.middleware)
})

setupListeners(store.dispatch)
