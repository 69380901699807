import { useCallback, useMemo, useState } from 'react'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import { Box, Container, Typography } from '@mui/material'
import { PageHeader } from '@/components/page-header'
import { Calendar as CalendarIcon } from '@/icons/calendar'
import { formatDistanceDateWithTime } from '@/utils/date'
import { StatusGrid } from '@/components/status-grid'
import PersonIcon from '@mui/icons-material/Person'
import { QueryGuard } from '@/components/query-guard'

import { useGetIdpQuery } from '@/store/api/idp'
import { IdpMenu } from '@/components/idp/idp-menu'

export const IdentityProvider = () => {
  const navigate = useNavigate()
  const { tenantId, idpId } = useParams()
  const [pending, setPending] = useState(false)

  const { data: idp, error, isLoading } = useGetIdpQuery({ idpId }, { skip: pending })

  const beforeDeleteIdp = useCallback(() => setPending(true), [])
  const onDeleteIdp = useCallback((err) => {
    if (err) {
      setPending(false)
    } else {
      navigate('..')
    }
  }, [navigate])

  const tabs = useMemo(() => [
    {
      href: `/${tenantId}/idps/${idpId}`,
      label: 'Settings'
    }
  ], [tenantId, idpId])

  const renderContent = () => {
    return (
      <QueryGuard
        isLoading={isLoading}
        statusCode={error?.status}
      >
        <PageHeader
          divider
          backTo="-1"
          backTitle="Identity Providers"
          title={idp?.name}
          tabs={tabs}
          actions={
            <IdpMenu
              idp={idp}
              onDeleteIdp={onDeleteIdp}
              beforeDeleteIdp={beforeDeleteIdp}
            />
          }
          descriptions={
            <>
              <StatusGrid>
                <PersonIcon
                  fontSize="small"
                  sx={{ color: 'text.secondary' }}
                />
                <Typography
                  color="textSecondary"
                  sx={{ ml: 0.5 }}
                  variant="body2"
                >
                  ID: {idp?.id}
                </Typography>
              </StatusGrid>
              <StatusGrid>
                <PersonIcon
                  fontSize="small"
                  sx={{ color: 'text.secondary' }}
                />
                <Typography
                  color="textSecondary"
                  sx={{ ml: 0.5 }}
                  variant="body2"
                >
                  Type: {idp?.type}
                </Typography>
              </StatusGrid>
              <StatusGrid>
                <CalendarIcon
                  fontSize="small"
                  sx={{ color: 'text.secondary' }}
                />
                <Typography
                  color="textSecondary"
                  sx={{ ml: 0.5 }}
                  variant="body2"
                >
                  Since: {formatDistanceDateWithTime(idp?.createdAt)}
                </Typography>
              </StatusGrid>
            </>
          }
        />
        <QueryGuard isLoading={pending} >
          <Outlet />
        </QueryGuard>
      </QueryGuard>
    )
  }

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        flexGrow: 1
      }}
    >
      <Container
        maxWidth="lg"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%'
        }}
      >
        {renderContent()}
      </Container>
    </Box>
  )
}
