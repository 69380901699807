import PropTypes from 'prop-types'
import toast from 'react-hot-toast'
import { IconButton, Menu, MenuItem } from '@mui/material'
import { ConfirmationDialog } from '@/components/confirmation-dialog'
import { useDialog } from '@/hooks/use-dialog'
import { usePopover } from '@/hooks/use-popover'
import { DotsVertical as DotsVerticalIcon } from '@/icons/dots-vertical'
import { useCallback } from 'react'
import { useDeleteIdpMutation } from '@/store/api/idp'

const useDeleteIdp = (idpId, beforeDeleteIdp, onDeleteIdp) => {
  const [open, handleOpen, handleClose] = useDialog()
  const [deleteIdpMutation] = useDeleteIdpMutation()

  const onConfirm = useCallback(() => {
    handleClose()
    beforeDeleteIdp?.()
    deleteIdpMutation({ idpId })
      .unwrap()
      .then(() => {
        onDeleteIdp?.()
        toast.success('Identity provider has been removed')
      })
      .catch((e) => {
        onDeleteIdp?.(e)
        console.error('Failed to delete identity provider: ', e)
        toast.error('Failed to delete this identity provider')
      })
  }, [handleClose, idpId, beforeDeleteIdp, deleteIdpMutation, onDeleteIdp])

  return [open, handleOpen, onConfirm, handleClose]
}

export const IdpMenu = (props) => {
  const [anchorRef, open, handleOpen, handleClose] = usePopover()
  const { idp, beforeDeleteIdp, onDeleteIdp, ...other } = props

  const [deleteOpen, handleDelete, onConfirmDelete, onCancelDelete] =
    useDeleteIdp(idp.id, beforeDeleteIdp, onDeleteIdp)

  const onAction = useCallback((next) => {
    return () => {
      handleClose()
      next()
    }
  }, [handleClose])

  return (
    <div onClick={ e => e.stopPropagation() }>
      <IconButton
        onClick={handleOpen}
        ref={anchorRef}
        {...other}
      >
        <DotsVerticalIcon fontSize="small" />
      </IconButton>
      <Menu
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        open={open}
        onClose={handleClose}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuItem onClick={onAction(handleDelete)}>
          Delete
        </MenuItem>
      </Menu>
      <ConfirmationDialog
        title="Delete Identity Provider"
        message="Are you sure you want to delete this identity provider?"
        onCancel={onCancelDelete}
        onConfirm={onConfirmDelete}
        open={deleteOpen}
        variant={'error'}
      />
    </div>
  )
}

IdpMenu.propTypes = {
  idp: PropTypes.object,
  onDeleteIdp: PropTypes.func,
  beforeDeleteIdp: PropTypes.func
}
