import { useSections } from '@/hooks/use-sections'
import { useGetCurrentUserQuery } from '@/store/api/current-user'
import { Navigate, useParams } from 'react-router-dom'

export const Landing = () => {
  const { tenantId } = useParams()
  const { data: currentUser, isFetching } = useGetCurrentUserQuery()
  const { landingPath } = useSections(tenantId, currentUser.roles[tenantId])

  if (isFetching || !landingPath) {
    return <></>
  }

  return (<Navigate to={landingPath} />)
}
