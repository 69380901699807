import { createBrowserHistory, createPath } from 'history'
import { useEffect, useState } from 'react'
import { useHref, useLocation } from 'react-router-dom'

export const useBackTo = (to) => {
  const browserHistory = createBrowserHistory()
  const path = useHref('..')
  const location = useLocation()
  const [backTo, setBackTo] = useState(null)
  useEffect(() => {
    // Set a new 'to' behaviour, -1 in string means either goes back
    // the recorded previous page or moves up in URL address
    if (to === '-1') {
      if (location.state) {
        setBackTo(createPath(browserHistory.location.state.location))
      } else {
        setBackTo(path)
      }
    }
  }, [browserHistory, location.state, path, to])

  return (backTo || to)
}
