import PropTypes from 'prop-types'
import { ErrorGuard } from './error-guard'
import { SkeletonLoading } from './skeleton-loading'
import { CircularLoading } from './circular-loading'

const loadingVariant = [
  'none',
  'circular',
  'skeleton'
]

export const QueryGuard = (props) => {
  const {
    children,
    isLoading,
    statusCode,
    variant
  } = props

  if (isLoading) {
    switch (variant) {
      case 'none':
        return <div />

      case 'circular':
        return <CircularLoading />

      case 'skeleton':
      default:
        return <SkeletonLoading />
    }
  }

  return (
    <ErrorGuard statusCode={statusCode}>
      {children}
    </ErrorGuard>
  )
}

QueryGuard.defaultProps = {
  variant: 'skeleton'
}

QueryGuard.propTypes = {
  children: PropTypes.node,
  isLoading: PropTypes.bool,
  statusCode: PropTypes.number,
  variant: PropTypes.oneOf(loadingVariant)
}
