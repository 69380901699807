import { useEffect } from 'react'
import { useRoutes } from 'react-router-dom'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { initializeI18n } from './i18n'
import { useSettings } from './contexts/settings-context'
import { useAuth } from './hooks/use-auth'
import routes from './routes'
import { createCustomTheme } from './theme'

export const App = () => {
  const content = useRoutes(routes)
  const { settings } = useSettings()
  const { isLoading } = useAuth()

  useEffect(() => {
    initializeI18n(settings.language)
  }, [settings])

  const theme = createCustomTheme({
    theme: settings.theme
  })

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {!isLoading && content}
    </ThemeProvider>
  )
}
