import { useCallback, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

export const useTableParams = (prop) => {
  const filterFields = prop?.filterFields

  const [searchParams, setSearchParams] = useSearchParams()

  const setParams = useCallback(
    (params) => {
      const newSearchParams = searchParams
      params.forEach(param => {
        const { type, field, value } = param

        // set page params
        if (type === 'page') {
          newSearchParams.set('page', value)
          return
        }

        // set filter params
        if (type === 'sort') {
          newSearchParams.set('sort', `${value ? '' : '-'}${field}`)
          return
        }

        // set filter params
        if (type === 'filter') {
          filterFields?.forEach(field => newSearchParams.delete(field))
          switch (field) {
            case 'all':
              break
            default:
              newSearchParams.set(field, value)
              break
          }
        }
      })
      setSearchParams(newSearchParams)
    },
    [searchParams, setSearchParams, filterFields]
  )

  // set params functions
  const setPageParam = useCallback(page => setParams([
    { type: 'page', value: page }
  ]), [setParams])
  const setSortParam = useCallback((field, ascOrder) => setParams([
    { type: 'sort', field, value: ascOrder },
    { type: 'page', value: '0' }
  ]), [setParams])
  const setFilterParam = useCallback((field, pattern) => setParams([
    { type: 'filter', field, value: pattern },
    { type: 'page', value: '0' }
  ]), [setParams])

  // params value
  const pageParams = useMemo(
    () => ({
      page: parseInt(searchParams.get('page'), 10) || undefined,
      pageSize: parseInt(searchParams.get('pageSize'), 10) || undefined
    }), [searchParams]
  )
  const sortParams = useMemo(() => ({ sort: searchParams.get('sort') || undefined }), [searchParams])
  const filterParams = useMemo(() => {
    const filterParams = {}
    filterFields?.forEach(field => {
      const value = searchParams.get(field)
      if (value) {
        filterParams[field] = value
      }
    })
    return filterParams
  }, [filterFields, searchParams])

  return { pageParams, sortParams, filterParams, setPageParam, setSortParam, setFilterParam }
}
