import NiomonLogo from '@/assets/niomon-logo.svg'
import { subDays, subHours, subMinutes } from 'date-fns'

const now = new Date()

export default [
  {
    id: 1,
    tenant_id: '000001',
    type: 'client.general',
    name: 'Niomon admin panel',
    description: 'Admin panel is the fundamental client of Niomon',
    client_id: 'niomon_admin_panel',
    logo: NiomonLogo,
    theme_color: '#184bca',
    allowed_relay_callback_urls: [
      'https://niomon.dev:8443/authorize'
    ],
    logout_url: [
      'https://niomon.dev:8443/logout'
    ],
    allowed_web_origins: [
      'https://niomon.io',
      'https://niomon.dev:8443'
    ],
    allowed_cors: [
      'https://*.niomon.dev:8443'
    ],
    // In seconds
    token_expiry: 36000,
    client_secret: 'f2ca1bb6c7e907d06dafe4687e579fce76b37e4e93b7605022da52e6ccc26fd2',
    created_at: subDays(subHours(subMinutes(now, 25), 9), 234)
  },
  {
    id: 2,
    tenant_id: '000002',
    type: 'client.general',
    name: 'LikerLand',
    description: 'LikerLand is an ecosystem encouraging creator by having LikeCoin',
    client_id: 'liker_land',
    logo: 'https://liker.land/apple-touch-icon.png',
    theme_color: '#28646e',
    allowed_relay_callback_urls: [
      'https://liker.land/authorize'
    ],
    logout_url: [
      'https://liker.land/logout'
    ],
    allowed_web_origins: [
      'http://localhost:3000'
    ],
    allowed_cors: [
      'https://*.liker.land'
    ],
    // In seconds
    token_expiry: 36000,
    client_secret: 'f2ca1bb6c7e907d06dafe4687e579fce76b37e4e93b7605022da52e6ccc26fd2',
    created_at: subDays(subHours(subMinutes(now, 3), 10), 4)
  },
  {
    id: 3,
    tenant_id: '000003',
    type: 'client.general',
    name: 'Encl',
    description: 'Encl is an end-to-end encrypted file sharing service',
    client_id: 'encl',
    logo: 'https://encl.io/assets/icons/android-chrome-512x512.png',
    theme_color: '#28646e',
    allowed_relay_callback_urls: [
      'https://encl.io/authorize'
    ],
    logout_url: [
      'https://liker.land/logout'
    ],
    allowed_web_origins: [
      'http://localhost:3000'
    ],
    allowed_cors: [
      'https://*.encl.io'
    ],
    // In seconds
    token_expiry: 36000,
    client_secret: 'f2ca1bb6c7e907d06dafe4687e579fce76b37e4e93b7605022da52e6ccc26fd2',
    created_at: subDays(subHours(subMinutes(now, 3), 10), 4)
  }
]
