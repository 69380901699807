import { createContext, useEffect, useReducer } from 'react'
import PropTypes from 'prop-types'
import { NiomonClient, resolveServiceHost } from '@niomon/niomon-js'
import { ADMIN_CONSOLE_TENANT, ADMIN_CONSOLE_CLIENT_ID } from '@/config'
import { GLOBAL_RESOURCE_NAMESPACE } from '@/constant'

export const client = new NiomonClient({
  baseURL: `${resolveServiceHost(window.origin, 'api')}/${ADMIN_CONSOLE_TENANT}`,
  clientId: ADMIN_CONSOLE_CLIENT_ID,
  redirectURI: `${window.origin}/${GLOBAL_RESOURCE_NAMESPACE}/callback`
})

const initialState = {
  isLoading: true,
  isFetching: false,
  isAuthenticated: false,
  user: null
}

const handlers = {
  LOAD: (state, action) => {
    const { isAuthenticated, user } = action.payload
    return {
      ...state,
      isLoading: false,
      isAuthenticated,
      user
    }
  },
  LOGIN: (state, action) => {
    const { user } = action.payload

    return {
      ...state,
      isAuthenticated: true,
      user
    }
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null
  })
}

const reducer = (state, action) => (handlers[action.type]
  ? handlers[action.type](state, action)
  : state)

export const AuthContext = createContext({
  ...initialState,
  loginWithRedirect: () => Promise.resolve(),
  handleAuthCallback: () => Promise.resolve(),
  logout: () => Promise.resolve()
})

export const AuthProvider = (props) => {
  const { children } = props
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = await client.getTokenSilently()
        if (accessToken) {
          const user = await client.getUser()
          dispatch({
            type: 'LOAD',
            payload: {
              isAuthenticated: true,
              user
            }
          })
        } else {
          dispatch({
            type: 'LOAD',
            payload: {
              isAuthenticated: false,
              user: null
            }
          })
        }
      } catch (err) {
        console.error(err)
        await client.logout()
        dispatch({
          type: 'LOAD',
          payload: {
            isAuthenticated: false,
            user: null
          }
        })
      }
    }

    initialize()
  }, [])

  const loginWithRedirect = () => {
    client.loginWithRedirect()
  }

  const handleAuthCallback = async () => {
    try {
      await client.handleAuthCallback()
      const user = await client.getUser()
      dispatch({
        type: 'LOGIN',
        payload: {
          user
        }
      })
    } catch (e) {
      console.error('Oauth callback error: ', e)
    }
  }

  const logout = async () => {
    await client.logout()
    dispatch({ type: 'LOGOUT' })
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        loginWithRedirect,
        handleAuthCallback,
        logout
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
}
