import PropTypes from 'prop-types'
import { Avatar, Box } from '@mui/material'
import { Label } from './label'

const AvatarComponent = (props) => {
  const { avatar, ...other } = props
  if (avatar === undefined) {
    return <div />
  } else if (typeof (avatar) === 'string') {
    return (
      <Avatar
        src={avatar}
        variant="rounded"
        sx={{
          height: 36,
          mr: 1,
          width: 36,
          background: 'transparent'
        }}
        imgProps={{
          sx: {
            width: 'auto',
            height: 'auto',
            maxHeight: '100%',
            maxWidth: '100%'
          }
        }}
        {...other}
      />
    )
  }

  return avatar
}

AvatarComponent.propTypes = {
  avatar: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ])
}

export const TitleWithAvatar = (props) => {
  const { title, titleProps, avatar, avatarProps, sx, ...other } = props

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        ...sx
      }}
      {...other}
    >
      <AvatarComponent
        avatar={avatar}
        {...avatarProps}
      />
      <Label
        title={title}
        {...titleProps}
      />
    </Box>
  )
}

TitleWithAvatar.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]),
  titleProps: PropTypes.object,
  avatar: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]),
  avatarProps: PropTypes.object,
  sx: PropTypes.object
}
