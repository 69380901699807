import PropTypes from 'prop-types'
import {
  Box,
  ButtonBase,
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography
} from '@mui/material'
import { usePopover } from '@/hooks/use-popover'
import { Selector as SelectorIcon } from '@/icons/selector'

import { ALL_TENANTS_KEY } from './dashboard-navbar'
import { ROOT_TENANT } from '@/config'
import { GLOBAL_RESOURCE_NAMESPACE } from '@/constant'

export const TenantPopover = (props) => {
  const {
    currentTenantId,
    tenantIds,
    onTenantChange,
    sx,
    ...other
  } = props
  const [anchorRef, open, handleOpen, handleClose] = usePopover()

  const handleTenantChange = (tenantId) => {
    handleClose()
    onTenantChange?.(tenantId)
  }
  const displayName = currentTenantId === GLOBAL_RESOURCE_NAMESPACE ? 'All tenants' : currentTenantId

  return (
    <>
      <ButtonBase
        title={displayName}
        onClick={handleOpen}
        ref={anchorRef}
        sx={{
          borderRadius: 1,
          display: 'flex',
          p: 1,
          width: 180,
          '&:hover': {
            backgroundColor: 'rgba(255,255,255,0.1)'
          },
          ...sx
        }}
        {...other}
      >
        <Typography
          noWrap
          color="textSecondary"
          sx={{
            color: 'primary.contrastText',
            mr: 3
          }}
          variant="subtitle2"
        >
          {displayName}
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        <SelectorIcon fontSize="small" />
      </ButtonBase>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'bottom'
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 200 }
        }}
      >
        <List>
          {tenantIds.map((tenantId, index) => (
            <ListItem
              key={tenantId}
              divider={tenantIds.length - 1 === index}
              button
              selected={currentTenantId === tenantId}
              onClick={() => handleTenantChange(tenantId)}
            >
              {/* display tenant id because tenant admin don't have an effective way to get tenant name */}
              <ListItemText primary={tenantId} />
            </ListItem>
          ))}
          {tenantIds.includes(ROOT_TENANT) &&
            <ListItem
              key={ALL_TENANTS_KEY}
              button
              selected={currentTenantId === GLOBAL_RESOURCE_NAMESPACE}
              onClick={() => handleTenantChange(ALL_TENANTS_KEY)}
            >
              <ListItemText primary="All tenants" />
            </ListItem>
          }
        </List>
      </Popover>
    </>
  )
}

TenantPopover.propTypes = {
  currentTenantId: PropTypes.string,
  onTenantChange: PropTypes.func,
  tenantIds: PropTypes.array.isRequired,
  sx: PropTypes.object
}
