import PropTypes from 'prop-types'
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Divider,
  Typography
} from '@mui/material'
import { formatDistanceDateWithTime } from '@/utils/date'
import { CredentialCardMenu } from './credential-card-menu'
import IphoneIcon from '@/components/iphone-icon'

export const CredentialCard = (props) => {
  const { credential, deviceIcon, cardSx } = props

  return (
    <Card
      sx={{
        boxShadow: '0px 1px 2px 0px rgba(9, 30, 66, 0.08)',
        ...cardSx
      }}
      variant="outlined"
    >
      <Box
        sx={{
          position: 'relative'
        }}
      >
        <CardMedia
          sx={{
            height: 108,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          {deviceIcon}
          <Box
            sx={{
              position: 'absolute',
              top: 5,
              right: 5
            }}
          >
            <CredentialCardMenu
              credential={credential}
            />
          </Box>
        </CardMedia>
      </Box>
      <Divider />
      <CardContent
        sx={{
          height: 108
        }}
      >
        <Typography
          noWrap
          variant='subtitle2'
        >
          {credential?.id}
        </Typography>
        <Typography
          variant='body2'
        >
          {`Last used: ${formatDistanceDateWithTime(credential?.lastUsedAt)}`}
        </Typography>
      </CardContent>
    </Card>
  )
}

CredentialCard.defaultProps = {
  deviceIcon: IphoneIcon
}

CredentialCard.propTypes = {
  credential: PropTypes.object,
  cardSx: PropTypes.object,
  deviceIcon: PropTypes.node
}
