import * as yup from 'yup'
import { tenantIdSchema, tenantNameSchema, urlSchema } from '@/schema/common'

const schema = yup.object({
  friendlyName: tenantNameSchema().required(),
  id: tenantIdSchema().required('Company ID cannot be empty'),
  homepage: urlSchema(),
  size: yup.string()
})

export default schema
