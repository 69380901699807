import { adminApi as generatedAdminApi } from '@/store/codegen/generated/adminApi'
import { getRoleBindingKey } from '@/utils/role'

export const roleBindingApi = generatedAdminApi.enhanceEndpoints({
  addTagTypes: ['roleBinding'],
  endpoints: {
    listRoleBindings: {
      providesTags: (result, error) => {
        return (
          result
            ? [
                ...result.roleBindings.map((roleBinding) => ({
                  type: 'roleBinding',
                  id: getRoleBindingKey(roleBinding)
                })),
                // PARTIAL-LIST denotes that the list is partial, which is paginated
                { type: 'roleBinding', id: 'PARTIAL-LIST' }
              ]
            : [{ type: 'roleBinding', id: 'PARTIAL-LIST' }]
        )
      }
    }
  }
})

export const {
  useListRoleBindingsQuery
} = roleBindingApi
