import PropTypes from 'prop-types'
import schema from '@/schema/ditto/tenant/create-tenant-schema'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Grid, ToggleButton, ToggleButtonGroup } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useEffect } from 'react'
import { FormField } from '@/components/form-field'
import { useSearchParams } from 'react-router-dom'
import { NIOMON_ADMIN } from '@/schema/role/variants'

export const OnboardingForm = (props) => {
  const [searchParams] = useSearchParams()
  const methods = useForm({
    mode: 'onBlur',
    defaultValues: {
      // By default `roles` is not provided to the create tenant api endpoint request body so that the server
      // will assign the default roles from the config file. If `?feature=auth` present in the url params of
      // the onboarding form, specify the roles assigned to the user for accessing the auth feature of the tenant.
      roles: searchParams.get('feature') === 'auth' ? [NIOMON_ADMIN] : null
    },
    resolver: yupResolver(schema)
  })

  const { handleSubmit, setValue, watch, clearErrors, setError, trigger, formState: { errors, isValidating } } = methods

  const { onSubmit } = props
  const friendlyName = watch('friendlyName')
  const generatedId = friendlyName?.trim()?.toLowerCase()?.replace(/ /g, '-')

  const onSubmitForm = (data) => {
    onSubmit(data, setError)
  }

  useEffect(() => {
    if (generatedId) {
      clearErrors('id')
      setValue('id', generatedId)
      trigger('id')
    }
  }, [clearErrors, generatedId, setValue, trigger])

  const formFields = [
    {
      id: 'friendlyName',
      label: 'Company Name',
      helperText: 'At least 2 character with letters, digits and space only',
      placeholder: 'Your company name',
      required: true,
      sx: {
        width: '60%',
        minWidth: '270px'
      }
    },
    {
      id: 'id',
      label: 'Company ID',
      helperText: 'At least 2 character with letters, digits and \'-\' only',
      placeholder: 'your-company-id',
      required: true,
      sx: {
        width: '60%',
        minWidth: '270px'
      }
    },
    {
      id: 'homepage',
      label: 'Company Website',
      helperText: errors.homepage?.message,
      placeholder: 'https://example.com',
      sx: {
        width: '60%',
        minWidth: '270px'
      }
    },
    {
      id: 'size',
      label: 'Company Size',
      type: 'custom',
      component: ({ onChange, onBlur, value, ref }) => (
        <ToggleButtonGroup
          exclusive
          ref={ref}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          sx={{
            minWidth: '270px'
          }}
        >
          <ToggleButton value="small">
            1-49
          </ToggleButton>
          <ToggleButton value="medium">
            50-99
          </ToggleButton>
          <ToggleButton value="large">
            200-999
          </ToggleButton>
          <ToggleButton value="enterprise">
            1000+
          </ToggleButton>
        </ToggleButtonGroup>
      )
    }
  ]

  return (
    <form onSubmit={handleSubmit(onSubmitForm)} >
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        spacing={4}
      >
        {formFields.map((fieldProp) => (
          <FormField
            key={fieldProp.id}
            methods={methods}
            {...fieldProp}
          />
        ))}
        <Grid
          item
          xs={12}
          mt={4}
        >
          <Button
            type="submit"
            color="primary"
            variant="contained"
            disabled={isValidating}
          >
            Get Started
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

OnboardingForm.propTypes = {
  onSubmit: PropTypes.func
}
