import { createSlice } from '@reduxjs/toolkit'
import store from 'store2'

export const restoreTenantId = () => {
  // check if local storage is supported by the browser, show warning if not supported
  // e.g. private browsing on old version of safari will disable local storage and session storage
  if (store.isFake()) {
    console.warn('Local storage is not available, use fakeStore instead')
  }

  if (store.local.has('tenantId')) {
    return store.local.get('tenantId')
  }
  return ''
}

const storeTenantId = (id) => {
  // check if local storage is supported by the browser, show warning if not supported
  // e.g. private browsing on old version of safari will disable local storage and session storage
  if (store.local.isFake()) {
    console.warn('Local storage is not available, use fakeStore instead')
  }

  store.local.set('tenantId', id)
}

export const slice = createSlice({
  name: 'tenantId',
  initialState: {
    value: restoreTenantId()
  },
  reducers: {
    switchTenant: (state, action) => {
      if (state.value !== action.payload) {
        state.value = action.payload
        storeTenantId(action.payload)
      }
    }
  }
})

export const { switchTenant } = slice.actions

export const selectTenantId = state => state.tenantId.value

export default slice.reducer
