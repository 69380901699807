import { useParams, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useGetCurrentUserQuery } from '@/store/api/current-user'
import { selectTenantId } from '@/store/tenant-id'
import { GLOBAL_RESOURCE_NAMESPACE } from '@/constant'
import { useEffect } from 'react'

export const NavigateToTenant = () => {
  const navigate = useNavigate()
  const { tenantId } = useParams()
  const currentTenantId = useSelector(selectTenantId)
  const { data: currentUser, isFetching } = useGetCurrentUserQuery(undefined, { skip: !!tenantId })

  useEffect(() => {
    if (!isFetching) {
      const roles = currentUser?.roles
      const tenantIds = roles ? Object.keys(roles) : []
      // Pick a default tenantId to redirect to
      if (tenantIds.length === 0) {
        // User has no admin access to any tenants
        // NOTE: currently get-started page is supposed for registering ditto tenant, if user need to
        // create tenant with auth feature access, visit `/-/get-started?feature=auth` instead
        navigate(`/${GLOBAL_RESOURCE_NAMESPACE}/get-started`)
      } else if (currentTenantId && tenantIds.includes(currentTenantId)) {
        // If there is stored tenantId and the user has access to it
        navigate(`/${encodeURIComponent(currentTenantId)}/`)
      } else {
        // Change store's tenantId to first tenant Id that user owns
        tenantIds.sort()
        navigate(`/${encodeURIComponent(tenantIds[0])}/`)
      }
    }
  }, [isFetching, currentUser, tenantId, currentTenantId, navigate])

  return <></> // Return a blank element as fallback
}

NavigateToTenant.propTypes = {
}
