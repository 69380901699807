import PropTypes from 'prop-types'
import store from 'store2'
import { useAuth } from '@/hooks/use-auth'
import { Login } from '@/containers/login'
import { useGetCurrentUserQuery } from '@/store/api/current-user'
import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ADMIN_CONSOLE_VISIT_URL_KEY } from '@/config'

export const AuthGuard = (props) => {
  const { children } = props
  const auth = useAuth()
  const location = useLocation()
  const navigate = useNavigate()
  const { isFetching, error } = useGetCurrentUserQuery(undefined, { skip: !auth.isAuthenticated })

  useEffect(() => {
    if (!auth.isAuthenticated) {
      if (store.session.isFake()) {
        console.warn('Session storage is not available, use fakeStore instead')
      }
      store.session.set(ADMIN_CONSOLE_VISIT_URL_KEY, JSON.stringify(location))
    } else if (store.session.has(ADMIN_CONSOLE_VISIT_URL_KEY)) {
      const requestedLocation = store.session.get(ADMIN_CONSOLE_VISIT_URL_KEY)
      store.session.remove(ADMIN_CONSOLE_VISIT_URL_KEY)
      navigate(JSON.parse(requestedLocation))
    }
  }, [auth.isAuthenticated, location, navigate])

  if (!auth.isAuthenticated) {
    return (
      <Login />
    )
  }

  if (!isFetching) {
    // currentUser return error
    if (error) {
      if (error.status === 401) {
        // delay 3s for user to read the toast message of 401 error
        setTimeout(auth.logout, 3000)
      }
      return <></> // Return a blank element as fallback
    }
  }
  if (store.session.has(ADMIN_CONSOLE_VISIT_URL_KEY)) {
    return <></>
  }
  return <>{children}</>
}

AuthGuard.propTypes = {
  children: PropTypes.node
}
