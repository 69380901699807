import PropTypes from 'prop-types'
import { ConfirmationDialog } from '@/components/confirmation-dialog'
import toast from 'react-hot-toast'
import { useUpdateTenantMutation } from '@/store/api/tenant'

export const ActivationTenantConfirmationDialog = (props) => {
  const { open, handleClose, tenant, ...other } = props
  const [updateTenant] = useUpdateTenantMutation()
  const dialogTitle = tenant?.status === 'active'
    ? 'Deactivate Tenant'
    : 'Activate Tenant'

  const dialogMessage = tenant?.status === 'active'
    ? 'Are you sure you want to deactivate this tenant?'
    : 'Are you sure you want to activate this tenant?'

  const onConfirm = () => {
    const newStatus = tenant?.status === 'active'
      ? 'inactive'
      : 'active'

    const tenantUpdate = { tenantId: tenant?.id, body: { status: newStatus } }
    const updateAction = newStatus === 'active'
      ? 'activated'
      : 'deactivated'

    updateTenant(tenantUpdate)
      .unwrap()
      .then(() => {
        toast(`Tenant has been ${updateAction}`)
        handleClose()
      })
      .catch(error => {
        if (error.data.message) {
          toast.error(error.data.message)
        }
      })
  }
  return (
    <ConfirmationDialog
      title={dialogTitle}
      message={dialogMessage}
      onCancel={handleClose}
      onConfirm={onConfirm}
      open={open}
      variant={tenant?.status === 'active' ? 'error' : 'info'}
      {...other}
    />
  )
}

ActivationTenantConfirmationDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  tenant: PropTypes.object
}
