import { useEffect, useState } from 'react'
import { useLocation, matchPath } from 'react-router-dom'
import PropTypes from 'prop-types'
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  List
} from '@mui/material'
import { DashboardSidebarItem } from './dashboard-sidebar-item'
import { Scrollbar } from './scrollbar'
import { ChevronLeft as ChevronLeftIcon } from '@/icons/chevron-left'
import { ChevronRight as ChevronRightIcon } from '@/icons/chevron-right'
import { useSections } from '@/hooks/use-sections'
import { useGetCurrentUserQuery } from '@/store/api/current-user'
import { selectTenantId } from '@/store/tenant-id'
import { useSelector } from 'react-redux'

export const DashboardSidebar = (props) => {
  const { onPin, pinned } = props
  const { pathname } = useLocation()
  const tenantId = useSelector(selectTenantId)
  const { data: currentUser } = useGetCurrentUserQuery()
  const { navigationItems } = useSections(tenantId, currentUser.roles[tenantId])
  const [openedItem, setOpenedItem] = useState(null)
  const [activeItem, setActiveItem] = useState(null)
  const [activeHref, setActiveHref] = useState('')
  const [hovered, setHovered] = useState(false)

  const handleOpenItem = (item) => {
    if (openedItem === item) {
      setOpenedItem(null)
      return
    }

    setOpenedItem(item)
  }

  useEffect(() => {
    navigationItems.forEach((item) => {
      if (item.items) {
        for (let index = 0; index < item.items.length; index++) {
          const active = matchPath({ path: item.items[index].href, end: true }, pathname)

          if (active) {
            setActiveItem(item)
            setActiveHref(item.items[index].href)
            setOpenedItem(item)
            break
          }
        }
      } else {
        const active = !!matchPath({ path: item.href, end: true }, pathname)

        if (active) {
          setActiveItem(item)
          setOpenedItem(item)
        }
      }
    })
  }, [navigationItems, pathname])

  return (
    <Drawer
      open
      sx={{ zIndex: 1000 }}
      variant="permanent"
      PaperProps={{
        onMouseOver: () => { setHovered(true) },
        onMouseLeave: () => { setHovered(false) },
        sx: {
          backgroundColor: 'background.paper',
          height: 'calc(100% - 64px)',
          overflowX: 'hidden',
          top: 64,
          transition: 'width 250ms ease-in-out',
          width: pinned ? 270 : 73,
          '& .simplebar-content': {
            height: '100%'
          },
          '&:hover': {
            width: 270,
            '& span, p': {
              display: 'flex'
            }
          }
        }
      }}
    >
      <Scrollbar
        style={{
          display: 'flex',
          flex: 1,
          overflowX: 'hidden',
          overflowY: 'auto'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            p: 2
          }}
        >
          <List disablePadding>
            {(navigationItems.map((item) => (
              <DashboardSidebarItem
                active={activeItem?.title === item.title}
                activeHref={activeHref}
                divider={item.divider}
                key={item.title}
                onOpen={() => handleOpenItem(item)}
                open={openedItem?.title === item.title && (hovered || pinned)}
                pinned={pinned}
                {...item}
              />
            )))}
          </List>
          <Box sx={{ flexGrow: 1 }} />
          <Divider />
          <Box sx={{ pt: 1 }}>
            <IconButton onClick={onPin}>
              {pinned ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </Box>
        </Box>
      </Scrollbar>
    </Drawer>
  )
}

DashboardSidebar.propTypes = {
  onPin: PropTypes.func,
  pinned: PropTypes.bool
}
