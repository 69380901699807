export const statusVariants = [
  {
    color: 'error.main',
    label: 'Inactive',
    value: 'inactive'
  },
  {
    color: 'success.main',
    label: 'Active',
    value: 'active'
  }
]

export const subscriptionVariants = [
  {
    value: 'Enterprise',
    label: 'Enterprise'
  },
  {
    value: 'Free',
    label: 'Free'
  }
]
