import PropTypes from 'prop-types'
import { Typography } from '@mui/material'

export const Label = (props) => {
  const { title, ...other } = props

  if (typeof (title) === 'string') {
    return (
      <Typography
        noWrap
        variant="inherit"
        {...other}
      >
          {title}
      </Typography>
    )
  }

  return title
}

Label.defaultProps = {
  title: ''
}

Label.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ])
}
