import * as yup from 'yup'
import { colorCodeRegex, localeRegex, phoneRegex, tenantIdRegex, tenantNameRegex } from '@/utils/regex'

export const tenantIdSchema = () => yup.string()
  .max(256, 'Tenant id too long')
  .matches(tenantIdRegex, { message: 'Invalid tenant id' })

export const tenantNameSchema = () => yup.string()
  .max(256, 'Tenant name too long')
  .matches(tenantNameRegex, { message: 'Invalid tenant name' })

export const urlSchema = (showUrl = false) => yup.string()
  .max(2048, 'URL too long')
  .test(
    'url',
    (obj) => (
      showUrl
        ? `Invalid URL format: ${obj.value}`
        : 'Invalid URL format'
    ),
    (value) => {
      if (value === '') {
        return true
      }
      try {
        const valid = new URL(value)
        return Boolean(valid)
      } catch {
        return false
      }
    })

export const colorCodeSchema = () => yup.string()
  .matches(colorCodeRegex, { message: 'Invalid color code' })

export const emailSchema = () => yup.string()
  .max(320, 'Email too long')
  .email('Invalid email format')

export const phoneNumberSchema = () => yup.string()
  .matches(phoneRegex, { message: 'Invalid phone number', excludeEmptyString: true })

// TODO: may add a transform function to normalize the locale (e.g. en-us => en-US)
export const localeSchema = () => yup.string()
  .matches(localeRegex, { message: 'Invalid locale string', excludeEmptyString: true })

export const urlArrayStringSchema = () => yup.array(urlSchema(true))
  .transform((value, originalValue) => {
    // split by ',' => trim space => filter out empty string
    return originalValue.split(',').map(url => url.trim()).filter(item => item)
  })
