import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { selectTenantId, switchTenant } from '@/store/tenant-id'
import { GLOBAL_RESOURCE_NAMESPACE } from '@/constant'
import { useGetCurrentUserQuery } from '@/store/api/current-user'
import { QueryGuard } from './query-guard'

export const TenantGuard = ({ children }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { tenantId = GLOBAL_RESOURCE_NAMESPACE } = useParams()
  const storeTenantId = useSelector(selectTenantId)
  const { data: currentUser, isFetching, error } = useGetCurrentUserQuery()

  useEffect(() => {
    dispatch(switchTenant(tenantId))
  }, [dispatch, tenantId])

  useEffect(() => {
    if (currentUser && !isFetching && !error) {
      const roles = currentUser.roles
      const tenantIds = roles ? Object.keys(roles) : []
      if (tenantIds.length === 0) {
        navigate(`/${GLOBAL_RESOURCE_NAMESPACE}/get-started`)
      }
    }
  }, [currentUser, isFetching, error, navigate, dispatch, tenantId])

  const updatingStoreTenantId = storeTenantId !== tenantId

  return (
    <QueryGuard
      isLoading={isFetching || updatingStoreTenantId}
      statusCode={error?.status}
    >
      {children}
    </QueryGuard>
  )
}

TenantGuard.propTypes = {
  children: PropTypes.node
}
