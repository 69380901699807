// eslint-disable-next-line max-len
// General Error handling: https://redux-toolkit.js.org/rtk-query/usage/customizing-queries#automatic-re-authorization-by-extending-fetchbasequery
import { fetchBaseQuery } from '@reduxjs/toolkit/query'
import toast from 'react-hot-toast'
import { resolveServiceHost } from '@niomon/niomon-js'

import { client } from '../contexts/auth-context'
import { ROOT_TENANT } from '@/config'
import { GLOBAL_RESOURCE_NAMESPACE } from '@/constant'
import { selectTenantId } from './tenant-id'
import { getCurrentUser } from './api/current-user'

// hardcoded url for getting mock respond, used by mockQuery
export const mockServerUrl = 'https://mock.niomon.io'

// select store's tenant id via tenant state
const getTenantIdFromStore = (state) => {
  return selectTenantId(state)
}

const baseQuery = (basePath) => fetchBaseQuery({
  baseUrl: resolveServiceHost(location.origin, 'api') + basePath,
  prepareHeaders: async (headers) => {
    try {
      const token = await client.getTokenSilently()
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }
    } catch (e) {
    }

    return headers
  }
})

const queryResponseHandler = (args, api, extraOptions) => (res) => {
  if (res.error) {
    switch (res.error.status) {
      case 500:
        toast.error('Internal error, please retry later')
        break
      case 404:
        toast.error('The corresponding target is not found')
        break
      case 401:
        if (api.endpoint === 'getCurrentUser') {
          toast.error('Your session is expired. Please login again.')
          break
        }
        // Re-trigger getCurrentUser endpoint to rerender <AuthGuard>. Logout if getCurrentUser return 401.
        api.dispatch(getCurrentUser.initiate())
        break
    }
    console.error(res.error)
  }

  return res
}

const queryErrorHandler = () => (e) => console.error(e)

const baseQueryWrapper = (basePath, args, api, extraOptions) => {
  return baseQuery(basePath)(args, api, extraOptions)
    .then(queryResponseHandler(args, api, extraOptions), queryErrorHandler(args, api, extraOptions))
}

// base query for mock api calling
export const mockQuery = fetchBaseQuery({
  baseUrl: mockServerUrl
})

// base query for internal api calling
export const internalApiBaseQuery = (args, api, extraOptions) => {
  let tenantId = extraOptions?.tenantId
  if (!tenantId) {
    tenantId = getTenantIdFromStore(api.getState())
  }
  return baseQueryWrapper(`/${tenantId}/internal`, args, api, extraOptions)
}

// base query for admin api calling
export const adminApiBaseQuery = (args, api, extraOptions) => {
  let tenantId = extraOptions?.tenantId
  if (!tenantId) {
    tenantId = getTenantIdFromStore(api.getState())

    // XXX: This is difference from internalApiBaseQuery
    if (tenantId === GLOBAL_RESOURCE_NAMESPACE) {
      tenantId = ROOT_TENANT
    }
  }
  return baseQueryWrapper(`/${tenantId}`, args, api, extraOptions)
}

export const dittoApiBaseQuery = (args, api, extraOptions) => {
  let tenantId = extraOptions?.tenantId
  if (!tenantId) {
    tenantId = getTenantIdFromStore(api.getState())

    // XXX: This is difference from internalApiBaseQuery
    if (tenantId === GLOBAL_RESOURCE_NAMESPACE) {
      tenantId = ROOT_TENANT
    }
  }
  return baseQueryWrapper(`/${tenantId}/ditto`, args, api, extraOptions)
}
