import toast from 'react-hot-toast'

/**
 * Returns a function that handle a API error response.
 *
 * @param {*} setError setError function from useForm.
 */
export const handleFormError = (setError) => {
  return (resp) => {
    switch (resp.status) {
      case 400:
        if (resp.data?.fields) {
          mapFieldsError(resp, setError)
        } else {
          toast.error('Invalid arguments')
        }
        return
      case 409:
        if (resp.data?.fields) {
          mapFieldsError(resp, setError)
        } else {
          toast.error('Conflicted request. Please try again')
        }
        return
      case 413:
        if (resp.data?.fields) {
          mapFieldsError(resp, setError)
        } else {
          toast.error('File size too large. Please try again')
        }
        return
    }
    toast.error('Unknown API error')
  }
}

const mapFieldsError = (resp, setError) => {
  if (Array.isArray(resp.data?.fields)) {
    resp.data.fields.forEach((error) => {
      setError(error.field, {
        type: 'manual',
        message: translateError(error.description)
      })
    })
  }
}

export const translateError = (message) => {
  switch (message) {
    case 'userEmailNotExists':
      return 'User with this email address does not exist'
    case 'required':
      return 'Required'
    case 'duplicated':
      return 'Already exists'
    case 'invalidFileType':
      return 'Invalid File Type'
  }
  return 'Is invalid'
}
