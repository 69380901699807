import { adminApi as generatedAdminApi } from '@/store/codegen/generated/adminApi'

export const idpApi = generatedAdminApi.enhanceEndpoints({
  addTagTypes: ['idp'],
  endpoints: {
    listIdps: {
      providesTags: (result) => {
        return (
          result
            ? [
                ...result.idps.map(({ id }) => ({ type: 'idp', id })),
                // PARTIAL-LIST denotes that the list is partial, which is paginated
                { type: 'idp', id: 'PARTIAL-LIST' }
              ]
            : [{ type: 'idp', id: 'PARTIAL-LIST' }]
        )
      }
    },
    getIdp: {
      providesTags: (result) => result ? [{ type: 'idp', id: result.id }] : []
    },
    createIdp: {
      invalidatesTags: ['idp']
    },
    updateIdp: {
      invalidatesTags: (result, error, { id }) => [{ type: 'idp', id }]
    },
    deleteIdp: {
      invalidatesTags: ['idp']
    }
  }
})

export const {
  useListIdpsQuery,
  useGetIdpQuery,
  useCreateIdpMutation,
  useUpdateIdpMutation,
  useDeleteIdpMutation
} = idpApi
