import { subDays, subHours, subMinutes } from 'date-fns'

const now = new Date()

export default [
  {
    id: 'D-faacbd39c3',
    merchant_id: '22098DaJrDAJKAVqUIQm99QYK8h',
    status: 'accepted',
    created_at: subDays(subHours(subMinutes(now, 25), 1), 234),
    total: {
      // eslint-disable-next-line max-len
      label: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
      amount: {
        currency: 'USD',
        value: '65.00'
      }
    },
    displayItems: [
      {
        label: 'Donation1 amount',
        amount: {
          currency: 'USD',
          value: '15.00'
        }
      },
      {
        label: 'Donation2 amount',
        amount: {
          currency: 'USD',
          value: '32.00'
        }
      },
      {
        label: 'Donation3 amount',
        amount: {
          currency: 'USD',
          value: '18.00'
        }
      }
    ]
  },
  {
    id: 'D-05a0d143f6',
    merchant_id: '22098DaJrDAJKAVqUIQm99QYK8h',
    status: 'rejected',
    created_at: subMinutes(now, 3),
    total: {
      label: 'Iphone 19',
      amount: {
        currency: 'USD',
        value: '3367.00'
      }
    },
    displayItems: [
      {
        label: 'Iphone 19 x 1',
        amount: {
          currency: 'USD',
          value: '3361.00'
        }
      }
    ]
  },
  {
    id: 'D-7efbe427b6',
    merchant_id: '22098DaJrDAJKAVqUIQm99QYK8h',
    status: 'accepted',
    created_at: subDays(subHours(subMinutes(now, 5), 10), 5),
    total: {
      label: 'Hair Dryer Meta zXm 617v3123',
      amount: {
        currency: 'JPY',
        value: '3199999.00'
      }
    },
    displayItems: [
      {
        label: 'Hair Dryer Meta zXm 617v3123',
        amount: {
          currency: 'JPY',
          value: '3199999.00'
        }
      }
    ]
  },
  {
    id: 'D-339b37d002',
    merchant_id: '22098DaJrDAJKAVqUIQm99QYK8h',
    status: 'pending',
    created_at: subDays(subHours(subMinutes(now, 2), 11), 333),
    total: {
      label: 'Logitech Mouse M331 feat. lll version 3.17.10.666666666',
      amount: {
        currency: 'HKD',
        value: '159.00'
      }
    },
    displayItems: [
      {
        label: 'Logitech Mouse M331 (20% discount)',
        amount: {
          currency: 'HKD',
          value: '159.00'
        }
      }
    ]
  }
]
