import { Helmet } from 'react-helmet-async'
import { Box, Grid, Typography, useMediaQuery } from '@mui/material'
import { OnboardingForm } from '@/components/ditto/onboarding-form'
import { ReactComponent as DittoLogo } from '@/assets/ditto/ditto-logo-color-inverted.svg'
import { ReactComponent as NiomonLogo } from '@/assets/niomon-logo.svg'
import { AccountPopover } from '@/components/account-popover'
import { currentUserApi, useGetCurrentUserQuery } from '@/store/api/current-user'
import { useCreateDittoTenantMutation } from '@/store/api/ditto/tenant'
import { handleFormError } from '@/utils/form-error'
import { useNavigateWithHistory } from '@/hooks/use-navigate-with-history'
import { useDispatch } from 'react-redux'

const useCreateDittoTenant = (callback) => {
  const [createTenant, { isLoading }] = useCreateDittoTenantMutation()

  const onSubmit = (data, setError) => {
    createTenant({ body: data })
      .unwrap()
      .then(({ id }) => { callback(id) })
      .catch(handleFormError(setError))
  }

  return [onSubmit, isLoading]
}

export const GetStarted = () => {
  const smDown = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const dispatch = useDispatch()
  const navigate = useNavigateWithHistory()
  const { data: currentUser } = useGetCurrentUserQuery()
  const user = currentUser?.user
  const onCreateTenant = (id) => {
    // cross-api invalidation
    dispatch(currentUserApi.util.invalidateTags(['current_user']))
    // TODO: navigate right after invalidateTags action has been done instead of setTimeout (#422)
    setTimeout(() => navigate(`/${id}`), 100)
  }
  const [onSubmit, isLoading] = useCreateDittoTenant(onCreateTenant)
  return (
    <>
      <Helmet>
        <title>Get started | Niomon Admin Console</title>
      </Helmet>
      <Grid
        container
        display="flex"
        height="100%"
        minWidth="320px"
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          item
          xs={5}
          height="100%"
          backgroundColor="#1e212a"
          padding={smDown ? 4 : 8}
          display={smDown ? 'none' : 'flex'}
          flexDirection="column"
          justifyContent="center"
        >
          <NiomonLogo
            width="150px"
            style={{
              borderRadius: 4,
              marginBottom: 24
            }}
          />
          <Typography
            fontSize={24}
            fontFamily="sans-serif"
            color="#fff"
          >
            The first step of your success
          </Typography>
        </Grid>
        <Grid
          item
          xs={smDown ? 12 : 7}
          height="100%"
          display="flex"
          flexDirection="column"
        >
          <Box
            display="flex"
            backgroundColor={smDown ? '#1e212a' : 'transparent'}
            padding={2}
          >
            <Box display={smDown ? 'flex' : 'none'}>
              <DittoLogo height={36} />
            </Box>
            <Box marginLeft="auto" >
              <AccountPopover
                currentUser={user}
                breakpoint="0px"
                showUsername={false}
              />
            </Box>
          </Box>
          <Box
            display="flex"
            height="100%"
            alignItems="center"
            justifyContent="center"
            padding={smDown ? 4 : 8}
          >
            <OnboardingForm
              onSubmit={onSubmit}
              isLoading={isLoading}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  )
}
