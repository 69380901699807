import { GLOBAL_RESOURCE_NAMESPACE } from '@/constant'

import { CustomUsers as UsersIcon } from '@/icons/custom-users'
import { OfficeBuilding as OfficeBuildingIcon } from '@/icons/office-building'
import { Ditto as DittoIcon } from '@/icons/ditto'
import AppsIcon from '@mui/icons-material/Apps'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'
// import ListIcon from '@mui/icons-material/List'
import RouteIcon from '@mui/icons-material/Route'
import SettingsEthernetIcon from '@mui/icons-material/SettingsEthernet'
import SettingsIcon from '@mui/icons-material/Settings'
import {
  NIOMON_ADMIN,
  NIOMON_AUTH_ADMIN,
  NIOMON_AUTH_EDITOR,
  NIOMON_AUTH_VIEWER,
  NIOMON_DITTO_DEVELOPER,
  NIOMON_EDITOR,
  NIOMON_TENANT_OWNER,
  NIOMON_VIEWER
} from './role/variants'
import { ROOT_TENANT } from '@/config'

const userHasRoleIn = (roles) =>
  (userRoles) => userRoles?.some(userRole => roles.includes(userRole))

// Navigation items which keyed by the section name
export const getNavigationItemList = (tenantId) => [
  {
    icon: OfficeBuildingIcon,
    title: 'Tenants',
    href: `/${GLOBAL_RESOURCE_NAMESPACE}/tenants`,
    isShown: () => (tenantId === GLOBAL_RESOURCE_NAMESPACE)
  },
  {
    icon: UsersIcon,
    title: 'Users',
    href: `/${tenantId}/users`,
    isShown: userHasRoleIn([
      NIOMON_ADMIN,
      NIOMON_AUTH_ADMIN,
      NIOMON_AUTH_EDITOR,
      NIOMON_AUTH_VIEWER,
      NIOMON_EDITOR,
      NIOMON_VIEWER
    ])
  },
  {
    icon: AdminPanelSettingsIcon,
    title: 'Roles',
    href: `/${tenantId}/role-bindings`,
    isShown: () => (tenantId === ROOT_TENANT)
  },
  {
    icon: AppsIcon,
    title: 'Applications',
    href: `/${tenantId}/clients`,
    isShown: userHasRoleIn([
      NIOMON_ADMIN,
      NIOMON_AUTH_ADMIN,
      NIOMON_AUTH_EDITOR,
      NIOMON_EDITOR
    ])
  },
  {
    icon: DittoIcon,
    title: 'Ditto Apps',
    href: `/${tenantId}/ditto/clients`,
    isShown: userHasRoleIn([
      NIOMON_ADMIN,
      NIOMON_EDITOR,
      NIOMON_DITTO_DEVELOPER
    ])
  },
  {
    icon: SettingsEthernetIcon,
    title: 'Identity Providers',
    href: `/${tenantId}/idps`,
    isShown: userHasRoleIn([
      NIOMON_ADMIN,
      NIOMON_AUTH_ADMIN,
      NIOMON_AUTH_EDITOR,
      NIOMON_EDITOR
    ])
  },
  {
    icon: RouteIcon,
    title: 'Login Widget',
    href: `/${tenantId}/login-widget`,
    isShown: userHasRoleIn([
      NIOMON_ADMIN,
      NIOMON_AUTH_ADMIN
    ])
  },
  // TODO: enable section if ready
  // {
  //   icon: ListIcon,
  //   title: 'Logs',
  //   href: `/${tenantId}/logs`,
  //   isShown: userHasRoleIn([
  //     NIOMON_ADMIN,
  //     NIOMON_AUTH_ADMIN,
  //     NIOMON_AUTH_EDITOR,
  //     NIOMON_AUTH_VIEWER,
  //     NIOMON_DITTO_DEVELOPER,
  //     NIOMON_EDITOR,
  //     NIOMON_TENANT_OWNER,
  //     NIOMON_VIEWER
  //   ])
  // },
  {
    icon: SettingsIcon,
    title: 'Settings',
    items: [
      {
        href: `/${tenantId}/settings`,
        title: 'General',
        isShown: userHasRoleIn([NIOMON_ADMIN, NIOMON_TENANT_OWNER])
      },
      {
        href: `/${tenantId}/settings/email`,
        title: 'Email',
        isShown: userHasRoleIn([NIOMON_ADMIN, NIOMON_TENANT_OWNER])
      },
      // TODO: enable sub-section if ready
      // {
      //   href: `/${tenantId}/settings/sms`,
      //   title: 'SMS',
      //   isShown: userHasRoleIn([NIOMON_ADMIN, NIOMON_TENANT_OWNER])
      // },
      {
        href: `/${tenantId}/settings/admins`,
        title: 'Administrators',
        isShown: userHasRoleIn([NIOMON_ADMIN, NIOMON_TENANT_OWNER])
      },
      {
        href: `/${tenantId}/settings/api-key`,
        title: 'API Key',
        isShown: userHasRoleIn([NIOMON_ADMIN, NIOMON_TENANT_OWNER])
      }
    ]
  }
]
