import { Suspense, lazy } from 'react'
import { Navigate } from 'react-router-dom'
import { AuthGuard } from '@/components/auth-guard'
import { TenantGuard } from '@/components/tenant-guard'
import { NavigateToTenant } from '@/components/navigate-to-tenant'
import { GuestGuard } from './components/guest-guard'
import { LoadingScreen } from './components/loading-screen'
import { Tenant } from './containers/tenant'
import { User } from './containers/user'
import { Client } from './containers/client'
import { Account } from './containers/account'
import { DashboardLayout } from './containers/dashboard-layout'
import { Settings } from './containers/settings'
import { SecurityDevices } from './containers/security-devices'
import { GetStarted } from './containers/ditto/get-started'
import { Landing } from './containers/landing'
import { GLOBAL_RESOURCE_NAMESPACE } from './constant'

import { DittoClient } from './containers/ditto/client'
import { IdentityProvider } from './containers/identity-provider'

// eslint-disable-next-line react/display-name
const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
)
Loadable.displayName = 'Loadable'

// Disable for max-len requirement as the pages declaration are standard for the routes
/* eslint-disable max-len */
// Not found pages
const NotFound = Loadable(lazy(() => import('./containers/not-found').then((module) => ({ default: module.NotFound }))))

// Auth pages
const Login = Loadable(lazy(() => import('./containers/login').then((module) => ({ default: module.Login }))))
const AuthCallback = Loadable(lazy(() => import('./containers/auth-callback').then((module) => ({ default: module.AuthCallback }))))
const Logout = Loadable(lazy(() => import('./containers/logout').then((module) => ({ default: module.Logout }))))

// Management dashboard pages
const Tenants = Loadable(lazy(() => import('./containers/tenants').then((module) => ({ default: module.Tenants }))))
const TenantSummary = Loadable(lazy(() => import('./containers/tenant-summary').then((module) => ({ default: module.TenantSummary }))))

const Users = Loadable(lazy(() => import('./containers/users').then((module) => ({ default: module.Users }))))
const UserSummary = Loadable(lazy(() => import('./containers/user-summary').then((module) => ({ default: module.UserSummary }))))
const UserSessions = Loadable(lazy(() => import('./containers/user-sessions').then((module) => ({ default: module.UserSessions }))))

const Clients = Loadable(lazy(() => import('./containers/clients').then((module) => ({ default: module.Clients }))))
const ClientGeneral = Loadable(lazy(() => import('./containers/client-general').then((module) => ({ default: module.ClientGeneral }))))

const RoleBindings = Loadable(lazy(() => import('./containers/role-bindings').then((module) => ({ default: module.RoleBindings }))))

const AccountGeneral = Loadable(lazy(() => import('./containers/account-general').then((module) => ({ default: module.AccountGeneral }))))

const SettingsTenant = Loadable(lazy(() => import('./containers/settings-tenant').then((module) => ({ default: module.SettingsTenant }))))
const SettingsAdmins = Loadable(lazy(() => import('./containers/settings-admins').then((module) => ({ default: module.SettingsAdmins }))))
const SettingsApiKey = Loadable(lazy(() => import('./containers/settings-api-key').then((module) => ({ default: module.SettingsApiKey }))))
const SettingsEmail = Loadable(lazy(() => import('./containers/settings-email').then((module) => ({ default: module.SettingsEmail }))))
const SettingsCardLayout = Loadable(lazy(() => import('./containers/settings-card-layout').then((module) => ({ default: module.SettingsCardLayout }))))

const DittoClientTokens = Loadable(lazy(() => import('./containers/ditto/client-tokens').then((module) => ({ default: module.ClientTokens }))))
const DittoClientToken = Loadable(lazy(() => import('./containers/ditto/client-token').then((module) => ({ default: module.ClientToken }))))
const DittoClients = Loadable(lazy(() => import('./containers/ditto/clients').then((module) => ({ default: module.DittoClients }))))
const DittoClientGeneral = Loadable(lazy(() => import('./containers/ditto/client-general').then((module) => ({ default: module.DittoClientGeneral }))))

const IdentityProviders = Loadable(lazy(() => import('./containers/identity-providers').then((module) => ({ default: module.IdentityProviders }))))
const IdentityProviderSettings = Loadable(lazy(() => import('./containers/identity-provider-settings').then((module) => ({ default: module.IdentityProviderSettings }))))

const IdpUsers = Loadable(lazy(() => import('./containers/idp-users').then((module) => ({ default: module.IdpUsers }))))

const LoginWidget = Loadable(lazy(() => import('./containers/login-widget').then((module) => ({ default: module.LoginWidget }))))
/* eslint-enable max-len */

const routes = [
  {
    path: '/',
    element: (
      <AuthGuard>
        <NavigateToTenant />
      </AuthGuard>
    )
  },
  {
    path: GLOBAL_RESOURCE_NAMESPACE,
    children: [
      {
        path: '',
        element: (<Navigate to="tenants"/>)
      },
      {
        path: 'get-started',
        element: (
          <AuthGuard>
            <GetStarted />
          </AuthGuard>
        )
      },
      {
        path: 'tenants',
        element: (
          <AuthGuard>
            <TenantGuard>
              <DashboardLayout />
            </TenantGuard>
          </AuthGuard>
        ),
        children: [
          {
            path: '',
            element: <Tenants />
          },
          {
            path: ':id',
            element: <Tenant />,
            children: [
              {
                path: '',
                element: <TenantSummary />
              }
            ]
          }
        ]
      },
      {
        path: 'login',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        )
      },
      {
        path: 'callback',
        element: (
          <GuestGuard>
            <AuthCallback />
          </GuestGuard>
        )
      },
      {
        path: 'logout',
        element: (
          <AuthGuard>
            <Logout />
          </AuthGuard>
        )
      }
    ]
  },
  {
    path: ':tenantId',
    element: (
      <AuthGuard>
        <TenantGuard>
          <DashboardLayout />
        </TenantGuard>
      </AuthGuard>
    ),
    children: [
      {
        path: 'ditto',
        children: [
          {
            path: 'clients',
            children: [
              {
                path: '',
                element: <DittoClients />
              },
              {
                path: ':clientId',
                element: <DittoClient />,
                children: [
                  {
                    path: '',
                    element: <DittoClientGeneral />
                  },
                  {
                    path: 'tokens',
                    children: [
                      {
                        path: '',
                        element: <DittoClientTokens />
                      },
                      {
                        path: ':tokenId',
                        element: <DittoClientToken />
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        path: 'account',
        children: [
          {
            path: '',
            element: <Account />,
            children: [
              {
                path: '',
                element: <AccountGeneral />
              },
              {
                path: 'team',
                element: <SettingsAdmins />
              }
            ]
          }
        ]
      },
      {
        path: 'settings',
        element: <Settings />,
        children: [
          {
            path: '',
            element: <SettingsTenant />
          },
          {
            path: 'admins',
            element: <SettingsAdmins />
          },
          {
            path: 'api-key',
            element: <SettingsApiKey />
          },
          {
            path: 'email',
            element: <SettingsEmail />
          },
          {
            path: 'card-layouts',
            children: [
              {
                path: ':cardLayoutId',
                element: <SettingsCardLayout />
              }
            ]
          }
        ]
      },
      {
        path: 'users',
        children: [
          {
            path: '',
            element: <Users />
          },
          {
            path: ':userId',
            element: <User />,
            children: [
              {
                path: '',
                element: <UserSummary />
              },
              {
                path: 'idps',
                element: <IdpUsers />
              },
              {
                path: 'sessions',
                element: <UserSessions />
              },
              {
                path: 'credentials',
                element: <SecurityDevices />
              }
            ]
          }
        ]
      },
      {
        path: 'clients',
        children: [
          {
            path: '',
            element: <Clients />
          },
          {
            path: ':clientId',
            element: <Client />,
            children: [
              {
                path: '',
                element: <ClientGeneral />
              }
            ]
          }
        ]
      },
      {
        path: 'role-bindings',
        children: [
          {
            path: '',
            element: <RoleBindings />
          }
        ]
      },
      {
        path: 'login-widget',
        element: <LoginWidget />
      },
      {
        path: 'idps',
        children: [
          {
            path: '',
            element: <IdentityProviders />
          },
          {
            path: ':idpId',
            element: <IdentityProvider />,
            children: [
              {
                path: '',
                element: <IdentityProviderSettings />
              }
            ]
          }
        ]
      },
      {
        path: '',
        element: (<Landing />)
      }
    ]
  },
  {
    path: '*',
    exact: true,
    element: <NotFound />
  }
]

export default routes
