import PropTypes from 'prop-types'
import { Box, Divider } from '@mui/material'
import Masonry from '@mui/lab/Masonry'

import { Pagination } from '@/components/pagination'
import { CredentialCard } from '@/components/credential/credential-card'

export const CredentialCardMasonry = (props) => {
  const { credentials, page, pageSize, totalSize, onPageChange } = props

  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column'
      }}
    >
      <Masonry
        columns={3}
        spacing={4}
      >
        {credentials.map((credential) => (
          <CredentialCard
            key={credential.id}
            credential={credential}
          />
        ))}
      </Masonry>
      <Divider sx={{ mt: 'auto' }} />
      <Pagination
        onPageChange={onPageChange}
        page={page}
        pageSize={pageSize}
        totalSize={totalSize}
      />
    </Box>
  )
}

CredentialCardMasonry.propTypes = {
  credentials: PropTypes.array,
  onPageChange: PropTypes.func,
  page: PropTypes.number,
  pageSize: PropTypes.number,
  totalSize: PropTypes.number
}
