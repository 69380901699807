import { adminApi as generatedAdminApi } from '@/store/codegen/generated/adminApi'

export const clientApi = generatedAdminApi.enhanceEndpoints({
  addTagTypes: ['client'],
  endpoints: {
    listClients: {
      providesTags: (result, error) => {
        return (
          result
            ? [
                ...result.clients.map(({ id }) => ({ type: 'client', id })),
                // PARTIAL-LIST denotes that the list is partial, which is paginated
                { type: 'client', id: 'PARTIAL-LIST' }
              ]
            : [{ type: 'client', id: 'PARTIAL-LIST' }]
        )
      }
    },
    getClient: {
      providesTags: (result, error) => result ? [{ type: 'client', id: result.id }] : []
    },
    createClient: {
      invalidatesTags: ['client']
    },
    updateClient: {
      invalidatesTags: (result, error, { id }) => [{ type: 'client', id }]
    },
    deleteClient: {
      invalidatesTags: ['client']
    }
  }
})

export const {
  useCreateClientMutation,
  useListClientsQuery,
  useGetClientQuery,
  useUpdateClientMutation,
  useDeleteClientMutation
} = clientApi
