
import PropTypes from 'prop-types'
import { useGenerateUploadUrlMutation } from '@/store/api/upload'
import { useState } from 'react'
import { SingleFileUploader } from './single-file-uploader'
import { Box, Card } from '@mui/material'
import { handleFormError } from '@/utils/form-error'
import { ImagePreview } from './image-preview'
import { ErrorCode } from 'react-dropzone'
import byteSize from 'byte-size'

const useUploadImage = (onUploadImage, fieldName, setError, clearErrors) => {
  const [isLoading, setIsLoading] = useState(false)
  const [generateUploadUrl] = useGenerateUploadUrlMutation()

  // api response the field of error is "filename" which differ from the
  // corresponding field name in the form, therefore provide a customize
  // setError function to modify error field
  const customSetError = (_, error) => setError(fieldName, error)

  const onSubmit = async (file) => {
    setIsLoading(true)
    clearErrors(fieldName)

    try {
      const res = await generateUploadUrl({ filename: file.name })
      if (res.error) {
        handleFormError(customSetError)(res.error)
        throw new Error(res.error)
      }

      const uploadRes = await fetch(res.data.uploadUrl, { method: 'PUT', body: file })
      if (!uploadRes.ok) {
        handleFormError(customSetError)(uploadRes)
        const isJson = uploadRes.headers.get('content-type')?.includes('application/json')
        const data = isJson ? await uploadRes.json() : null

        const error = (data && data.message) || uploadRes.statusText
        throw new Error(error)
      }

      onUploadImage?.(res.data.fileUrl)
    } catch (e) {
      console.error('Failed to upload the image: ', e)
    }

    setIsLoading(false)
  }

  return [onSubmit, isLoading]
}

export const ImageUploader = (props) => {
  const { imageUrl, setValue, setError, clearErrors, name, maxSize, minSize, ...other } = props
  const onUploadImage = (url) => setValue(name, url)
  const onRemoveImage = () => setValue(name, '')

  const [uploadImage, isLoading] = useUploadImage(onUploadImage, name, setError, clearErrors)
  const handleError = (err) => {
    let errorMessage = ''

    switch (err.code) {
      case ErrorCode.FileTooLarge:
        errorMessage = `Image is too big. Max filesize: ${byteSize(maxSize)}`
        break

      case ErrorCode.FileTooSmall:
        errorMessage = `Image is too small. Min filesize: ${byteSize(minSize)}`
        break

      case ErrorCode.TooManyFiles:
        errorMessage = 'Too many files.'
        break

      case ErrorCode.FileInvalidType:
        errorMessage = 'Image type is invalid. Accept file type: .jpg, .jpeg, .png'
        break
    }

    setError(name, {
      type: 'manual',
      message: errorMessage
    })
  }

  const renderContent = () => {
    if (imageUrl) {
      return (
        <ImagePreview
          imageUrl={imageUrl}
          onRemoveImage={onRemoveImage}
          sx={{
            background: 'transparent',
            height: '100%',
            width: 'auto',
            maxHeight: '100%',
            maxWidth: '100%'
          }}
        />
      )
    }

    return (
      <SingleFileUploader
        upload={uploadImage}
        handleError={handleError}
        isLoading={isLoading}
        // max size: 1 MB
        maxSize={maxSize}
        minSize={minSize}
        // Disable Options select box feature of mac native file picker
        // ref:https://github.com/react-dropzone/react-dropzone/issues/1191
        useFsAccessApi={false}
        accept={{
          'image/png': [],
          'image/jpeg': []
        }}
        {...other}
      />
    )
  }

  return (
    <Card variant="outlined">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        width="100%"
        height={144}
      >
        {renderContent()}
      </Box>
    </Card>
  )
}

ImageUploader.propTypes = {
  name: PropTypes.string,
  maxSize: PropTypes.number,
  minSize: PropTypes.number,
  imageUrl: PropTypes.string,
  setValue: PropTypes.func,
  setError: PropTypes.func,
  clearErrors: PropTypes.func
}
