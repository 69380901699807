import { adminApi as generatedAdminApi } from '@/store/codegen/generated/adminApi'

export const cardLayoutApi = generatedAdminApi.enhanceEndpoints({
  addTagTypes: ['cardLayout'],
  endpoints: {
    listCardLayouts: {
      providesTags: (result, error) => {
        return (
          result
            ? [
                ...result.cardLayouts.map(({ id }) => ({ type: 'cardLayout', id })),
                // PARTIAL-LIST denotes that the list is partial, which is paginated
                { type: 'cardLayout', id: 'PARTIAL-LIST' }
              ]
            : [{ type: 'cardLayout', id: 'PARTIAL-LIST' }]
        )
      }
    },
    getCardLayout: {
      providesTags: (result, error) => result ? [{ type: 'cardLayout', id: result.id }] : []
    },
    createCardLayout: {
      invalidatesTags: ['cardLayout']
    },
    updateCardLayout: {
      invalidatesTags: (result, error, { id }) => [{ type: 'cardLayout', id }]
    },
    deleteCardLayout: {
      invalidatesTags: ['cardLayout']
    }
  }
})

export const {
  useCreateCardLayoutMutation,
  useListCardLayoutsQuery,
  useGetCardLayoutQuery,
  useUpdateCardLayoutMutation,
  useDeleteCardLayoutMutation
} = cardLayoutApi
