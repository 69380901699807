import { createApi } from '@reduxjs/toolkit/query/react'
import { internalApiBaseQuery } from '@/store/query'

export const dittoTenantApi = createApi({
  reducerPath: 'tenant',
  baseQuery: internalApiBaseQuery,
  // allow refetch all subscribed queries after regaining a network connection
  refetchOnReconnect: true,
  // cache expired after 10 seconds
  refetchOnMountOrArgChange: 10,
  endpoints: (builder) => ({
    createTenant: builder.mutation({
      query: (queryArg) => ({
        url: '/tenants',
        method: 'POST',
        body: queryArg.body
      }),
      extraOptions: {
        tenantId: '-'
      }
    })
  })
})

export const {
  useCreateTenantMutation: useCreateDittoTenantMutation
} = dittoTenantApi
