import { isPlainObject } from '@reduxjs/toolkit'
import type { SerializeQueryArgs } from '@reduxjs/toolkit/dist/query/defaultSerializeQueryArgs'

import { restoreTenantId } from '@/store/tenant-id'

// Customize the cache key to include tenant information
export const serializeWithTenantId: SerializeQueryArgs<any> = ({ endpointName, endpointDefinition, queryArgs }) => {
  // XXX: Extract tenant in address if necessaary
  const tenant = restoreTenantId() ?? location.pathname.split('/')[1]
  // Modify from defaultSerializeQueryArgs from official implementation
  // eslint-disable-next-line max-len
  // Reference: https://github.com/reduxjs/redux-toolkit/blob/master/packages/toolkit/src/query/defaultSerializeQueryArgs.ts
  return `${endpointName}:${tenant}:(${JSON.stringify(queryArgs, (key, value) =>
    isPlainObject(value)
      ? Object.keys(value)
          .sort()
          .reduce<any>((acc, key) => {
            acc[key] = (value as any)[key]
            return acc
          }, {})
      : value
  )})`
}
