import PropTypes from 'prop-types'
import DefaultAppIcon from '@/components/default-app-icon'
import { TitleWithAvatar } from '@/components/title-with-avatar'
import { Avatar } from '@mui/material'

export const ClientDisplayCard = (props) => {
  const { avatar, ...other } = props
  return (
    <TitleWithAvatar
      avatar={(
        <Avatar
          src={avatar}
          variant="rounded"
          sx={{
            height: 36,
            mr: 1,
            width: 36,
            background: 'transparent'
          }}
          imgProps={{
            sx: {
              width: 'auto',
              height: 'auto',
              maxHeight: '100%',
              maxWidth: '100%'
            }
          }}
        >
          <DefaultAppIcon />
        </Avatar>
      )}
      {...other}
    />
  )
}

ClientDisplayCard.propTypes = {
  avatar: PropTypes.string
}
