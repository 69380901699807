import { useState } from 'react'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import { Box, Container } from '@mui/material'
import { PageHeader } from '@/components/page-header'
import { UserMenu } from '@/components/user/user-menu'
import { QueryGuard } from '@/components/query-guard'

import { useGetUserQuery } from '@/store/api/user'
import { UserDisplayCard } from '@/components/user/user-display-card'

export const User = () => {
  const navigate = useNavigate()
  const { tenantId, userId } = useParams()
  const [pending, setPending] = useState(false)

  const { data: user, error, isLoading } = useGetUserQuery({ userId }, { skip: pending })

  const beforeDeleteUser = () => {
    setPending(true)
  }

  const onDeleteUser = (e) => {
    if (e) {
      setPending(false)
    } else {
      navigate('..')
    }
  }

  const tabs = [
    {
      href: `/${tenantId}/users/${userId}`,
      label: 'Details'
    },
    {
      href: `/${tenantId}/users/${userId}/idps`,
      label: 'Linked Identity'
    },
    {
      href: `/${tenantId}/users/${userId}/sessions`,
      label: 'Sessions'
    },
    {
      href: `/${tenantId}/users/${userId}/credentials`,
      label: 'Credentials'
    }
    // TODO: enable the tabs below if ready
    // {
    //   href: `/${tenantId}/users/${userId}/authorized-app`,
    //   label: 'Authorized Applications'
    // },
    // {
    //   href: `/${tenantId}/users/${userId}/logs`,
    //   label: 'Logs'
    // }
  ]

  const renderContent = () => {
    return (
      <QueryGuard
        isLoading={isLoading}
        statusCode={error?.status}
      >
        <PageHeader
          divider
          backTo="-1"
          backTitle="Users"
          title={(
            <UserDisplayCard
              user={user}
              variant="id"
              primaryProps={{
                variant: 'h4'
              }}
              marginLeft={1}
            />
          )}
          tabs={tabs}
          actions={
            <UserMenu
              user={user}
              onDeleteUser={onDeleteUser}
              beforeDeleteUser={beforeDeleteUser}
            />
          }
        />
        <QueryGuard isLoading={pending} >
          <Outlet />
        </QueryGuard>
      </QueryGuard>
    )
  }

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        flexGrow: 1
      }}
    >
      <Container
        maxWidth="lg"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%'
        }}
      >
        {renderContent()}
      </Container>
    </Box>
  )
}
