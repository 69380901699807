import { createApi } from '@reduxjs/toolkit/query/react'
import { internalApiBaseQuery } from '@/store/query'

export const uploadApi = createApi({
  reducerPath: 'upload',
  baseQuery: internalApiBaseQuery,
  endpoints: (builder) => ({
    generateUploadUrl: builder.mutation({
      query: (queryArg) => ({
        url: '/images/upload',
        method: 'POST',
        body: { filename: queryArg.filename }
      }),
      extraOptions: {
        tenantId: '-'
      }
    })
  })
})

export const {
  useGenerateUploadUrlMutation
} = uploadApi
