import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from '@mui/material'
import ErrorIcon from '@mui/icons-material/Error'
import WarningIcon from '@mui/icons-material/Warning'
import InfoIcon from '@mui/icons-material/Info'
import { InputField } from './input-field'

const icons = {
  error: (
    <ErrorIcon
      color="error"
      fontSize="large"
    />
  ),
  warning: (
    <WarningIcon
      color="warning"
      fontSize="large"
    />
  ),
  info: (
    <InfoIcon
      color="info"
      fontSize="large"
    />
  )
}

const INPUT_FIELD_KEY = 'field'

// TODO: Check error handling implementation
export const SingleInputDialog = (props) => {
  const {
    message,
    onCancel,
    onSubmit,
    open,
    label,
    submitProps,
    title,
    variant,
    error,
    ...other
  } = props
  const { register, watch, setError, handleSubmit, reset } = useForm()
  const watchField = watch(INPUT_FIELD_KEY)
  const { disabled } = submitProps

  let MessageComponent
  if (typeof (message) === 'string') {
    MessageComponent = (
      <Typography
        color="textPrimary"
        variant="body1"
        sx={{ mb: 3 }}
      >
        {message}
      </Typography>
    )
  } else {
    MessageComponent = message
  }

  useEffect(() => {
    if (error) {
      setError(INPUT_FIELD_KEY, error)
    }
  }, [error, setError])

  return (
    <Dialog
      onClose={onCancel}
      open={open}
      PaperProps={{
        sx: {
          width: '100%'
        }
      }}
      TransitionProps={{
        onExited: () => reset()
      }}
      {...other}
    >
      <DialogTitle
        sx={{
          alignItems: 'center',
          display: 'flex'
        }}
      >
        {icons[variant]}
        <Typography
          color="inherit"
          sx={{ ml: 2 }}
          variant="inherit"
        >
          {title}
        </Typography>
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          {MessageComponent}
          <InputField
            fullWidth
            label={label}
            {...register(INPUT_FIELD_KEY)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={onCancel}
            variant="text"
          >
            Cancel
          </Button>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            {...submitProps}
            disabled={typeof (disabled) === 'function' ? disabled?.(watchField) : disabled}
          >
            {submitProps.label ?? 'Confirm'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

SingleInputDialog.defaultProps = {
  open: false
}

SingleInputDialog.propTypes = {
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  open: PropTypes.bool,
  submitProps: PropTypes.object,
  label: PropTypes.string,
  title: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['error', 'warning', 'info']),
  // TODO: Set error type
  error: PropTypes.node
}
