import PropTypes from 'prop-types'
import LockIcon from '@mui/icons-material/Lock'
import { TitleWithAvatar } from '@/components/title-with-avatar'
import { Avatar, Badge, Stack, Typography } from '@mui/material'
import { UnverifiedChip } from '@/components/unverified-chip'
import { lightError } from '@/colors'

export const UserDisplayCard = (props) => {
  const { user, variant, primaryProps, secondaryProps, ...other } = props
  const displayName = user?.preferredUsername || user?.name || user?.email || user?.phoneNumber

  const renderSecondaryComponent = () => {
    switch (variant) {
      case 'id':
        return (
          <Typography
            noWrap
            variant='body2'
            color='text.secondary'
            {...secondaryProps}
          >
            {`ID: ${user?.id}`}
          </Typography>
        )
      case 'contact':
        return (
          <>
            <Typography
              noWrap
              variant='body2'
              color='text.secondary'
              {...secondaryProps}
            >
              {user.email || user.phoneNumber}
            </Typography>
            {
              user.email
                ? (!user.emailVerified && <UnverifiedChip />)
                : user.phoneNumber
                  ? (!user.phoneNumberVerified && <UnverifiedChip />)
                  : null
            }
          </>
        )
      default:
        return <></>
    }
  }

  return (
    <TitleWithAvatar
      avatar={
        <Badge
          badgeContent={
            <LockIcon
              style={{
                backgroundColor: lightError.main,
                color: 'white',
                padding: 4,
                borderRadius: 9999
              }}
            />
          }
          overlap="circular"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          sx={{
            mr: 1
          }}
          invisible={!user?.locked}
        >
          <Avatar
            src={user.picture}
            variant="rounded"
            sx={{
              borderRadius: 9999,
              backgroundColor: 'transparent'
            }}
            imgProps={{
              sx: {
                width: 'auto',
                height: 'auto',
                maxHeight: '100%',
                maxWidth: '100%'
              }
            }}
          />
        </Badge>
      }
      title={
        <Stack
          width={0}
          flexGrow={1}
        >
          <Typography
            noWrap
            variant='subtitle2'
            {...primaryProps}
          >
            {displayName || '-'}
          </Typography>
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
          >
            {renderSecondaryComponent()}
          </Stack>
        </Stack>
      }
      {...other}
    />
  )
}

UserDisplayCard.propTypes = {
  user: PropTypes.object,
  variant: PropTypes.string,
  primaryProps: PropTypes.object,
  secondaryProps: PropTypes.object
}
