import { adminApi as generatedAdminApi } from '@/store/codegen/generated/adminApi'

export const paymentApi = generatedAdminApi.enhanceEndpoints({
  addTagTypes: ['payment'],
  endpoints: {
    listPaymentRequests: {
      providesTags: (result, error) => {
        return (
          result
            ? [
                ...result.paymentRequests.map(({ id }) => ({ type: 'payment', id })),
                // PARTIAL-LIST denotes that the list is partial, which is paginated
                { type: 'payment', id: 'PARTIAL-LIST' }
              ]
            : [{ type: 'payment', id: 'PARTIAL-LIST' }]
        )
      }
    },

    getPaymentRequest: {
      providesTags: (result, error, { id }) => [{ type: 'payment', id }],
      transformResponse: (response) => {
        if (response.metadata) {
          response.metadata = JSON.stringify(response.metadata)
        }
        return response
      }
    },

    updatePaymentRequest: {
      invalidatesTags: (result, error, { id }) => [{ type: 'payment', id }]
    }
  }
})

export const {
  useListPaymentRequestsQuery,
  useGetPaymentRequestQuery,
  useUpdatePaymentRequestMutation
} = paymentApi
