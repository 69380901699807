import { emptyTemplateApi as api } from "@/store/codegen/emptyTemplateApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getSelfTenant: build.query<GetSelfTenantApiResponse, GetSelfTenantApiArg>({
      query: () => ({ url: `/v1/tenant` }),
    }),
    createTenant: build.mutation<CreateTenantApiResponse, CreateTenantApiArg>({
      query: (queryArg) => ({
        url: `/v1/tenants`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    listTenants: build.query<ListTenantsApiResponse, ListTenantsApiArg>({
      query: (queryArg) => ({
        url: `/v1/tenants`,
        params: { page: queryArg.page, pageSize: queryArg.pageSize },
      }),
    }),
    getTenant: build.query<GetTenantApiResponse, GetTenantApiArg>({
      query: (queryArg) => ({ url: `/v1/tenants/${queryArg.tenantId}` }),
    }),
    updateTenant: build.mutation<UpdateTenantApiResponse, UpdateTenantApiArg>({
      query: (queryArg) => ({
        url: `/v1/tenants/${queryArg.tenantId}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    deleteTenant: build.mutation<DeleteTenantApiResponse, DeleteTenantApiArg>({
      query: (queryArg) => ({
        url: `/v1/tenants/${queryArg.tenantId}`,
        method: "DELETE",
      }),
    }),
    createClient: build.mutation<CreateClientApiResponse, CreateClientApiArg>({
      query: (queryArg) => ({
        url: `/v1/clients`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    listClients: build.query<ListClientsApiResponse, ListClientsApiArg>({
      query: (queryArg) => ({
        url: `/v1/clients`,
        params: { page: queryArg.page, pageSize: queryArg.pageSize },
      }),
    }),
    getClient: build.query<GetClientApiResponse, GetClientApiArg>({
      query: (queryArg) => ({ url: `/v1/clients/${queryArg.clientId}` }),
    }),
    updateClient: build.mutation<UpdateClientApiResponse, UpdateClientApiArg>({
      query: (queryArg) => ({
        url: `/v1/clients/${queryArg.clientId}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    deleteClient: build.mutation<DeleteClientApiResponse, DeleteClientApiArg>({
      query: (queryArg) => ({
        url: `/v1/clients/${queryArg.clientId}`,
        method: "DELETE",
      }),
    }),
    createUser: build.mutation<CreateUserApiResponse, CreateUserApiArg>({
      query: (queryArg) => ({
        url: `/v1/users`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    listUsers: build.query<ListUsersApiResponse, ListUsersApiArg>({
      query: (queryArg) => ({
        url: `/v1/users`,
        params: { page: queryArg.page, pageSize: queryArg.pageSize },
      }),
    }),
    getUser: build.query<GetUserApiResponse, GetUserApiArg>({
      query: (queryArg) => ({ url: `/v1/users/${queryArg.userId}` }),
    }),
    updateUser: build.mutation<UpdateUserApiResponse, UpdateUserApiArg>({
      query: (queryArg) => ({
        url: `/v1/users/${queryArg.userId}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    deleteUser: build.mutation<DeleteUserApiResponse, DeleteUserApiArg>({
      query: (queryArg) => ({
        url: `/v1/users/${queryArg.userId}`,
        method: "DELETE",
      }),
    }),
    createEnrollment: build.mutation<
      CreateEnrollmentApiResponse,
      CreateEnrollmentApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/users/${queryArg.userId}/enrollments`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    listUserSessions: build.query<
      ListUserSessionsApiResponse,
      ListUserSessionsApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/users/${queryArg.userId}/sessions`,
        params: { page: queryArg.page, pageSize: queryArg.pageSize },
      }),
    }),
    listIdpUsers: build.query<ListIdpUsersApiResponse, ListIdpUsersApiArg>({
      query: (queryArg) => ({
        url: `/v1/users/${queryArg.userId}/idps`,
        params: { page: queryArg.page, pageSize: queryArg.pageSize },
      }),
    }),
    createIdpUser: build.mutation<
      CreateIdpUserApiResponse,
      CreateIdpUserApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/users/${queryArg.userId}/idps/${queryArg.idpId}`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getIdpUser: build.query<GetIdpUserApiResponse, GetIdpUserApiArg>({
      query: (queryArg) => ({
        url: `/v1/users/${queryArg.userId}/idps/${queryArg.idpId}`,
      }),
    }),
    updateIdpUser: build.mutation<
      UpdateIdpUserApiResponse,
      UpdateIdpUserApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/users/${queryArg.userId}/idps/${queryArg.idpId}`,
        method: "PUT",
      }),
    }),
    deleteIdpUser: build.mutation<
      DeleteIdpUserApiResponse,
      DeleteIdpUserApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/users/${queryArg.userId}/idps/${queryArg.idpId}`,
        method: "DELETE",
      }),
    }),
    getUserByEmail: build.query<
      GetUserByEmailApiResponse,
      GetUserByEmailApiArg
    >({
      query: (queryArg) => ({ url: `/v1/users-by-email/${queryArg.email}` }),
    }),
    getUserByPhoneNumber: build.query<
      GetUserByPhoneNumberApiResponse,
      GetUserByPhoneNumberApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/users-by-phone-number/${queryArg.phoneNumber}`,
      }),
    }),
    authenticateSession: build.mutation<
      AuthenticateSessionApiResponse,
      AuthenticateSessionApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/sessions/authenticate`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getSession: build.query<GetSessionApiResponse, GetSessionApiArg>({
      query: (queryArg) => ({ url: `/v1/sessions/${queryArg.sessionId}` }),
    }),
    deleteSession: build.mutation<
      DeleteSessionApiResponse,
      DeleteSessionApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/sessions/${queryArg.sessionId}`,
        method: "DELETE",
      }),
    }),
    listRoleBindings: build.query<
      ListRoleBindingsApiResponse,
      ListRoleBindingsApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/role-bindings`,
        params: { page: queryArg.page, pageSize: queryArg.pageSize },
      }),
    }),
    listUserCredentials: build.query<
      ListUserCredentialsApiResponse,
      ListUserCredentialsApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/users/${queryArg.userId}/credentials`,
        params: { page: queryArg.page, pageSize: queryArg.pageSize },
      }),
    }),
    getCredential: build.query<GetCredentialApiResponse, GetCredentialApiArg>({
      query: (queryArg) => ({
        url: `/v1/credentials/${queryArg.credentialId}`,
      }),
    }),
    deleteCredential: build.mutation<
      DeleteCredentialApiResponse,
      DeleteCredentialApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/credentials/${queryArg.credentialId}`,
        method: "DELETE",
      }),
    }),
    getSettings: build.query<GetSettingsApiResponse, GetSettingsApiArg>({
      query: () => ({ url: `/v1/settings` }),
    }),
    updateSettings: build.mutation<
      UpdateSettingsApiResponse,
      UpdateSettingsApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/settings`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    getMetadata: build.query<GetMetadataApiResponse, GetMetadataApiArg>({
      query: () => ({ url: `/v1/metadata` }),
    }),
    updateMetadata: build.mutation<
      UpdateMetadataApiResponse,
      UpdateMetadataApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/metadata`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    createIdp: build.mutation<CreateIdpApiResponse, CreateIdpApiArg>({
      query: (queryArg) => ({
        url: `/v1/idps`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    listIdps: build.query<ListIdpsApiResponse, ListIdpsApiArg>({
      query: (queryArg) => ({
        url: `/v1/idps`,
        params: { page: queryArg.page, pageSize: queryArg.pageSize },
      }),
    }),
    getIdp: build.query<GetIdpApiResponse, GetIdpApiArg>({
      query: (queryArg) => ({ url: `/v1/idps/${queryArg.idpId}` }),
    }),
    updateIdp: build.mutation<UpdateIdpApiResponse, UpdateIdpApiArg>({
      query: (queryArg) => ({
        url: `/v1/idps/${queryArg.idpId}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    deleteIdp: build.mutation<DeleteIdpApiResponse, DeleteIdpApiArg>({
      query: (queryArg) => ({
        url: `/v1/idps/${queryArg.idpId}`,
        method: "DELETE",
      }),
    }),
    startAuthenticateIdp: build.mutation<
      StartAuthenticateIdpApiResponse,
      StartAuthenticateIdpApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/idps/${queryArg.idpId}/start`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    authenticateIdp: build.mutation<
      AuthenticateIdpApiResponse,
      AuthenticateIdpApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/idps/${queryArg.idpId}/authenticate`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    sendEmailPasscode: build.mutation<
      SendEmailPasscodeApiResponse,
      SendEmailPasscodeApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/otps/email/send`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    sendSmsPasscode: build.mutation<
      SendSmsPasscodeApiResponse,
      SendSmsPasscodeApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/otps/sms/send`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    authenticatePasscode: build.mutation<
      AuthenticatePasscodeApiResponse,
      AuthenticatePasscodeApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/otps/authenticate`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    createCheckoutProduct: build.mutation<
      CreateCheckoutProductApiResponse,
      CreateCheckoutProductApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/checkout-products`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as adminApi };
export type GetSelfTenantApiResponse = /** status 200 Success */ {
  id: string;
} & {
  status?: "active" | "inactive";
} & {
  isApiKeySet: boolean;
  updatedAt: string;
  createdAt: string;
  deletedAt?: string;
};
export type GetSelfTenantApiArg = void;
export type CreateTenantApiResponse = /** status 200 Success */ {
  id: string;
} & {
  status?: "active" | "inactive";
} & {
  isApiKeySet: boolean;
  updatedAt: string;
  createdAt: string;
  deletedAt?: string;
};
export type CreateTenantApiArg = {
  body: {
    id: string;
    email: string;
  };
};
export type ListTenantsApiResponse = /** status 200 Success */ {
  page: number;
  pageSize: number;
  totalSize?: number;
} & {
  tenants: ({
    id: string;
  } & {
    status?: "active" | "inactive";
  } & {
    isApiKeySet: boolean;
    updatedAt: string;
    createdAt: string;
    deletedAt?: string;
  })[];
};
export type ListTenantsApiArg = {
  /** The current page number. Zero-indexed. */
  page?: number;
  /** Maximum number of results to be returned. Default 50, maximum 1000. */
  pageSize?: number;
};
export type GetTenantApiResponse = /** status 200 Success */ {
  id: string;
} & {
  status?: "active" | "inactive";
} & {
  isApiKeySet: boolean;
  updatedAt: string;
  createdAt: string;
  deletedAt?: string;
};
export type GetTenantApiArg = {
  /** ID for the Tenant. */
  tenantId: string;
};
export type UpdateTenantApiResponse = /** status 200 Success */ {
  id: string;
} & {
  status?: "active" | "inactive";
} & {
  isApiKeySet: boolean;
  updatedAt: string;
  createdAt: string;
  deletedAt?: string;
};
export type UpdateTenantApiArg = {
  /** ID for the Tenant. */
  tenantId: string;
  body: {
    status?: "active" | "inactive";
  };
};
export type DeleteTenantApiResponse = /** status 204 Success */ undefined;
export type DeleteTenantApiArg = {
  /** ID for the Tenant. */
  tenantId: string;
};
export type CreateClientApiResponse = /** status 200 Success */ {
  id: string;
} & {
  name?: string;
  description?: string;
  appType?: "single-page" | "web" | "native" | "machine";
  themeColor?: string;
  logoUrl?: string;
  callbacks?: string[];
  allowedOrigins?: string[];
  webOrigins?: string[];
  allowedLogoutUrls?: string[];
  initiateLoginUrl?: string;
  tokenExpiryTime?: number;
  requireConsent?: boolean;
} & {
  secret?: string;
  type?: "login" | "payment";
  updatedAt: string;
  createdAt: string;
};
export type CreateClientApiArg = {
  body: {
    name?: string;
    description?: string;
    appType?: "single-page" | "web" | "native" | "machine";
    themeColor?: string;
    logoUrl?: string;
    callbacks?: string[];
    allowedOrigins?: string[];
    webOrigins?: string[];
    allowedLogoutUrls?: string[];
    initiateLoginUrl?: string;
    tokenExpiryTime?: number;
    requireConsent?: boolean;
  } & {
    name: string;
    type: "login" | "payment";
    appType: "single-page" | "web" | "native" | "machine";
  };
};
export type ListClientsApiResponse = /** status 200 Success */ {
  page: number;
  pageSize: number;
  totalSize?: number;
} & {
  clients: ({
    id: string;
  } & {
    name?: string;
    description?: string;
    appType?: "single-page" | "web" | "native" | "machine";
    themeColor?: string;
    logoUrl?: string;
    callbacks?: string[];
    allowedOrigins?: string[];
    webOrigins?: string[];
    allowedLogoutUrls?: string[];
    initiateLoginUrl?: string;
    tokenExpiryTime?: number;
    requireConsent?: boolean;
  } & {
    secret?: string;
    type?: "login" | "payment";
    updatedAt: string;
    createdAt: string;
  })[];
};
export type ListClientsApiArg = {
  /** The current page number. Zero-indexed. */
  page?: number;
  /** Maximum number of results to be returned. Default 50, maximum 1000. */
  pageSize?: number;
};
export type GetClientApiResponse = /** status 200 Success */ {
  id: string;
} & {
  name?: string;
  description?: string;
  appType?: "single-page" | "web" | "native" | "machine";
  themeColor?: string;
  logoUrl?: string;
  callbacks?: string[];
  allowedOrigins?: string[];
  webOrigins?: string[];
  allowedLogoutUrls?: string[];
  initiateLoginUrl?: string;
  tokenExpiryTime?: number;
  requireConsent?: boolean;
} & {
  secret?: string;
  type?: "login" | "payment";
  updatedAt: string;
  createdAt: string;
};
export type GetClientApiArg = {
  /** ID of the Client. */
  clientId: string;
};
export type UpdateClientApiResponse = /** status 200 Success */ {
  id: string;
} & {
  name?: string;
  description?: string;
  appType?: "single-page" | "web" | "native" | "machine";
  themeColor?: string;
  logoUrl?: string;
  callbacks?: string[];
  allowedOrigins?: string[];
  webOrigins?: string[];
  allowedLogoutUrls?: string[];
  initiateLoginUrl?: string;
  tokenExpiryTime?: number;
  requireConsent?: boolean;
} & {
  secret?: string;
  type?: "login" | "payment";
  updatedAt: string;
  createdAt: string;
};
export type UpdateClientApiArg = {
  /** ID of the Client. */
  clientId: string;
  body: {
    name?: string;
    description?: string;
    appType?: "single-page" | "web" | "native" | "machine";
    themeColor?: string;
    logoUrl?: string;
    callbacks?: string[];
    allowedOrigins?: string[];
    webOrigins?: string[];
    allowedLogoutUrls?: string[];
    initiateLoginUrl?: string;
    tokenExpiryTime?: number;
    requireConsent?: boolean;
  };
};
export type DeleteClientApiResponse = /** status 204 Success */ undefined;
export type DeleteClientApiArg = {
  /** ID of the Client. */
  clientId: string;
};
export type CreateUserApiResponse = /** status 200 Success */ {
  id: string;
} & {
  name?: string;
  givenName?: string;
  familyName?: string;
  middleName?: string;
  nickname?: string;
  preferredUsername?: string;
  profile?: string;
  picture?: string;
  website?: string;
  email?: string;
  emailVerified?: boolean;
  gender?: string;
  birthdate?: string;
  zoneinfo?: string;
  locale?: string;
  phoneNumber?: string;
  phoneNumberVerified?: boolean;
  lockedAt?: string;
  locked?: boolean;
  address?: {
    formatted?: string;
    streetAddress?: string;
    locality?: string;
    region?: string;
    postalCode?: string;
    country?: string;
  };
  userMetadata?: object;
  appMetadata?: object;
} & {
  lastSeenAt?: string;
  updatedAt: string;
  createdAt: string;
};
export type CreateUserApiArg = {
  body: {
    name?: string;
    givenName?: string;
    familyName?: string;
    middleName?: string;
    nickname?: string;
    preferredUsername?: string;
    profile?: string;
    picture?: string;
    website?: string;
    email?: string;
    emailVerified?: boolean;
    gender?: string;
    birthdate?: string;
    zoneinfo?: string;
    locale?: string;
    phoneNumber?: string;
    phoneNumberVerified?: boolean;
    lockedAt?: string;
    locked?: boolean;
    address?: {
      formatted?: string;
      streetAddress?: string;
      locality?: string;
      region?: string;
      postalCode?: string;
      country?: string;
    };
    userMetadata?: object;
    appMetadata?: object;
  };
};
export type ListUsersApiResponse = /** status 200 Success */ {
  page: number;
  pageSize: number;
  totalSize?: number;
} & {
  users: ({
    id: string;
  } & {
    name?: string;
    givenName?: string;
    familyName?: string;
    middleName?: string;
    nickname?: string;
    preferredUsername?: string;
    profile?: string;
    picture?: string;
    website?: string;
    email?: string;
    emailVerified?: boolean;
    gender?: string;
    birthdate?: string;
    zoneinfo?: string;
    locale?: string;
    phoneNumber?: string;
    phoneNumberVerified?: boolean;
    lockedAt?: string;
    locked?: boolean;
    address?: {
      formatted?: string;
      streetAddress?: string;
      locality?: string;
      region?: string;
      postalCode?: string;
      country?: string;
    };
    userMetadata?: object;
    appMetadata?: object;
  } & {
    lastSeenAt?: string;
    updatedAt: string;
    createdAt: string;
  })[];
};
export type ListUsersApiArg = {
  /** The current page number. Zero-indexed. */
  page?: number;
  /** Maximum number of results to be returned. Default 50, maximum 1000. */
  pageSize?: number;
};
export type GetUserApiResponse = /** status 200 Success */ {
  id: string;
} & {
  name?: string;
  givenName?: string;
  familyName?: string;
  middleName?: string;
  nickname?: string;
  preferredUsername?: string;
  profile?: string;
  picture?: string;
  website?: string;
  email?: string;
  emailVerified?: boolean;
  gender?: string;
  birthdate?: string;
  zoneinfo?: string;
  locale?: string;
  phoneNumber?: string;
  phoneNumberVerified?: boolean;
  lockedAt?: string;
  locked?: boolean;
  address?: {
    formatted?: string;
    streetAddress?: string;
    locality?: string;
    region?: string;
    postalCode?: string;
    country?: string;
  };
  userMetadata?: object;
  appMetadata?: object;
} & {
  lastSeenAt?: string;
  updatedAt: string;
  createdAt: string;
};
export type GetUserApiArg = {
  /** ID of the User. */
  userId: string;
};
export type UpdateUserApiResponse = /** status 200 Success */ {
  id: string;
} & {
  name?: string;
  givenName?: string;
  familyName?: string;
  middleName?: string;
  nickname?: string;
  preferredUsername?: string;
  profile?: string;
  picture?: string;
  website?: string;
  email?: string;
  emailVerified?: boolean;
  gender?: string;
  birthdate?: string;
  zoneinfo?: string;
  locale?: string;
  phoneNumber?: string;
  phoneNumberVerified?: boolean;
  lockedAt?: string;
  locked?: boolean;
  address?: {
    formatted?: string;
    streetAddress?: string;
    locality?: string;
    region?: string;
    postalCode?: string;
    country?: string;
  };
  userMetadata?: object;
  appMetadata?: object;
} & {
  lastSeenAt?: string;
  updatedAt: string;
  createdAt: string;
};
export type UpdateUserApiArg = {
  /** ID of the User. */
  userId: string;
  body: {
    name?: string;
    givenName?: string;
    familyName?: string;
    middleName?: string;
    nickname?: string;
    preferredUsername?: string;
    profile?: string;
    picture?: string;
    website?: string;
    email?: string;
    emailVerified?: boolean;
    gender?: string;
    birthdate?: string;
    zoneinfo?: string;
    locale?: string;
    phoneNumber?: string;
    phoneNumberVerified?: boolean;
    lockedAt?: string;
    locked?: boolean;
    address?: {
      formatted?: string;
      streetAddress?: string;
      locality?: string;
      region?: string;
      postalCode?: string;
      country?: string;
    };
    userMetadata?: object;
    appMetadata?: object;
  };
};
export type DeleteUserApiResponse = /** status 204 Success */ undefined;
export type DeleteUserApiArg = {
  /** ID of the User. */
  userId: string;
};
export type CreateEnrollmentApiResponse = /** status 200 Success */ {
  token: string;
  uri: string;
  deepLink?: string;
};
export type CreateEnrollmentApiArg = {
  /** ID of the User to enroll a credential. */
  userId: string;
  body: {
    type?: string;
    send?: boolean;
  };
};
export type ListUserSessionsApiResponse = /** status 200 Success */ {
  page: number;
  pageSize: number;
  totalSize?: number;
} & {
  sessions: {
    id: string;
    userId: string;
    clientId?: string;
    lastSeenAt?: string;
    lastSeenIp?: string;
    lastSeenLocation?: string;
    userAgent?: string;
    expiresAt?: string;
    updatedAt: string;
    createdAt: string;
  }[];
};
export type ListUserSessionsApiArg = {
  /** The user ID to get active sessions for. */
  userId: string;
  /** The current page number. Zero-indexed. */
  page?: number;
  /** Maximum number of results to be returned. Default 50, maximum 1000. */
  pageSize?: number;
};
export type ListIdpUsersApiResponse = /** status 200 Success */ {
  page: number;
  pageSize: number;
  totalSize?: number;
} & {
  idpUsers: {
    idpId: string;
    userId: string;
    externalId: string;
    metadata?: object;
    lastLoginAt: string;
    updatedAt: string;
    createdAt: string;
  }[];
};
export type ListIdpUsersApiArg = {
  /** The user ID to get IdP User for. */
  userId: string;
  /** The current page number. Zero-indexed. */
  page?: number;
  /** Maximum number of results to be returned. Default 50, maximum 1000. */
  pageSize?: number;
};
export type CreateIdpUserApiResponse = /** status 200 Success */ {
  idpId: string;
  userId: string;
  externalId: string;
  metadata?: object;
  lastLoginAt: string;
  updatedAt: string;
  createdAt: string;
};
export type CreateIdpUserApiArg = {
  /** The user ID to get IdP User for. */
  userId: string;
  /** The IdP ID to get IdP User for. */
  idpId: string;
  body: {
    idpId: string;
    userId: string;
    externalId: string;
    metadata?: object;
    lastLoginAt: string;
    updatedAt: string;
    createdAt: string;
  };
};
export type GetIdpUserApiResponse = /** status 200 Success */ {
  idpId: string;
  userId: string;
  externalId: string;
  metadata?: object;
  lastLoginAt: string;
  updatedAt: string;
  createdAt: string;
};
export type GetIdpUserApiArg = {
  /** The user ID to get IdP User for. */
  userId: string;
  /** The IdP ID to get IdP User for. */
  idpId: string;
};
export type UpdateIdpUserApiResponse = /** status 200 Success */ {
  idpId: string;
  userId: string;
  externalId: string;
  metadata?: object;
  lastLoginAt: string;
  updatedAt: string;
  createdAt: string;
};
export type UpdateIdpUserApiArg = {
  /** The user ID to get IdP User for. */
  userId: string;
  /** The IdP ID to get IdP User for. */
  idpId: string;
};
export type DeleteIdpUserApiResponse = /** status 204 Success */ undefined;
export type DeleteIdpUserApiArg = {
  /** The user ID to get IdP User for. */
  userId: string;
  /** The IdP ID to get IdP User for. */
  idpId: string;
};
export type GetUserByEmailApiResponse = /** status 200 Success */ {
  id: string;
} & {
  name?: string;
  givenName?: string;
  familyName?: string;
  middleName?: string;
  nickname?: string;
  preferredUsername?: string;
  profile?: string;
  picture?: string;
  website?: string;
  email?: string;
  emailVerified?: boolean;
  gender?: string;
  birthdate?: string;
  zoneinfo?: string;
  locale?: string;
  phoneNumber?: string;
  phoneNumberVerified?: boolean;
  lockedAt?: string;
  locked?: boolean;
  address?: {
    formatted?: string;
    streetAddress?: string;
    locality?: string;
    region?: string;
    postalCode?: string;
    country?: string;
  };
  userMetadata?: object;
  appMetadata?: object;
} & {
  lastSeenAt?: string;
  updatedAt: string;
  createdAt: string;
};
export type GetUserByEmailApiArg = {
  /** Email of the User. */
  email: string;
};
export type GetUserByPhoneNumberApiResponse = /** status 200 Success */ {
  id: string;
} & {
  name?: string;
  givenName?: string;
  familyName?: string;
  middleName?: string;
  nickname?: string;
  preferredUsername?: string;
  profile?: string;
  picture?: string;
  website?: string;
  email?: string;
  emailVerified?: boolean;
  gender?: string;
  birthdate?: string;
  zoneinfo?: string;
  locale?: string;
  phoneNumber?: string;
  phoneNumberVerified?: boolean;
  lockedAt?: string;
  locked?: boolean;
  address?: {
    formatted?: string;
    streetAddress?: string;
    locality?: string;
    region?: string;
    postalCode?: string;
    country?: string;
  };
  userMetadata?: object;
  appMetadata?: object;
} & {
  lastSeenAt?: string;
  updatedAt: string;
  createdAt: string;
};
export type GetUserByPhoneNumberApiArg = {
  /** Phone number of the User. */
  phoneNumber: string;
};
export type AuthenticateSessionApiResponse = /** status 200 Success */ {
  session?: {
    id: string;
    userId: string;
    clientId?: string;
    lastSeenAt?: string;
    lastSeenIp?: string;
    lastSeenLocation?: string;
    userAgent?: string;
    expiresAt?: string;
    updatedAt: string;
    createdAt: string;
  };
  sessionToken?: string;
  user: {
    id: string;
  } & {
    name?: string;
    givenName?: string;
    familyName?: string;
    middleName?: string;
    nickname?: string;
    preferredUsername?: string;
    profile?: string;
    picture?: string;
    website?: string;
    email?: string;
    emailVerified?: boolean;
    gender?: string;
    birthdate?: string;
    zoneinfo?: string;
    locale?: string;
    phoneNumber?: string;
    phoneNumberVerified?: boolean;
    lockedAt?: string;
    locked?: boolean;
    address?: {
      formatted?: string;
      streetAddress?: string;
      locality?: string;
      region?: string;
      postalCode?: string;
      country?: string;
    };
    userMetadata?: object;
    appMetadata?: object;
  } & {
    lastSeenAt?: string;
    updatedAt: string;
    createdAt: string;
  };
};
export type AuthenticateSessionApiArg = {
  body: {
    sessionToken: string;
    clientId?: string;
    sessionExpirationMinutes?: number;
    rotateSessionToken?: boolean;
    metadata?: {
      deviceToken?: string;
      userAgent?: string;
      ipAddress?: string;
    };
  };
};
export type GetSessionApiResponse = /** status 200 Success */ {
  id: string;
  userId: string;
  clientId?: string;
  lastSeenAt?: string;
  lastSeenIp?: string;
  lastSeenLocation?: string;
  userAgent?: string;
  expiresAt?: string;
  updatedAt: string;
  createdAt: string;
};
export type GetSessionApiArg = {
  /** ID of the Session. */
  sessionId: string;
};
export type DeleteSessionApiResponse = /** status 204 Success */ undefined;
export type DeleteSessionApiArg = {
  /** ID of the Session. */
  sessionId: string;
};
export type ListRoleBindingsApiResponse = /** status 200 Success */ {
  page: number;
  pageSize: number;
  totalSize?: number;
} & {
  roleBindings: {
    userId: string;
    role: string;
    domain: string;
  }[];
};
export type ListRoleBindingsApiArg = {
  /** The current page number. Zero-indexed. */
  page?: number;
  /** Maximum number of results to be returned. Default 50, maximum 1000. */
  pageSize?: number;
};
export type ListUserCredentialsApiResponse = /** status 200 Success */ {
  page: number;
  pageSize: number;
  totalSize?: number;
} & {
  credentials: {
    id: string;
    type?: string;
    publicKey: string;
    lastUsedAt?: string;
    createdAt?: string;
    device?: {
      hash?: string;
      swVersion?: string;
      mobile?: boolean;
      platform?: string;
      architecture?: string;
      bitness?: string;
      model?: string;
      platformVersion?: string;
      uaBrand?: string;
      uaVersion?: string;
      lastSeenIp?: string;
      lastSeenAt?: string;
    };
  }[];
};
export type ListUserCredentialsApiArg = {
  /** ID for the User. */
  userId: string;
  /** The current page number. Zero-indexed. */
  page?: number;
  /** Maximum number of results to be returned. Default 50, maximum 1000. */
  pageSize?: number;
};
export type GetCredentialApiResponse = /** status 200 Success */ {
  id: string;
  type?: string;
  publicKey: string;
  lastUsedAt?: string;
  createdAt?: string;
  device?: {
    hash?: string;
    swVersion?: string;
    mobile?: boolean;
    platform?: string;
    architecture?: string;
    bitness?: string;
    model?: string;
    platformVersion?: string;
    uaBrand?: string;
    uaVersion?: string;
    lastSeenIp?: string;
    lastSeenAt?: string;
  };
};
export type GetCredentialApiArg = {
  /** ID of the Credential. */
  credentialId: string;
};
export type DeleteCredentialApiResponse = /** status 204 Success */ undefined;
export type DeleteCredentialApiArg = {
  /** ID of the Credential. */
  credentialId: string;
};
export type GetSettingsApiResponse = /** status 200 Success */ {
  friendlyName?: string;
  logoUrl?: string;
  defaultAudience?: string;
  loginUrl?: string;
  supportUrl?: string;
};
export type GetSettingsApiArg = void;
export type UpdateSettingsApiResponse = /** status 200 Success */ {
  friendlyName?: string;
  logoUrl?: string;
  defaultAudience?: string;
  loginUrl?: string;
  supportUrl?: string;
};
export type UpdateSettingsApiArg = {
  body: {
    friendlyName?: string;
    logoUrl?: string;
    defaultAudience?: string;
    loginUrl?: string;
    supportUrl?: string;
  };
};
export type GetMetadataApiResponse = /** status 200 Success */ object;
export type GetMetadataApiArg = void;
export type UpdateMetadataApiResponse = /** status 200 Success */ object;
export type UpdateMetadataApiArg = {
  body: object;
};
export type CreateIdpApiResponse = /** status 200 Success */ {
  id?: string;
  type:
    | "apple"
    | "facebook"
    | "google"
    | "microsoft"
    | "oauth2"
    | "oidc"
    | "twitter";
  name: string;
  clientId?: string;
  clientSecret?: string;
  scopes?: string;
  issuer?: string;
  authorizationEndpoint?: string;
  tokenEndpoint?: string;
  userInfoEndpoint?: string;
  jwksUri?: string;
  idTokenSigningAlg?: string;
  idTokenSigningKey?: string;
  appleTeamId?: string;
  appleKid?: string;
  updatedAt?: string;
  createdAt?: string;
};
export type CreateIdpApiArg = {
  body: {
    id: string;
    type:
      | "apple"
      | "facebook"
      | "google"
      | "microsoft"
      | "oauth2"
      | "oidc"
      | "twitter";
    name: string;
    clientId?: string;
    clientSecret?: string;
    scopes?: string;
    issuer?: string;
    authorizationEndpoint?: string;
    tokenEndpoint?: string;
    userInfoEndpoint?: string;
    jwksUri?: string;
    idTokenSigningAlg?: string;
    idTokenSigningKey?: string;
    appleTeamId?: string;
    appleKid?: string;
  };
};
export type ListIdpsApiResponse = /** status 200 Success */ {
  page: number;
  pageSize: number;
  totalSize?: number;
} & {
  idps: {
    id?: string;
    type:
      | "apple"
      | "facebook"
      | "google"
      | "microsoft"
      | "oauth2"
      | "oidc"
      | "twitter";
    name: string;
    clientId?: string;
    clientSecret?: string;
    scopes?: string;
    issuer?: string;
    authorizationEndpoint?: string;
    tokenEndpoint?: string;
    userInfoEndpoint?: string;
    jwksUri?: string;
    idTokenSigningAlg?: string;
    idTokenSigningKey?: string;
    appleTeamId?: string;
    appleKid?: string;
    updatedAt?: string;
    createdAt?: string;
  }[];
};
export type ListIdpsApiArg = {
  /** The current page number. Zero-indexed. */
  page?: number;
  /** Maximum number of results to be returned. Default 50, maximum 1000. */
  pageSize?: number;
};
export type GetIdpApiResponse = /** status 200 Success */ {
  id?: string;
  type:
    | "apple"
    | "facebook"
    | "google"
    | "microsoft"
    | "oauth2"
    | "oidc"
    | "twitter";
  name: string;
  clientId?: string;
  clientSecret?: string;
  scopes?: string;
  issuer?: string;
  authorizationEndpoint?: string;
  tokenEndpoint?: string;
  userInfoEndpoint?: string;
  jwksUri?: string;
  idTokenSigningAlg?: string;
  idTokenSigningKey?: string;
  appleTeamId?: string;
  appleKid?: string;
  updatedAt?: string;
  createdAt?: string;
};
export type GetIdpApiArg = {
  /** ID of the Identity Provider. */
  idpId: string;
};
export type UpdateIdpApiResponse = /** status 200 Success */ {
  id?: string;
  type:
    | "apple"
    | "facebook"
    | "google"
    | "microsoft"
    | "oauth2"
    | "oidc"
    | "twitter";
  name: string;
  clientId?: string;
  clientSecret?: string;
  scopes?: string;
  issuer?: string;
  authorizationEndpoint?: string;
  tokenEndpoint?: string;
  userInfoEndpoint?: string;
  jwksUri?: string;
  idTokenSigningAlg?: string;
  idTokenSigningKey?: string;
  appleTeamId?: string;
  appleKid?: string;
  updatedAt?: string;
  createdAt?: string;
};
export type UpdateIdpApiArg = {
  /** ID of the Identity Provider. */
  idpId: string;
  body: {
    id?: string;
    type:
      | "apple"
      | "facebook"
      | "google"
      | "microsoft"
      | "oauth2"
      | "oidc"
      | "twitter";
    name: string;
    clientId?: string;
    clientSecret?: string;
    scopes?: string;
    issuer?: string;
    authorizationEndpoint?: string;
    tokenEndpoint?: string;
    userInfoEndpoint?: string;
    jwksUri?: string;
    idTokenSigningAlg?: string;
    idTokenSigningKey?: string;
    appleTeamId?: string;
    appleKid?: string;
    updatedAt?: string;
    createdAt?: string;
  };
};
export type DeleteIdpApiResponse = /** status 204 Success */ undefined;
export type DeleteIdpApiArg = {
  /** ID of the Identity Provider. */
  idpId: string;
};
export type StartAuthenticateIdpApiResponse = /** status 200 Success */ {
  authorizationUrl: string;
  requestToken?: string;
};
export type StartAuthenticateIdpApiArg = {
  /** ID of the Identity Provider. */
  idpId: string;
  body: {
    redirectUrl: string;
    state: string;
    metadata?: {
      deviceToken?: string;
      userAgent?: string;
      ipAddress?: string;
    };
  };
};
export type AuthenticateIdpApiResponse = /** status 200 Success */ {
  session?: {
    id: string;
    userId: string;
    clientId?: string;
    lastSeenAt?: string;
    lastSeenIp?: string;
    lastSeenLocation?: string;
    userAgent?: string;
    expiresAt?: string;
    updatedAt: string;
    createdAt: string;
  };
  sessionToken?: string;
  user: {
    id: string;
  } & {
    name?: string;
    givenName?: string;
    familyName?: string;
    middleName?: string;
    nickname?: string;
    preferredUsername?: string;
    profile?: string;
    picture?: string;
    website?: string;
    email?: string;
    emailVerified?: boolean;
    gender?: string;
    birthdate?: string;
    zoneinfo?: string;
    locale?: string;
    phoneNumber?: string;
    phoneNumberVerified?: boolean;
    lockedAt?: string;
    locked?: boolean;
    address?: {
      formatted?: string;
      streetAddress?: string;
      locality?: string;
      region?: string;
      postalCode?: string;
      country?: string;
    };
    userMetadata?: object;
    appMetadata?: object;
  } & {
    lastSeenAt?: string;
    updatedAt: string;
    createdAt: string;
  };
} & {
  idp: {
    externalId: string;
    tokens: object;
    userInfo: object;
  };
} & {
  userCreated: boolean;
};
export type AuthenticateIdpApiArg = {
  /** ID of the Identity Provider. */
  idpId: string;
  body: {
    code: string;
    redirectUrl: string;
    requestToken?: string;
    sessionExpirationMinutes?: number;
    createUser?: boolean;
    metadata?: {
      deviceToken?: string;
      userAgent?: string;
      ipAddress?: string;
    };
  };
};
export type SendEmailPasscodeApiResponse = /** status 200 Success */ {
  to: string;
  userId: string;
  userCreated: boolean;
};
export type SendEmailPasscodeApiArg = {
  body: {
    to: string;
    createUser?: boolean;
    metadata?: {
      deviceToken?: string;
      userAgent?: string;
      ipAddress?: string;
    };
  };
};
export type SendSmsPasscodeApiResponse = /** status 200 Success */ {
  to: string;
  userId: string;
  userCreated: boolean;
};
export type SendSmsPasscodeApiArg = {
  body: {
    to: string;
    createUser?: boolean;
    metadata?: {
      deviceToken?: string;
      userAgent?: string;
      ipAddress?: string;
    };
  };
};
export type AuthenticatePasscodeApiResponse = /** status 200 Success */ {
  session?: {
    id: string;
    userId: string;
    clientId?: string;
    lastSeenAt?: string;
    lastSeenIp?: string;
    lastSeenLocation?: string;
    userAgent?: string;
    expiresAt?: string;
    updatedAt: string;
    createdAt: string;
  };
  sessionToken?: string;
  user: {
    id: string;
  } & {
    name?: string;
    givenName?: string;
    familyName?: string;
    middleName?: string;
    nickname?: string;
    preferredUsername?: string;
    profile?: string;
    picture?: string;
    website?: string;
    email?: string;
    emailVerified?: boolean;
    gender?: string;
    birthdate?: string;
    zoneinfo?: string;
    locale?: string;
    phoneNumber?: string;
    phoneNumberVerified?: boolean;
    lockedAt?: string;
    locked?: boolean;
    address?: {
      formatted?: string;
      streetAddress?: string;
      locality?: string;
      region?: string;
      postalCode?: string;
      country?: string;
    };
    userMetadata?: object;
    appMetadata?: object;
  } & {
    lastSeenAt?: string;
    updatedAt: string;
    createdAt: string;
  };
};
export type AuthenticatePasscodeApiArg = {
  body: {
    to: string;
    code: string;
    sessionExpirationMinutes?: number;
    metadata?: {
      deviceToken?: string;
      userAgent?: string;
      ipAddress?: string;
    };
  };
};
export type CreateCheckoutProductApiResponse = /** status 201 Success */ {
  id: string;
  name: string;
  network: string;
  contractAddress: string;
  iconUrl: string;
  maxSupply?: number;
  minted: number;
  fiatCurrency?: string;
  fiatPrice?: number;
  cryptoCurrency?: string;
  cryptoPrice?: number;
  updatedAt?: string;
  createdAt?: string;
};
export type CreateCheckoutProductApiArg = {
  body: {
    name: string;
    network: string;
    contractAddress: string;
    contractType?: string;
    iconUrl: string;
    maxSupply?: number;
    fiatCurrency?: string;
    fiatPrice?: number;
    cryptoCurrency?: string;
    cryptoPrice?: number;
  };
};
export const {
  useGetSelfTenantQuery,
  useCreateTenantMutation,
  useListTenantsQuery,
  useGetTenantQuery,
  useUpdateTenantMutation,
  useDeleteTenantMutation,
  useCreateClientMutation,
  useListClientsQuery,
  useGetClientQuery,
  useUpdateClientMutation,
  useDeleteClientMutation,
  useCreateUserMutation,
  useListUsersQuery,
  useGetUserQuery,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useCreateEnrollmentMutation,
  useListUserSessionsQuery,
  useListIdpUsersQuery,
  useCreateIdpUserMutation,
  useGetIdpUserQuery,
  useUpdateIdpUserMutation,
  useDeleteIdpUserMutation,
  useGetUserByEmailQuery,
  useGetUserByPhoneNumberQuery,
  useAuthenticateSessionMutation,
  useGetSessionQuery,
  useDeleteSessionMutation,
  useListRoleBindingsQuery,
  useListUserCredentialsQuery,
  useGetCredentialQuery,
  useDeleteCredentialMutation,
  useGetSettingsQuery,
  useUpdateSettingsMutation,
  useGetMetadataQuery,
  useUpdateMetadataMutation,
  useCreateIdpMutation,
  useListIdpsQuery,
  useGetIdpQuery,
  useUpdateIdpMutation,
  useDeleteIdpMutation,
  useStartAuthenticateIdpMutation,
  useAuthenticateIdpMutation,
  useSendEmailPasscodeMutation,
  useSendSmsPasscodeMutation,
  useAuthenticatePasscodeMutation,
  useCreateCheckoutProductMutation,
} = injectedRtkApi;
