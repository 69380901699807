import { Box, Typography } from '@mui/material'
import { ExclamationOutlined as ExclamationOutlinedIcon } from '@/icons/exclamation-outlined'
import LoadingButton from '@mui/lab/LoadingButton'
import RefreshIcon from '@mui/icons-material/Refresh'
import { useState } from 'react'

export const UnknownError = () => {
  const [loading, setLoading] = useState(false)

  const onClick = () => {
    setLoading(true)
    location.reload()
  }

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <ExclamationOutlinedIcon />
        <Typography
          color="textSecondary"
          sx={{ my: 2 }}
          variant="body2"
          >
            Something went wrong, tap to retry
        </Typography>
        <LoadingButton
          onClick={onClick}
          loading={loading}
          loadingPosition="start"
          startIcon={<RefreshIcon />}
          variant="outlined"
        >
          Retry
        </LoadingButton>
      </Box>
    </Box>
  )
}
