import { subDays, subHours, subMinutes } from 'date-fns'

const now = new Date()

export default [
  {
    id: '000001',
    name: 'RootNiomon',
    admin_user_id: [1],
    logo: 'https://encl.io/assets/icons/android-chrome-512x512.png',
    domain_name: 'Niomon.com',
    subscription: 'Enterprise',
    email: 'root@niomon.dev',
    num_of_user: 731,
    created_at: subDays(subHours(subMinutes(now, 25), 9), 234),
    status: 'active'
  },
  {
    id: '000002',
    name: 'LikeCo',
    admin_user_id: [],
    subscription: 'Enterprise',
    logo: 'https://www.worldlogomart.com/wp-content/uploads/2019/09/BG-Logo.jpg',
    email: 'service@like.co',
    num_of_user: 102,
    created_at: subDays(subHours(now, 10), 4),
    status: 'active'
  },
  {
    id: '000041',
    name: 'Maxeus',
    admin_user_id: [],
    subscription: 'Free',
    logo: 'https://www.worldlogomart.com/wp-content/uploads/2019/09/Mex.png',
    num_of_user: 1,
    created_at: subDays(now, 1),
    status: 'inactive'
  },
  {
    id: '000102',
    name: 'ROC',
    admin_user_id: [],
    subscription: 'Free',
    num_of_user: 1,
    created_at: subMinutes(now, 55),
    status: 'inactive'
  },
  {
    id: 'sys',
    name: 'System',
    subscription: 'Enterprise',
    email: 'root@niomon.dev',
    num_of_user: 731,
    created_at: subDays(subHours(subMinutes(now, 25), 9), 234),
    status: 'active'
  }
]
