import { createApi } from '@reduxjs/toolkit/query/react'
import { internalApiBaseQuery } from '@/store/query'

export const roleApi = createApi({
  reducerPath: 'role',
  baseQuery: internalApiBaseQuery,
  tagTypes: ['role'],
  endpoints: (builder) => ({
    listRoles: builder.query({
      query: (queryArg) => ({
        url: '/roles',
        params: { page: queryArg.page, pageSize: queryArg.pageSize }
      }),
      providesTags: ['role']
    }),
    assignRole: builder.mutation({
      query: (queryArg) => ({
        url: '/roles/assign',
        method: 'POST',
        body: queryArg.body
      }),
      invalidatesTags: ['role']
    }),
    unassignRole: builder.mutation({
      query: (queryArg) => ({
        url: '/roles/unassign',
        method: 'POST',
        body: queryArg.body
      }),
      invalidatesTags: ['role']
    })
  })
})

export const {
  useListRolesQuery,
  useAssignRoleMutation,
  useUnassignRoleMutation
} = roleApi
