import { subDays, subHours, subMinutes } from 'date-fns'

const now = new Date()

export default [
  {
    id: 1,
    // eslint-disable-next-line max-len
    logo: 'https://images.generated.photos/moB96N8Ni852n0JxbIh7rHN0o8wukCWdOwoPKzXQhIE/rs:fit:512:512/wm:0.95:sowe:18:18:0.33/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/MDUyMDU5LmpwZw.jpg',
    id_number: '000001',
    display_name: 'Root',
    email: 'root@niomon.dev',
    email_verified: true,
    phone: '91234567',
    phone_verified: true,
    active: true,
    shown_in_app: true,
    createdAt: subDays(subHours(subMinutes(now, 25), 9), 244),
    added_at: subDays(subHours(subMinutes(now, 25), 9), 244),
    role: 'Administrator'
  },
  {
    id: 2,
    // eslint-disable-next-line max-len
    logo: 'https://images.generated.photos/jDFbdTavE9XWjycvfmft9lufbgKpJCsRFgPSxzVBgZQ/rs:fit:512:512/wm:0.95:sowe:18:18:0.33/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/MTg1ODU5LmpwZw.jpg',
    id_number: '000002',
    display_name: 'Second',
    email: 'second@niomon.dev',
    email_verified: false,
    phone: '91234567',
    phone_verified: true,
    active: true,
    shown_in_app: true,
    created_at: subDays(subHours(subMinutes(now, 25), 1), 234),
    added_at: subDays(subHours(subMinutes(now, 25), 1), 234),
    role: 'Editor'
  },
  {
    id: 3,
    logo: '',
    id_number: '000003',
    display_name: 'Ranim Shadiyah Sabbagh',
    email: 'ranim_sabbagh@niomon.dev',
    email_verified: true,
    phone: '',
    phone_verified: false,
    active: false,
    shown_in_app: true,
    created_at: subDays(subHours(subMinutes(now, 25), 8), 6),
    added_at: subDays(subHours(subMinutes(now, 25), 8), 6),
    role: 'Editor'
  },
  {
    id: 4,
    logo: '',
    id_number: null,
    display_name: 'Third One',
    email: 'third_one_should_have_a_very_long_email@niomon.dev',
    email_verified: true,
    phone: '91234567',
    phone_verified: false,
    active: true,
    shown_in_app: true,
    created_at: subDays(subHours(subMinutes(now, 25), 9), 234),
    added_at: subDays(subHours(subMinutes(now, 25), 9), 234),
    role: ''
  }
]
