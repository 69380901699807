import {
  differenceInCalendarDays,
  format,
  formatDistance,
  parseISO
} from 'date-fns'

const nullDate = (date) => {
  // XXX: Server should return null value instead
  // For now when year is 1 from server, it means server has no such information
  if (date.getFullYear() === 1) {
    return true
  }
  return false
}

export const formatDistanceDateWithTime = (d) => {
  if (!d) {
    return 'No information'
  }
  const now = new Date()
  const date = parseISO(d)
  if (nullDate(date)) {
    return 'No information'
  }
  const diff = differenceInCalendarDays(now, date)
  return diff < 7 ? formatDistance(date, now, { addSuffix: true }) : format(date, 'dd/MM/yyyy p')
}

export const formatDateWithTime = (d) => {
  if (!d) {
    return 'No information'
  }
  const date = parseISO(d)
  if (nullDate(date)) {
    return 'No information'
  }
  return format(date, 'dd/MM/yyyy HH:mm')
}

export const currentYear = () => {
  const now = new Date()
  return format(now, 'yyyy')
}
