import PropTypes from 'prop-types'
import toast from 'react-hot-toast'
import { ConfirmationDialog } from '@/components/confirmation-dialog'
import { useDeleteCredentialMutation } from '@/store/api/credential'

export const DeleteCredentialConfirmationDialog = (props) => {
  const {
    open,
    onCancel,
    credential,
    setPending,
    onSuccess,
    ...other
  } = props

  const [deleteCredential] = useDeleteCredentialMutation()

  const dialogTitle = 'Revoke Security Device'

  const dialogMessage = 'Are you sure you want to revoke this security device?'

  const onConfirm = () => {
    setPending?.(true)
    deleteCredential({ credentialId: credential?.id })
      .unwrap()
      .then(() => {
        toast.success('Credential has been revoked')
        onSuccess?.()
      })
      .catch(() => {
        toast.error('Failed to revoke this credential')
        setPending?.(false)
      })
  }

  return (
    <ConfirmationDialog
      title={dialogTitle}
      message={dialogMessage}
      onCancel={onCancel}
      onConfirm={onConfirm}
      open={open}
      variant={'error'}
      {...other}
    />
  )
}

DeleteCredentialConfirmationDialog.propTypes = {
  open: PropTypes.bool,
  onCancel: PropTypes.func,
  credential: PropTypes.object,
  setPending: PropTypes.func,
  onSuccess: PropTypes.func
}
