import PropTypes from 'prop-types'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast'
import {
  Box,
  Typography,
  Popover,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  MenuItem,
  useMediaQuery,
  Avatar
} from '@mui/material'
import { InputField } from './input-field'
import { useAuth } from '@/hooks/use-auth'
import { usePopover } from '@/hooks/use-popover'
import { ChevronDown as ChevronDownIcon } from '@/icons/chevron-down'
import { Logout as LogoutIcon } from '@/icons/logout'
import { User as UserIcon } from '@/icons/user'

import { ALL_TENANTS_KEY } from './dashboard-navbar'
import { ROOT_TENANT } from '@/config'
import { GLOBAL_RESOURCE_NAMESPACE } from '@/constant'
import { TitleWithAvatar } from './title-with-avatar'

export const AccountPopover = (props) => {
  const {
    currentUser,
    currentTenantId,
    onTenantChange,
    tenantIds,
    showUsername,
    breakpoint,
    ...other
  } = props

  const navigate = useNavigate()
  const { logout } = useAuth()
  const [anchorRef, open, handleOpen, handleClose] = usePopover()
  const breakpointDown = useMediaQuery((theme) => theme.breakpoints.down(breakpoint))
  const handleTenantChange = (event) => {
    onTenantChange?.(event.target.value)
  }

  const handleLogout = async () => {
    try {
      handleClose()
      await logout()
      navigate('/')
    } catch (err) {
      console.error(err)
      toast.error('Something went wrong')
    }
  }

  const userTitle =
    currentUser?.preferredUsername || currentUser?.name || currentUser?.email || currentUser?.phoneNumber

  return (
    <>
      <Box
        onClick={handleOpen}
        ref={anchorRef}
        sx={{
          alignItems: 'center',
          cursor: 'pointer',
          display: 'flex',
          ml: 2
        }}
        {...other}
      >
        <TitleWithAvatar
          title={(
            <Box
              sx={{
                alignItems: 'center',
                display: showUsername && !breakpointDown ? 'flex' : 'none',
                flex: 1
              }}
            >
              <Typography
                sx={{ color: 'primary.contrastText' }}
                variant="subtitle2"
              >
                {userTitle}
              </Typography>
              <ChevronDownIcon
                sx={{
                  color: 'primary.contrastText',
                  ml: 1
                }}
              />
            </Box>
          )}
          avatar={(
            <Avatar
              variant="circular"
              src={currentUser?.picture || ''}
              sx={{
                height: 36,
                mr: 1,
                width: 36
              }}
            >
              {userTitle?.[0].toUpperCase()}
            </Avatar>
          )}
        />
      </Box>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom'
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: {
            width: 260,
            display: 'flex',
            flexDirection: 'column'
          }
        }}
      >
        <InputField
          fullWidth
          onChange={handleTenantChange}
          select
          value={currentTenantId === GLOBAL_RESOURCE_NAMESPACE ? ALL_TENANTS_KEY : currentTenantId }
          sx={{
            display: breakpointDown ? 'inherit' : 'none',
            pt: 2,
            px: 2
          }}
        >
          {tenantIds.map((tenantId, index) => (
            <MenuItem
              key={tenantId}
              value={tenantId}
              divider={tenantIds.length - 1 === index}
            >
              {tenantId}
            </MenuItem>
          ))}
          {tenantIds.includes(ROOT_TENANT) &&
            <MenuItem
              key={ALL_TENANTS_KEY}
              value={ALL_TENANTS_KEY}
            >
              All tenants
            </MenuItem>
          }
        </InputField>
        <List>
          {
            tenantIds.length !== 0
              ? (
                <ListItem
                  button
                  component={RouterLink}
                  divider
                  onClick={handleClose}
                  to={`/${currentTenantId}/account`}
                >
                  <ListItemIcon>
                    <UserIcon />
                  </ListItemIcon>
                  <ListItemText primary="Account" />
                </ListItem>
                )
              : <></>
          }
          <ListItem
            button
            onClick={handleLogout}
          >
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary="Log out" />
          </ListItem>
        </List>
      </Popover>
    </>
  )
}

AccountPopover.defaultProps = {
  breakpoint: 'md',
  showUsername: true,
  tenantIds: []
}

AccountPopover.propTypes = {
  // @ts-ignore
  currentUser: PropTypes.object,
  currentTenantId: PropTypes.string,
  onTenantChange: PropTypes.func,
  tenantIds: PropTypes.array,
  breakpoint: PropTypes.string,
  showUsername: PropTypes.bool
}
