import 'simplebar/dist/simplebar.min.css'
import 'nprogress/nprogress.css'
import { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'
import { Provider as ReduxProvider } from 'react-redux'
import { Toaster } from 'react-hot-toast'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import { AuthProvider } from './contexts/auth-context'
import { SettingsProvider } from './contexts/settings-context'
import { App } from './app'
import { store } from './store'

// TODO: In current stage mocking is required, later it may only required in some state, change it when necessary
const REQUIRE_MOCK = false

if (REQUIRE_MOCK) {
  require('./api/mockServer')
}

ReactDOM.render(
  <StrictMode>
    <HelmetProvider>
      <ReduxProvider store={store}>
        <BrowserRouter>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <SettingsProvider>
              <AuthProvider>
                <App />
                <Toaster position="bottom-right" />
              </AuthProvider>
            </SettingsProvider>
          </LocalizationProvider>
        </BrowserRouter>
      </ReduxProvider>
    </HelmetProvider>
  </StrictMode>, document.getElementById('root')
)
