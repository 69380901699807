import PropTypes from 'prop-types'
import { Grid, Typography } from '@mui/material'

export const StatusGrid = ({ children, text, ...other }) => {
  return (
    <Grid
      item
      sx={{
        alignItems: 'center',
        display: 'flex',
        width: {
          md: 'auto',
          xs: '100%'
        }
      }}
      {...other}
    >
      {children ??
        <Typography
          color="textSecondary"
          sx={{ ml: 0.5 }}
          variant="body2"
        >
          {text}
        </Typography>
      }
    </Grid>
  )
}

StatusGrid.propTypes = {
  children: PropTypes.node,
  text: PropTypes.string
}
