import crypto from 'crypto'
import {
  createServer,
  Model,
  Factory,
  hasMany,
  association,
  Serializer,
  Response
} from 'miragejs'
import tenants from './fixtures/tenants'
import users from './fixtures/users'
import clients from './fixtures/clients'
import currentUsers from './fixtures/current-users'
import payments from './fixtures/payments'
import { mockServerUrl } from '@/store/query'
import { resolveServiceHost } from '@niomon/niomon-js'

const ITEMS_IN_PAGE = 3
// eyJoZWFkIjogIjEiLCAidGFpbCI6ICIzIn0= represents '{"head": "1", "tail": "4"}' in JSON format
// head is inclusive and tail is exclusive
const DEFAULT_PAGINATION_TOKEN = 'eyJoZWFkIjoiMSIsInRhaWwiOiI0In0='

const DefaultSerializer = Serializer.extend({
  embed: true,
  root: false
})

// Provide pagination behaviour in serializer
// Input pluralName now for simpler handling as there is no easy
// way to get plural name form from existing API
const PaginationSerializer = (pluralName) => DefaultSerializer.extend({
  serialize (object, request) {
    const json = Serializer.prototype.serialize.apply(this, arguments)
    const result = {}
    const len = this.schema[pluralName].all().length

    // For list requirement, provide page_token for pagination mechanism
    if (request.queryParams.page_token !== undefined) {
      // Case for having page_token
      // Use id for pagination, assuming id is sequential integer
      const pageToken = request.queryParams.page_token || DEFAULT_PAGINATION_TOKEN
      const range = JSON.parse(atob(pageToken))
      const rangeToken = Object.assign({}, range)
      result.prev_page_token = null
      result.next_page_token = null
      if (range.tail < len + 1) {
        // Set next_page_token
        rangeToken.head = String(parseInt(range.head, 10) + ITEMS_IN_PAGE)
        rangeToken.tail = String(parseInt(range.tail, 10) + ITEMS_IN_PAGE)
        result.next_page_token = btoa(JSON.stringify(rangeToken))
      }

      if (range.head > 1) {
        // Set prev_page_token
        rangeToken.head = String(parseInt(range.head, 10) - ITEMS_IN_PAGE)
        rangeToken.tail = String(parseInt(range.tail, 10) - ITEMS_IN_PAGE)
        result.prev_page_token = btoa(JSON.stringify(rangeToken))
      }
      result[pluralName] = json
      result.page_token = pageToken

      return result
    }

    return json
  }
})

const server = createServer({
  models: {
    tenant: Model.extend({
      user: hasMany()
    }),

    user: Model.extend({
    }),

    client: Model,
    currentUser: Model,
    payment: Model
  },

  serializers: {
    tenant: PaginationSerializer('tenants'),
    user: PaginationSerializer('users'),
    client: PaginationSerializer('clients'),
    payment: PaginationSerializer('payments'),
    currentUser: DefaultSerializer
  },

  fixtures: {
    tenants,
    users,
    clients,
    currentUsers,
    payments
  },

  factories: {
    tenant: Factory.extend({
      user: association()
    })
  },

  seeds (server) {
    server.loadFixtures()
  },

  routes () {
    // passthrough all requests to admin api endpoint
    this.passthrough(`${resolveServiceHost(location.origin, 'api')}/**`)

    // For mock responses
    this.urlPrefix = mockServerUrl

    // Endpoint for current_user
    // XXX: Fixed the user now for demo
    this.get('/current_user', (schema, request) => {
      return schema.currentUsers.findBy({ id: '1' })
    })

    // Endpoint for tenants
    this.get('/tenants', ({ tenants }, request) => {
      const pageToken = request.queryParams.page_token
      if (!pageToken) {
        // Default behaviour without token
        return tenants.all().slice(0, ITEMS_IN_PAGE)
      }
      const range = JSON.parse(atob(pageToken))

      return tenants.all().slice(range.head - 1, range.tail - 1)
    })
    this.get('/tenants/:id', ({ tenants }, request) => {
      return tenants.findBy({ id: request.params.id })
    })
    this.post('/tenants', ({ tenants }, { requestBody }) => {
      const attrs = JSON.parse(requestBody)
      attrs.id = crypto.randomBytes(5).toString('hex')
      attrs.status = 'inactive'
      attrs.created_at = new Date()
      attrs.subscription = 'Free'
      attrs.num_of_user = 0
      return tenants.create(attrs)
    })
    this.put('/tenants/:id', function ({ tenants }, request) {
      const id = request.params.id
      const attrs = JSON.parse(request.requestBody)
      return tenants.findBy({ id }).update(attrs)
    })
    this.delete('/tenants/:id', ({ tenants }, { params }) => {
      const id = params.id
      const tenant = tenants.findBy({ id })
      tenant.destroy()
    })
    this.get('/tenants/:id/admins', ({ users }, request) => {
      // XXX: For demo purpose only, will modify later
      const pageToken = request.queryParams.page_token
      if (!pageToken) {
        // Default behaviour without token
        return users.all().slice(0, ITEMS_IN_PAGE)
      }
      const range = JSON.parse(atob(pageToken))

      return users.all().slice(range.head - 1, range.tail - 1)
    })

    // Endpoint for users
    this.get('/users', ({ users }, request) => {
      const pageToken = request.queryParams.page_token
      if (!pageToken) {
        // Default behaviour without token
        return users.all().slice(0, ITEMS_IN_PAGE)
      }
      const range = JSON.parse(atob(pageToken))

      return users.all().slice(range.head - 1, range.tail - 1)
    })
    this.get('/users/:id', ({ users }, request) => {
      return users.findBy({ id: request.params.id })
    })
    this.put('/users/:id', function ({ users }, request) {
      const id = request.params.id
      const attrs = JSON.parse(request.requestBody)
      // Simple way to check phone format in mocking
      if (!(/^\d*$/.test(attrs.phone))) {
        return new Response(400, {}, {
          errors: {
            phone: 'Invalid format'
          }
        })
      }
      return users.find(id).update(attrs)
    })
    this.delete('/users/:id', ({ users }, request) => {
      const id = request.params.id
      const user = users.findBy({ id })
      user.destroy()
    })

    // Endpoint for enrollment
    this.post('/users/:id/enrollments', ({ users }, request) => {
      return new Response(200, {}, {
        enrollmentToken: 'test_token'
      })
    })

    // Endpoint for clients
    this.get('/clients', ({ clients }, request) => {
      const pageToken = request.queryParams.page_token
      if (!pageToken) {
        // Default behaviour without token
        return clients.all().slice(0, ITEMS_IN_PAGE)
      }
      const range = JSON.parse(atob(pageToken))

      return clients.all().slice(range.head - 1, range.tail - 1)
    })
    this.get('/clients/:id', ({ clients }, request) => {
      const result = clients.findBy({ id: request.params.id })
      if (result === null) {
        return new Response(404, {}, {
          message: 'Not found'
        })
      }
      return result
    })
    this.patch('/clients/:id', ({ clients }, { params, requestBody }) => {
      const id = params.id
      const attrs = JSON.parse(requestBody)

      // Simple way to check color code format in mocking
      // Reference: https://stackoverflow.com/questions/2819619/validating-html-color-codes-js
      if (!/^#[0-9a-f]{3}([0-9a-f]{3})?$/i.test(attrs.theme_color)) {
        return new Response(400, {}, {
          errors: {
            theme_color: 'Invalid format'
          }
        })
      }
      return clients.find(id).update(attrs)
    })
    this.post('/clients', ({ clients }, { requestBody }) => {
      const attrs = JSON.parse(requestBody)
      attrs.client_secret = crypto.randomBytes(20).toString('hex')
      return clients.create(attrs)
    })
    this.delete('/clients/:id', ({ clients }, { params }) => {
      const id = params.id
      const client = clients.findBy({ id })
      client.destroy()
    })

    // Endpoint for payment-requests
    this.get('/payment-requests', ({ payments }, request) => {
      const pageToken = request.queryParams.page_token
      if (!pageToken) {
        // Default behaviour without token
        return payments.all().slice(0, ITEMS_IN_PAGE)
      }
      const range = JSON.parse(atob(pageToken))

      return payments.all().slice(range.head - 1, range.tail - 1)
    })
    this.get('/payment-requests/:id', ({ payments }, request) => {
      return payments.findBy({ id: request.params.id })
    })
    this.put('/payment-requests/:id', function ({ payments }, request) {
      const id = request.params.id
      const attrs = JSON.parse(request.requestBody)
      return payments.findBy({ id: id }).update(attrs)
    })
  }
})

server.logging = true
