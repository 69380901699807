import { Outlet } from 'react-router-dom'
import {
  Box,
  Container,
  Typography
} from '@mui/material'

export const Settings = () => {
  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        flexGrow: 1
      }}
    >
      <Container
        maxWidth="lg"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%'
        }}
      >
        <Box sx={{ py: 4 }}>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex'
            }}
          >
            <Typography
              color="textPrimary"
              variant="h4"
            >
              Settings
            </Typography>
          </Box>
        </Box>
        <Outlet />
      </Container>
    </Box>
  )
}
