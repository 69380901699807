/* eslint-disable max-len */
import { createSvgIcon } from '@mui/material/utils'

export const Ditto = createSvgIcon(
  <svg
    viewBox="0 0 513 513"
    xmlns="http:www.w3.org/2000/svg"
  >
    <path
      d="M151.923,154.708c-10.904,68.542 -30.376,143.314 -54.521,210.298l71.657,-7.01c35.828,-58.416 69.32,-134.746 93.466,-210.298l-110.602,7.01Zm152.661,0c-10.904,68.542 -31.155,143.314 -54.522,210.298l71.658,-7.01c35.049,-58.416 69.32,-134.746 93.465,-210.298l-110.601,7.01Z"
    />
  </svg>
)
