import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone'

export default (
  <PhoneIphoneIcon
    sx={{
      color: 'neutral.500',
      fontSize: 72
    }}
  />
)
