import { useMemo } from 'react'
import { getNavigationItemList } from '@/schema/sections'

export const useSections = (tenantId, roles) => {
  return useMemo(() => {
    const navigationItems = getNavigationItemList(tenantId).map(navigationSection => {
      // 1-layer section
      if (!navigationSection.items) {
        if (!navigationSection.isShown(roles)) {
          return null
        }

        return navigationSection
      }

      // 2-layers section
      navigationSection.items = navigationSection.items.filter(item => item.isShown(roles))
      if (navigationSection.items.length === 0) {
        return null
      }
      return navigationSection
    }).filter(v => v)

    return { navigationItems, landingPath: navigationItems[0]?.href }
  }, [roles, tenantId])
}
