import PropTypes from 'prop-types'
import { SingleInputDialog } from '@/components/single-input-dialog'
import toast from 'react-hot-toast'
import { Typography } from '@mui/material'
import { useDeleteClientMutation } from '@/store/api/client'

export const DeleteClientConfirmationDialog = (props) => {
  const {
    open,
    onCancel,
    client,
    setPending,
    onSuccess,
    ...other
  } = props

  const [deleteClient] = useDeleteClientMutation()

  const onConfirm = () => {
    setPending?.(true)
    deleteClient({ clientId: client?.id })
      .unwrap()
      .then(() => {
        toast.success('Application has been removed')
        onSuccess?.()
      })
      .catch(() => {
        toast.error('Failed to delete this application')
        setPending?.(false)
      })
  }

  return (
    <SingleInputDialog
      title="Delete Application"
      message={
        <>
          <Typography
            color="textPrimary"
            variant="body1"
            sx={{ mb: 3 }}
          >
            {/* To better handle the message, inline them once, hence disable eslint for the line */}
            {/* eslint-disable-next-line max-len, react/jsx-max-props-per-line */}
            This action cannot be undone. This will permanently delete the <Typography component="span" sx={{ fontWeight: 'fontWeightBold' }}>{client?.name}</Typography> Application.
          </Typography>
          <Typography
            color="textPrimary"
            variant="body1"
            sx={{ mb: 3 }}
          >
            Type the name of application to confirm.
          </Typography>
        </>
      }
      label="Name"
      variant="error"
      onCancel={onCancel}
      onSubmit={onConfirm}
      open={open}
      submitProps={{
        color: 'error',
        label: 'Delete',
        disabled: (value) => value !== client?.name
      }}
      {...other}
    />
  )
}

DeleteClientConfirmationDialog.propTypes = {
  open: PropTypes.bool,
  onCancel: PropTypes.func,
  client: PropTypes.object,
  setPending: PropTypes.func,
  onSuccess: PropTypes.func
}
