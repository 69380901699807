import { createApi } from '@reduxjs/toolkit/query/react'
import { internalApiBaseQuery } from '@/store/query'

export const ApiKeyApi = createApi({
  reducerPath: 'api_key',
  baseQuery: internalApiBaseQuery,
  tagTypes: ['api_key'],
  // allow refetch all subscribed queries after regaining a network connection
  refetchOnReconnect: true,
  // cache expired after 10 seconds
  refetchOnMountOrArgChange: 10,
  endpoints: (builder) => ({
    getApiKey: builder.query({
      query: () => ({ url: '/api-key' }),
      providesTags: ['api_key']
    }),
    resetApiKey: builder.mutation({
      query: () => ({
        url: '/api-key/reset',
        method: 'POST'
      }),
      invalidatesTags: ['api_key']
    }),
    clearApiKey: builder.mutation({
      query: () => ({
        url: '/api-key/clear',
        method: 'POST'
      }),
      invalidatesTags: ['api_key']
    })
  })
})

export const {
  useGetApiKeyQuery,
  useClearApiKeyMutation,
  useResetApiKeyMutation
} = ApiKeyApi
