import PropTypes from 'prop-types'
import { Box, IconButton } from '@mui/material'
import { styled } from '@mui/material/styles'
import { ChevronLeft as ChevronLeftIcon } from '@/icons/chevron-left'
import { ChevronRight as ChevronRightIcon } from '@/icons/chevron-right'

const PaginationRoot = styled('div')(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  padding: theme.spacing(2)
}))

export const Pagination = (props) => {
  const { disabled, page, pageSize, totalSize, onPageChange, ...other } = props
  const isFirstPage = page === 0
  const isLastPage = (page + 1) * pageSize >= totalSize

  const handlePreviousPage = () => {
    onPageChange?.(page - 1)
  }

  const handleNextPage = () => {
    onPageChange?.(page + 1)
  }

  return (
    <PaginationRoot {...other}>
      <Box sx={{ flexGrow: 1 }} />
      <IconButton
        disabled={isFirstPage || disabled}
        onClick={handlePreviousPage}
      >
        <ChevronLeftIcon />
      </IconButton>
      <IconButton
        disabled={isLastPage || disabled}
        onClick={handleNextPage}
      >
        <ChevronRightIcon />
      </IconButton>
    </PaginationRoot>
  )
}

Pagination.defaultProps = {
  disabled: false,
  page: 0,
  pageSize: 50,
  totalSize: 0
}

Pagination.propTypes = {
  disabled: PropTypes.bool,
  onPageChange: PropTypes.func,
  page: PropTypes.number,
  pageSize: PropTypes.number,
  totalSize: PropTypes.number
}
