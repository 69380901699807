import { Box, Typography } from '@mui/material'
import DoNotTouchIcon from '@mui/icons-material/DoNotTouch'

export const PermissionDenied = () => {
  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <DoNotTouchIcon />
        <Typography
          color="textSecondary"
          sx={{ my: 2 }}
          variant="body2"
          >
            You do not have permission to access this page
        </Typography>
      </Box>
    </Box>
  )
}
