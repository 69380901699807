import { useState } from 'react'
import { Outlet, useParams, useNavigate } from 'react-router-dom'
import { Box, Container, Typography } from '@mui/material'
import { PageHeader } from '@/components/page-header'
import { ClientActionMenu } from '@/components/client/client-action-menu'
import { ChevronDown as ChevronDownIcon } from '@/icons/chevron-down'
import { QueryGuard } from '@/components/query-guard'

import { useGetClientQuery } from '@/store/api/client'
import { ClientDisplayCard } from '@/components/client/client-display-card'

// TODO(#90, #102): Root tenant relying party (i.e. Admin panel) should not be allowed
// to delete, this is related to access control part and form validation part
export const Client = () => {
  const { tenantId, clientId } = useParams()
  const navigate = useNavigate()
  const [pending, setPending] = useState(false)

  const { data: client, error, isLoading } = useGetClientQuery({ clientId }, { skip: pending })

  const tabs = [
    {
      href: `/${tenantId}/clients/${clientId}`,
      label: 'General'
    }
  ]

  const onSuccess = () => {
    navigate('..')
  }

  const renderContent = () => {
    return (
      <QueryGuard
        isLoading={isLoading}
        statusCode={error?.status}
      >
        <PageHeader
          divider
          backTo="-1"
          backTitle="Applications"
          tabs={tabs}
          actions={
            <ClientActionMenu
              label="Actions"
              color="primary"
              endIcon={<ChevronDownIcon fontSize="small" />}
              size="large"
              variant="contained"
              client={client}
              onSuccess={onSuccess}
              setPending={setPending}
            />
          }
          title={
            <>
              <ClientDisplayCard
                title={(
                  <Typography
                    color="textPrimary"
                    variant="h4"
                  >
                    {client?.name}
                  </Typography>
                )}
                avatar={client?.logoUrl}
              />
            </>
          }
        />
        <QueryGuard isLoading={pending} >
          <Outlet />
        </QueryGuard>
      </QueryGuard>
    )
  }

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        flexGrow: 1
      }}
    >
      <Container
        maxWidth="lg"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%'
        }}
      >
        {renderContent()}
      </Container>
    </Box>
  )
}
