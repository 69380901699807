import { useLocation, useNavigate } from 'react-router-dom'

export const useNavigateWithHistory = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const navigateHistory = (to, options) => {
    const optionsWithHistory = Object.assign(options || {}, { state: { location } })
    return navigate(to, optionsWithHistory)
  }

  return navigateHistory
}
