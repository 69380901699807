import PropTypes from 'prop-types'
import toast from 'react-hot-toast'
import { IconButton, Menu, MenuItem, Typography } from '@mui/material'
import { ConfirmationDialog } from '@/components/confirmation-dialog'
import { useDeleteUserMutation, useUpdateUserMutation } from '@/store/api/user'
import { useCreateEnrollmentMutation } from '@/store/api/credential'
import { useDialog } from '@/hooks/use-dialog'
import { usePopover } from '@/hooks/use-popover'
import { DotsVertical as DotsVerticalIcon } from '@/icons/dots-vertical'
import { SingleInputDialog } from '../single-input-dialog'

const useDeleteUser = (userId, beforeDeleteUser, onDeleteUser) => {
  const [
    open,
    handleOpen,
    handleClose
  ] = useDialog()
  const [deleteUserMutation] = useDeleteUserMutation()
  const onConfirm = () => {
    handleClose()
    beforeDeleteUser?.()
    deleteUserMutation({ userId })
      .unwrap()
      .then(() => {
        onDeleteUser?.()
        toast.success('User has been removed')
      })
      .catch((e) => {
        onDeleteUser?.(e)
        console.error('Failed to delete user: ', e)
        toast.error('Failed to delete this user')
      })
  }
  return [open, handleOpen, onConfirm, handleClose]
}

const useSendEnrollmentLink = (userId) => {
  const [
    open,
    handleOpen,
    handleClose
  ] = useDialog()
  const [createEnrollmentMutation] = useCreateEnrollmentMutation()
  const onConfirm = () => {
    handleClose()
    createEnrollmentMutation({ userId, body: { type: 'public-key', send: true } })
      .unwrap()
      .then(() => {
        toast.success('Enrollment link has been sent')
      })
      .catch((e) => {
        console.error('Failed to send enrollment link: ', e)
        toast.error('Failed to send enrollment link')
      })
  }
  return [open, handleOpen, onConfirm, handleClose]
}

const useLockUser = (userId, locked) => {
  const [
    open,
    handleOpen,
    handleClose
  ] = useDialog()
  const [updateUser] = useUpdateUserMutation()
  const onConfirm = () => {
    handleClose()
    updateUser({ userId, body: { locked: !locked } })
      .unwrap()
      .then(() => {
        toast.success('User has been locked')
      })
      .catch((e) => {
        console.error('Failed to update user: ', e)
        toast.error('Failed to lock user')
      })
  }
  return [open, handleOpen, onConfirm, handleClose]
}

export const UserMenu = (props) => {
  const [anchorRef, open, handleOpen, handleClose] = usePopover()

  // The onPendingDeleteUser and onDeleteUser hook is used by user details page to avoid reloading
  // user after it is deleted.
  const { user, beforeDeleteUser, onDeleteUser, ...other } = props

  const [deleteOpen, handleDelete, onConfirmDelete, onCancelDelete] =
    useDeleteUser(user.id, beforeDeleteUser, onDeleteUser)
  const [enrollmentOpen, handleEnrollment, onConfirmEnrollment, onCancelEnrollment] = useSendEnrollmentLink(user.id)

  const [lockUserOpen, handleLockUser, onConfirmLockUser, onCancelLockUser] = useLockUser(user.id, user.locked)

  const onAction = (next) => {
    return () => {
      handleClose()
      next()
    }
  }

  return (
    <div onClick={ e => e.stopPropagation() }>
      <IconButton
        onClick={handleOpen}
        ref={anchorRef}
        {...other}
      >
        <DotsVerticalIcon fontSize="small" />
      </IconButton>
      <Menu
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        open={open}
        onClose={handleClose}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuItem onClick={onAction(handleEnrollment)}>
          Send enrollment link
        </MenuItem>
        <MenuItem onClick={onAction(handleLockUser)}>
          {
            user.locked
              ? 'Unlock user'
              : 'Lock user'
          }
        </MenuItem>
        <MenuItem onClick={onAction(handleDelete)}>
          Delete
        </MenuItem>
      </Menu>
      <SingleInputDialog
        title="Delete User"
        message={
          <>
            <Typography
              color="textPrimary"
              variant="body1"
              sx={{ mb: 3 }}
            >
              Are you sure you want to permanently delete the user? This action cannot be undone.
            </Typography>
            <Typography
              color="textPrimary"
              variant="body1"
              sx={{ mb: 3 }}
            >
              Type the user ID (
                <Typography
                  component="span"
                  sx={{ fontWeight: 'fontWeightBold' }}
                >
                  {user?.id}
                </Typography>
              ) to confirm.
            </Typography>
          </>
        }
        label="User ID"
        variant="error"
        onCancel={onCancelDelete}
        onSubmit={onConfirmDelete}
        open={deleteOpen}
        submitProps={{
          color: 'error',
          label: 'Delete',
          disabled: (value) => value !== (user?.id)
        }}
        {...other}
      />
      <ConfirmationDialog
        title="Send enrollment link"
        message="Send a credential enrollment link to user?"
        onCancel={onCancelEnrollment}
        onConfirm={onConfirmEnrollment}
        open={enrollmentOpen}
        variant={'info'}
      />
      <ConfirmationDialog
        title={
          user.locked
            ? 'Unlock User'
            : 'Lock User'
        }
        message={
          user.locked
            ? 'Are you sure you want to unlock this user?'
            : 'Are you sure you want to lock this user? Locked user cannot login or access Niomon services.'
        }
        onCancel={onCancelLockUser}
        onConfirm={onConfirmLockUser}
        open={lockUserOpen}
        variant={'error'}
      />
    </div>
  )
}

UserMenu.propTypes = {
  user: PropTypes.object,
  onDeleteUser: PropTypes.func,
  beforeDeleteUser: PropTypes.func
}
