import { createApi } from '@reduxjs/toolkit/query/react'
import { dittoApiBaseQuery } from '@/store/query'
import { serializeWithTenantId } from '@/store/utils'

export const dittoClientTokenApi = createApi({
  reducerPath: 'ditto_client_token',
  baseQuery: dittoApiBaseQuery,
  tagTypes: ['ditto_client_token'],
  // allow refetch all subscribed queries after regaining a network connection
  refetchOnReconnect: true,
  // cache expired after 10 seconds
  refetchOnMountOrArgChange: 10,
  serializeQueryArgs: serializeWithTenantId,
  endpoints: (builder) => ({
    listClientTokens: builder.query({
      query: (queryArg) => ({ url: `/clients/${queryArg.clientId}/tokens` }),
      providesTags: (result, error) => {
        return (
          result
            ? [
                ...result.tokens.map(({ id }) => ({ type: 'ditto_client_token', id })),
                // PARTIAL-LIST denotes that the list is partial, which is paginated
                { type: 'ditto_client_token', id: 'PARTIAL-LIST' }
              ]
            : [{ type: 'ditto_client_token', id: 'PARTIAL-LIST' }]
        )
      }
    }),
    getClientToken: builder.query({
      query: (queryArg) => ({
        url: `/tokens/${queryArg.tokenId}`
      }),
      providesTags: (result, error) => result ? [{ type: 'ditto_client_token', id: result.id }] : []
    }),
    createClientToken: builder.mutation({
      query: (queryArg) => ({
        url: `/clients/${queryArg.clientId}/tokens`,
        method: 'POST',
        body: queryArg.body
      }),
      invalidatesTags: ['ditto_client_token']
    }),
    reviewClientTokenStatus: builder.mutation({
      query: (queryArg) => ({
        url: `/tokens/${queryArg.tokenId}/review`,
        method: 'POST',
        body: queryArg.body
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'ditto_client_token', id }]
    }),
    deleteClientToken: builder.mutation({
      query: (queryArg) => ({
        url: `/tokens/${queryArg.tokenId}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['ditto_client_token']
    })
  })
})

export const {
  useListClientTokensQuery,
  useGetClientTokenQuery,
  useCreateClientTokenMutation,
  useReviewClientTokenStatusMutation,
  useDeleteClientTokenMutation
} = dittoClientTokenApi
