import { Box, Skeleton } from '@mui/material'

export const SkeletonLoading = () => {
  return (
    <Box sx={{ py: 4 }}>
      <Skeleton height={42} />
      <Skeleton />
      <Skeleton />
    </Box>
  )
}
